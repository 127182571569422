import "./AbsensiCard.css";
import profile from "../../assets/profile.png";

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const AbsensiCard = ({ data }) => {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const handleOnClickAbsenCard = (v) => {
    navigate("/detailabsensi", { state: { fID: data.fID } });
  };

  return (
    <div
      onClick={() => {
        handleOnClickAbsenCard(data);
      }}
      className="AbsensiCard_Container"
    >
      <img
        className="AbsensiCardImage"
        src={`${API_URL}/files/${data.fCheckInImage}`}
        alt=""
        style={{ marginTop: "10px", marginBottom: "10px" }}
      />
      <div style={{ height: "10px" }} />
      <></>

      <div style={{ fontSize: '14px', marginBottom: '10px' }}>
        <text style={{ fontWeight: 'bold' }}>Check-In</text>
        <br></br>
        <text>{dayjs(data.fCheckInDateTime).format("DD MMM YYYY, HH:mm").toString()}</text>
      </div>

      <div style={{ fontSize: '14px' }}>
        <text style={{ fontWeight: 'bold' }}>Check-Out</text>
        <br></br>
        <text>{data.fCheckOut === 'Y' ? dayjs(data.fCheckOutDateTime).format("DD MMM YYYY, HH:mm").toString() : '-'}</text>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: "15px",
        }}
      >
        <img
          src={`${API_URL}/files/${data.fUser.fImages}`}
          alt=""
          style={{ width: "40px", height: '40px', marginRight: "10px", borderRadius: 100, objectFit: 'contain' }}
        />
        <text style={{ fontSize: "14px", fontWeight: 600 }}>
          {data.fUser.fName}
        </text>
      </div>
    </div>
  );
};

export default AbsensiCard;
