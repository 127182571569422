import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";
import PengajuanCard from "../../Components/PengajuanCard/PengajuanCard";
import progresslogo from "../../assets/progressspv.png";

import "@fontsource/poppins";

import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ProgressCard from "../../Components/ProgressCard/ProgressCard";
import ModalPicture from "../../Components/Modal/ModalPicture";

const ViewAllProjectProgress = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const { state } = useLocation();
  const { fID } = state;
  const navigate = useNavigate();
  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [progress, setProgress] = useState([]);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const [openModalPicture, setOpenModalPicture] = useState(false);
  const [image, setImage] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    getAllPengajuan();
  }, [user, page]);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID, page: 1, limit: 3, type: 'PM'
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(new Date(response.data.data[0].fCheckInDateTime));
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate('/')
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate('/')
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllPengajuan = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getallprojectprogress`,
        {page: 1, limit: 9, fID},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProgress(response.data.data);
      setTotalPage(response.data.meta.totalPages);
    } catch (error) {}
  };

  const handleAddClick = () => {
    navigate("/addpengajuan");
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "195px",
        paddingBottom: "25px",
        minHeight: '100vh',
        position: 'relative'
      }}
    >
      <Navbar profileData={user} />
      {/* Navbar Extension Manual */}
      <div
        style={{
          backgroundColor: "#F9FAFC",
          width: "100vw",
          position: "fixed",
          top: "55px",
          zIndex: 9991,
        }}
      >
        <div className="NavExtensionContainer">
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src={progresslogo} alt="" className="NavExtensionIcon" />
            <text className="NavExtensionText">Semua Progress</text>
          </div>
        </div>
      </div>

      <div className="AllPengajuanContainer">
        {progress.map((d) => {
          return <ProgressCard data={d} user={user} closeModalPicture={setOpenModalPicture} setImage={setImage} setImageIndex={setImageIndex} />;
        })}
      </div>

      <div style={{ height: "120px", width: "50px" }} />

      <Stack
        style={{
          width: "100%",
          maxWidth: "1024px",
          position: "absolute",
          bottom: "50px",
          left: 0,
          right: 0,
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "16px",
        }}
      >
        <Pagination count={totalPage} page={page} onChange={handleChangePage} />
      </Stack>
      {openModalPicture && (
        <ModalPicture closeModalPicture={setOpenModalPicture} picture={image} index={imageIndex} setIndex={setImageIndex} />
      )}
    </div>
  );
};

export default ViewAllProjectProgress;
