import "./Navbar.css";
import logo from "../../assets/logo.png";
import notification from "../../assets/notification.png";
import profile from "../../assets/profile.png";
import arrowDown from "../../assets/arrow_down.png";
import ModalCheckIn from "../Modal/ModalCheckIn";
import ModalProfile from "../Modal/ModalProfile";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const Navbar = ({ profileData }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [openModalProfile, setOpenModalProfile] = useState(false);

  const navigate = useNavigate();
  return (
    <div style={{ backgroundColor: 'white', width: '100vw', position: 'fixed', top: 0, zIndex: 9992 }}>
      <div className="Nav-Container">
        <img onClick={() => {navigate('/')}} src={logo} alt="logo" className="Nav-logo" />
        <div className="Nav-profile-Container">
          {/* <img src={notification} alt="notif" className="Nav-notif" /> */}
          <div onClick={() => { setOpenModalProfile(!openModalProfile) }} className="Nav-profile-button">
            <img style={{ borderRadius: 100 }} src={`${API_URL}/files/${profileData.fImages}`} alt='' className="Nav-profile-pic" />
            <div style={{ fontSize: '12px' }}>
              <text style={{ fontWeight: "bold" }}>{profileData.fName}</text>
              <div/>
              {profileData.fRoleAlias}
            </div>
            <img src={arrowDown} alt="arrow" className="Nav-profile-arrow" />
          </div>
        </div>
      </div>
      {openModalProfile && (
        <ModalProfile closeModalProfile={setOpenModalProfile} role={profileData.fRole} />
      )}
    </div>
  );
};

export default Navbar;
