import "./ProgressCard.css";
import { useSpring, animated } from "react-spring";
import profile from "../../assets/profile.png";

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const ProgressCard = ({ data, user, closeModalPicture, setImage, setImageIndex }) => {
  const navigate = useNavigate();
  const AnimatedDiv = animated.div;
  const API_URL = process.env.REACT_APP_API_URL;

  const gaugeProgress = useSpring({
    value: data.fProgressPercentage,
    from: { value: 0 },
    config: {
      duration: 500,
    },
  });

  const handleClickProject = (v) => {
    if(user.fRole === 'PM' || user.fRole === 'BOSS'){
      navigate("/editprogressproject", { state: { data: v } });
    }
  };

  const handleClickImage = (i) => {
    setImage(data.fProgressImages)
    setImageIndex(i)
    closeModalPicture(true)
  }

  return (
    <div
      className="ProgressCard_Container"
    >
      <div className="ProgressCardDateGaugeWrapper">
        <text style={{ fontSize: "14px" }}>
          {dayjs(data.fProgressDate).format("DD MMM YYYY").toString()}
        </text>
        <div
          className="ProgressCard-Container"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          <div style={{ width: "50px" }}>
            <AnimatedDiv
              style={{
                fontSize: "16px",
                lineHeight: 1,
                fontWeight: "bold",
                color: "#D0A360",
                padding: 0,
              }}
            >
              {gaugeProgress.value.to((value) => Math.floor(value) + "%")}
            </AnimatedDiv>
          </div>
          <div
            className="ProgressCard_Gauge_Number"
            style={{
              backgroundColor: "#EAEAED",
              height: "7px",
              borderRadius: 100,
            }}
          >
            <AnimatedDiv
              className="ProgressCard_Gauge"
              style={{
                width: gaugeProgress.value.to((value) => value + "%"),
                borderRadius: 100,
              }}
            ></AnimatedDiv>
          </div>
        </div>
      </div>

      <div className="ProgressCardImageWrapper">
      {
        data.fProgressImages.map((d,i) => {
            return(
                <img onClick={() => { handleClickImage(i) }} style={{ borderRadius: 5, border: '1px solid #A3A3A3', objectFit: 'cover' }} className="ProgressCardImage" src={`${API_URL}/files/${d.filename}`} alt="" />
            )
        })
      }
      </div>
      <div style={{ height: "20px" }} />
      <text style={{ fontWeight: "bold", fontSize: "16px" }}>Keterangan</text>
      <div style={{ height: '5px' }}></div>
      <div >
        <text style={{ fontSize: "16px" }}>
          {data.fKet}
        </text>
      </div>

      {(user.fRole === 'PM' || user.fRole === 'BOSS') && <button onClick={() => handleClickProject(data)} style={{ borderRadius: 5 }} className="detailProjectButtonEdit">EDIT</button>}
    </div>
  );
};

export default ProgressCard;
