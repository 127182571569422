import { useEffect, useState } from "react";
import "./PengajuanCard.css";
import { Link, useNavigate } from "react-router-dom";

import dayjs from "dayjs";

const PengajuanCard = ({ data }) => {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const ListItemSmall = () => {
    return data.fPengajuanItem.map((d, i) => {
      let sumArrived = 0;
      return (
        <div style={{ width: "100%" }}>
          {i === 0 ? (
            <div />
          ) : (
            <div
              style={{
                height: "0.5px",
                width: "100%",
                backgroundColor: "rgba(133,133,133,0.5)",
                marginBottom: "10px",
              }}
            />
          )}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <img
              style={{
                width: "100px",
                height: "100px",
                objectFit: "cover",
                marginBottom: "15px",
              }}
              src={`${API_URL}/files/${d.fItem.fItemImage.filename}`}
              alt=""
            />
            <div
              style={{
                height: "100px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                marginLeft: "10px",
              }}
            >
              <text style={{ fontSize: "14px", fontWeight: "bold" }}>
                Nama Barang
              </text>
              <div>
                <text style={{ fontSize: "14px" }}>{d.fItem.fItemName}</text>
              </div>

              <div style={{ height: "10px" }} />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <text style={{ fontSize: "14px", fontWeight: "bold" }}>
                    Total
                  </text>
                  <div>
                    <text style={{ fontSize: "14px" }}>
                      {d.fQuantity} {d.fDenom}
                    </text>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <text style={{ fontSize: "14px", fontWeight: "bold" }}>
                    Sampai
                  </text>
                  <div>
                    <text style={{ fontSize: "14px" }}>
                      {d.fArrived} {d.fDenom}
                    </text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const ListItemBig = () => {
    return data.fPengajuanItem.map((d, i) => {
      let sumArrived = 0;

      return (
        <div style={{ width: "100%" }}>
          {i === 0 ? (
            <div />
          ) : (
            <div
              style={{
                height: "0.5px",
                width: "100%",
                backgroundColor: "rgba(133,133,133,0.5)",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            />
          )}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <img
              style={{ width: "100px", height: "100px", objectFit: "cover" }}
              src={`${API_URL}/files/${d.fItem.fItemImage.filename}`}
              alt=""
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "flex-start",
                marginLeft: "10px",
              }}
            >
              <div
                style={{
                  width: "60%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <text style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Nama Barang
                </text>
                <div>
                  <text style={{ fontSize: "16px" }}>{d.fItem.fItemName}</text>
                </div>
              </div>

              <div style={{ width: "40%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <text style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Total
                  </text>
                  <text style={{ fontSize: "16px" }}>
                    {d.fQuantity} {d.fDenom}
                  </text>
                </div>
              </div>

              <div style={{ width: "40%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <text style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Sampai
                  </text>
                  <text style={{ fontSize: "16px" }}>
                    {d.fArrived} {d.fDenom}
                  </text>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const CardSmall = () => {
    return (
      <div className="Pengajuan_Container">
        {/* Tanggal dan Status */}
        <div
          className="PengajuanCard_Nav"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <text style={{ fontSize: "14px" }}>
            {dayjs(data.fDateTime).format("DD MMM YYYY").toString()}
          </text>
        </div>

        <text
          style={{
            color: "#4888C3",
            fontSize: "18px",
            fontWeight: "bold",
            marginTop: "10px",
            marginBottom: "10px",
            height: "40px",
          }}
        >
          {data.fPengajuanName}
        </text>
        {/* Item dan Jumlah*/}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <text style={{ fontWeight: "bold", fontSize: "16px" }}>New</text>
          <text style={{ fontSize: "16px" }}>{data.fSumStatus.new}</text>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <text style={{ fontWeight: "bold", fontSize: "16px" }}>Approved</text>
          <text style={{ fontSize: "16px" }}>{data.fSumStatus.checked}</text>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <text style={{ fontWeight: "bold", fontSize: "16px" }}>Rejected</text>
          <text style={{ fontSize: "16px" }}>{data.fSumStatus.rejected}</text>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <text style={{ fontWeight: "bold", fontSize: "16px" }}>
            Purchased
          </text>
          <text style={{ fontSize: "16px" }}>{data.fSumStatus.purchased}</text>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <text style={{ fontWeight: "bold", fontSize: "16px" }}>
            Completed
          </text>
          <text style={{ fontSize: "16px" }}>{data.fSumStatus.completed}</text>
        </div>

        {/* Divider */}
        <div
          style={{
            height: "3px",
            width: "70%",
            backgroundColor: "#4888C3",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "10px",
            marginBottom: "10px",
            borderRadius: 100,
          }}
        />

        {/* List Item */}
        <ListItemSmall />
      </div>
    );
  };

  const CardBig = () => {
    return (
      <div className="Pengajuan_Container2">
        {/* Tanggal dan Status */}
        <div
          className="PengajuanCard_Nav"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <text style={{ fontSize: "14px" }}>
            {dayjs(data.fDateTime).format("DD-MM-YYYY").toString()}
          </text>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            position: "relative",
            marginTop: "15px",
          }}
        >
          {/* Left Part */}
          <div
            style={{
              width: "30%",
              display: "flex",
              flebackgroundColor: "red",
              alignSelf: "flex-start",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {/* Status */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <text
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginBottom: "15px",
                  color: "#4888C3",
                }}
              >
                {data.fPengajuanName}
              </text>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <text style={{ fontWeight: "bold", fontSize: "16px" }}>New</text>
              <text style={{ fontSize: "16px" }}>{data.fSumStatus.new}</text>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <text style={{ fontWeight: "bold", fontSize: "16px" }}>
                Approved
              </text>
              <text style={{ fontSize: "16px" }}>
                {data.fSumStatus.checked}
              </text>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <text style={{ fontWeight: "bold", fontSize: "16px" }}>
                Rejected
              </text>
              <text style={{ fontSize: "16px" }}>
                {data.fSumStatus.rejected}
              </text>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <text style={{ fontWeight: "bold", fontSize: "16px" }}>
                Purchased
              </text>
              <text style={{ fontSize: "16px" }}>
                {data.fSumStatus.purchased}
              </text>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <text style={{ fontWeight: "bold", fontSize: "16px" }}>
                Completed
              </text>
              <text style={{ fontSize: "16px" }}>
                {data.fSumStatus.completed}
              </text>
            </div>

            <div />
            <div />
          </div>

          {/* Divider */}
          <div style={{ borderRadius: 100 }} className="PengajuanDivider" />

          {/* Right part */}
          <div className="PengajuanRightPart">
            <ListItemBig />
          </div>
        </div>
        <Link
          style={{
            color: "#4888C3",
            fontSize: "14px",
            fontWeight: "bold",
            position: "absolute",
            right: "15px",
            top: "15px",
          }}
        >
          Lihat Detail
        </Link>
      </div>
    );
  };

  const handleClickPengajuan = (v) => {
    navigate("/detailpengajuan", { state: { fID: data.fID } });
  };

  return (
    <div onClick={() => handleClickPengajuan(data)} style={{ width: "100%" }}>
      {!matches && <CardSmall />}
      {matches && <CardBig />}
    </div>
  );
};

export default PengajuanCard;
