import download from "../../assets/download.png";
import imageIcon from "../../assets/imageIcon.png";
import fileIcon from "../../assets/fileIcon.png";
import axios from "axios";
import fileDownload from "js-file-download";

const DownloadCard = ({ file, typeFile }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  function getFileExtension(filename) {
    return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
  }

  function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  }

  return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "0px 15px",
          justifyContent: "space-between",
          alignItems: "center",
          width: "300px",
          height: "88px",
          borderRadius: "7px",
          backgroundColor: "white",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: "50px" }}
            src={fileIcon}
            alt=""
          />
          <div style={{ fontSize: "14px", margin: "15px" }}>
            {
              file.name ? 
              <text style={{ fontWeight: "bold" }}>{file.name}</text>
              :
              <text style={{ fontWeight: "bold" }}>{`${typeFile}.${getFileExtension(file.filename)}`}</text>
            }
            <div />
            <text>{humanFileSize(file.size)}</text>
          </div>
        </div>

        <img
          onClick={() => {
            file.name ?
            handleDownload(URL.createObjectURL(file), `${typeFile}.${getFileExtension(file.name)}`)
            :
            handleDownload(`${API_URL}/files/${file.filename}`, `${typeFile}.${getFileExtension(file.filename)}`)
          }}
          style={{ width: "22px" }}
          src={download}
          alt=""
        />
      </div>
  );
};

export default DownloadCard;
