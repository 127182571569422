import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";

const CarouselImage = ({ data }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      style={{ width: "90%" }}
      activeIndex={index}
      onSelect={handleSelect}
    >
      {data.map((d) => {
        return (
          <Carousel.Item>
            <img
              style={{
                width: "100%",
                aspectRatio: "743/443",
                objectFit: "cover",
                margin: "5px 0",
              }}
              src={`${API_URL}/files/${d.filename}`}
              alt=""
            />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default CarouselImage;
