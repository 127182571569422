import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const ListPembelian = ({ listDisplayedItem }) => {
  const API_URL = process.env.REACT_APP_API_URL;

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  return (
      <div>
        {listDisplayedItem.map((data) => {
          return (
            <div className="ModalListPengajuanFilterPengajuanCard">
              <div className="ModalListPengajuanFilterPengajuanCardHeader">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <img
                    style={{
                      width: "85px",
                      height: "85px",
                      borderRadius: 7,
                    }}
                    src={`${API_URL}/files/${data.fItemImage.filename}`}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      gap: "3px",
                      flex: 1,
                      paddingLeft: "15px",
                      paddingRight: "15px",
                      paddingTop: "5px",
                    }}
                  >
                    <text style={{ fontWeight: "bold" }}>{data.fItemName}</text>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "end",
                      }}
                    >
                      <text>Total</text>
                      <text style={{ fontWeight: "bold", fontSize: "18px" }}>
                        {numberWithCommas(data.fTotal)} {data.fDenom}
                      </text>
                    </div>
                  </div>
                </div>
              </div>
  
              {data.fPengajuanItem.map((v) => {
                return (
                  <div className="ModalListPengajuanFilterPengajuanCardList">
                    <div
                      style={{
                        height: "1px",
                        width: "100%",
                        backgroundColor: "#dddddd",
                        marginBottom: "10px",
                      }}
                    />
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "row",
                          justifyContent: "space-between",
                          paddingRight: "15px",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <text style={{ fontWeight: "bold" }}>
                            {v.fPengajuanName}
                          </text>
                          <text>{v.fProjectName}</text>
                        </div>
                        <text style={{ fontWeight: "bold", fontSize: "18px" }}>
                          {numberWithCommas(v.fTotal)} {data.fDenom}
                        </text>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
};
export default ListPembelian;
