import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";

import imageicon from "../../assets/image.png";
import closeicon from "../../assets/closered.png";
import bin from "../../assets/bin.png";
import addicon from "../../assets/add.png";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import pengajuan from "../../assets/pengajuan.png";

import "./AddSuratJalan.css";
import "@fontsource/poppins";

import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";
require("dayjs/locale/de");

const djs = dayjs().locale("de").format();

const AddSuratJalan = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const { state } = useLocation();
  const { data } = state;

  const navigate = useNavigate();

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [itemArrived, setItemArrived] = useState([]);

  for (let i = 0; i < data.fPengajuanItem.length; i++) {
    itemArrived.push();
  }
  const [ket, setKet] = useState("");
  const [poNumber, setPONumber] = useState("");
  const [poName, setPOName] = useState("");

  const [files, setFiles] = useState([]);
  const [modalItem, setModalItem] = useState(false);
  const [listPengajuan, setListPengajuan] = useState([]);
  const [items, setItems] = useState([]);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      if (response.data.user[0].fRole !== "SPV") {
        navigate("/");
      }
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  modalItem
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  const validationForm = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!poNumber.trim()) {
      validationErrors.number = "harus diisi";
    }

    if (!poName.trim()) {
      validationErrors.name = "harus diisi";
    }
    if (!ket.trim()) {
      validationErrors.ket = "harus diisi";
    }

    let count = 0;

    for (let i = 0; i < data.fPengajuanItem.length; i++) {
      if (itemArrived[i]) {
        count++;
        break;
      }
    }

    if (!count) {
      validationErrors.item = "Item harus diisi minimal 1";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0 && !loading) {
      handleSaveButton();
    }
  };

  const handleSaveButton = async () => {
    setLoading(true);
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("fProjectID", data.fProject.fID);
      bodyFormData.append("fPengajuanID", data.fID);
      bodyFormData.append("fPONo", poNumber);
      bodyFormData.append("fPOName", poName);
      bodyFormData.append("fSuratJalanKet", ket);

      for (let i = 0; i < data.fPengajuanItem.length; i++) {
        bodyFormData.append("fPengajuanItem", JSON.stringify(data.fPengajuanItem[i]));
        bodyFormData.append("fQuantity", itemArrived[i]);
      }

      const response = await axiosJWT.post(
        `${API_URL}/addsuratjalan`,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "135px",
        position: "relative",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: pengajuan,
          title: "Tambah Pre-Order",
          name: "John Doe",
          job: "SPV",
        }}
      />

      <div className="AddPengajuanContainer">
        <text
          style={{ fontSize: "20px", fontWeight: "bold", color: "#D0A360" }}
        >
          {data.fPengajuanName}
        </text>
        <div className="AddPengajuanInput">
          <text style={{ fontWeight: "bold" }}>
            Nomor PO
            {errors.number && (
              <span
                style={{ color: "red", fontSize: "14px", fontWeight: "normal" }}
              >
                {" "}
                *{errors.number}
              </span>
            )}
          </text>
          <input
            value={poNumber}
            onChange={(e) => {
              setPONumber(e.target.value);
            }}
            className="AddNotulenInputText"
            type="text"
            placeholder="Nomor PO"
          />
        </div>

        <div className="AddPengajuanInput">
          <text style={{ fontWeight: "bold" }}>
            Nama PO
            {errors.name && (
              <span
                style={{ color: "red", fontSize: "14px", fontWeight: "normal" }}
              >
                {" "}
                *{errors.name}
              </span>
            )}
          </text>
          <input
            value={poName}
            onChange={(e) => {
              setPOName(e.target.value);
            }}
            className="AddNotulenInputText"
            type="text"
            placeholder="Nama PO"
          />
        </div>

        <div className="AddPengajuanInput">
          <text style={{ fontWeight: "bold" }}>
            Keterangan PO
            {errors.ket && (
              <span
                style={{ color: "red", fontSize: "14px", fontWeight: "normal" }}
              >
                {" "}
                *{errors.ket}
              </span>
            )}
          </text>
          <div className="AddPengajuanInputNotes">
            <textarea
              style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
              minLength={150}
              onChange={(v) => {
                setKet(v.target.value);
              }}
              className="AddPengajuanInputNote"
              type="text"
              placeholder="Keterangan"
            >
              {ket}
            </textarea>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "95%",
            gap: "15px",
          }}
        >
          <text style={{ fontWeight: "bold" }}>
            Jumlah Item
            {errors.item && (
              <span
                style={{ color: "red", fontSize: "14px", fontWeight: "normal" }}
              >
                {" "}
                *{errors.item}
              </span>
            )}
          </text>
          {data.fPengajuanItem.map((d, i) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <div
                  style={{
                    height: d.fStatus[0].fStatus !== "PURCHASED" ? 0 : "auto",
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: "15px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "stretch",
                      borderRadius: 5,
                    }}
                    src={`${API_URL}/files/${d.fItem.fItemImage.filename}`}
                    alt=""
                  />
                  <div className="AddSuratJalanItemInputJumlah">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <text style={{ fontWeight: "bold" }}>Nama</text>
                      <text>{d.fItem.fItemName}</text>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <text style={{ fontWeight: "bold" }}>
                        Sisa {`(${d.fDenom})`}
                      </text>
                      <text>{d.fQuantity - d.fArrived}</text>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: d.fStatus[0].fStatus !== "PURCHASED" ? 0 : "auto",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <text style={{ fontWeight: "bold" }}>
                    Jumlah {`(${d.fDenom})`}
                  </text>
                  <input
                    value={itemArrived[i]}
                    onChange={(e) => {
                      let temp = [...itemArrived];
                      temp[i] = e.target.value;
                      setItemArrived(temp);
                    }}
                    className="AddJumlahSuratJalanInputText"
                    type="number"
                    placeholder="0"
                  />
                </div>
              </div>
            );
          })}
        </div>

        <div style={{ display: "flex", width: "95%" }}>
          <div
            onClick={validationForm}
            className="AddPengajuanButton"
            style={{ opacity: loading ? "0.7" : "1" }}
          >
            {loading && <span>Loading...</span>}
            {!loading && <span>Simpan</span>}
          </div>
        </div>
      </div>
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default AddSuratJalan;
