import "./LoginPage.css";

import banner from "../../assets/banner.png";
import user_icon from "../../assets/person.png";
import password_icon from "../../assets/password.png";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { useState } from "react";

const LoginPage = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [loginCred, setLoginCred] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  axios.defaults.withCredentials = true;

  const Auth = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      await axios.post(`${API_URL}/login`, {
        data: loginCred,
        fPassword: password,
      });

      navigate("/");
    } catch (error) {
      if (error.response) {
        setLoading(false)
        setMsg(error.response.data.msg);
      }
    }
  };

  // // FE DOANG
  // const Auth = async (e) => {
  //   navigate('/', { state: {id: loginCred}});
  // }

  return (
    <div
      alt=""
      style={{
        width: "100vw",
        height: "100vh",
        backgroundSize: "100% 100%",
        backgroundImage: `url(${banner})`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        objectFit: "cover",
        position: "fixed",
        top: 0,
        zIndex: 9999,
      }}
    >
      <div className="loginContainer">
        <div className="loginHeader">
          <div className="loginText">Sign In</div>
          <div className="loginUnderline"></div>
        </div>

        <div className="loginInputs">
          {msg && (
            <text style={{ width: "80%", margin: "auto", color: "red" }}>
              *{msg}
            </text>
          )}
          <div className="loginInput">
            <img className="LoginImg" src={user_icon} alt="" />
            <input
              className="LoginInput2"
              type="text"
              placeholder="Username/Email"
              value={loginCred}
              onChange={(e) => setLoginCred(e.target.value)}
            />
          </div>

          <div className="loginInput">
            <img className="LoginImg" src={password_icon} alt="" />
            <input
              className="LoginInput2"
              type="password"
              placeholder="******"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>

        <div
          style={{ opacity: loading ? "0.7" : "1" }}
          onClick={(e) => {
            if(!loading){
              Auth(e);
            }
          }}
          className="loginSubmit"
        >
          {" "}
          {loading && <span>Loading...</span>}
          {!loading && <span>Sign In</span>}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
