import "./PlanningCard.css";
import profile from '../../assets/profile.png'

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const PlanningCard = ({ data }) => {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const handleClickTask = (v) => {
    navigate('/detailplanning', { state: {fID: v.fID}});
  }

  return (
    <div onClick={() => handleClickTask(data)} className="Task_Container">
      <text style={{ fontSize: "14px" }}>{dayjs(data.fPlanningDate).format('DD MMM YYYY').toString()}</text>

      <div style={{ height: '54px', marginTop: '10px' }}>
        <text style={{ fontSize: '18px', color: '#D0A360', fontWeight: 'bold' }}>{data.fPlanningName}</text>
      </div>   
      <div style={{ height: '10px' }} />
      <text style={{ fontWeight: 'bold', fontSize: '14px' }}>Notes:</text>
      <></>

      <div className="TaskNotesWrapper">
      <text className="TaskNotes" style={{ fontSize: '14px' }}>{data.fPlanningKet}</text>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginTop: '15px' }}>
        <img src={`${API_URL}/files/${data.fProfile.fImages}`} alt="" style={{ width: '40px', height: '40px', marginRight: '10px', borderRadius: 100 }} />
        <text style={{ fontSize: '14px', fontWeight: 600 }}>{data.fProfile.fName}</text>
      </div>
    </div>
  );
};

export default PlanningCard;
