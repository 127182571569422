import "./NotulenCard.css";
import profile from '../../assets/profile.png'

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const Notulencard = ({ data }) => {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  let status = data.fStatus[0].fStatus;

  if(data.fStatus[0].fStatus === 'NEW') status = 'Need SPV Sign';
  if(data.fStatus[0].fStatus === 'CHECKED1') status = 'Need Approval';
  if(data.fStatus[0].fStatus === 'APPROVED') status = 'Need Final Sign';
  if(data.fStatus[0].fStatus === 'REJECTED') status = 'Rejected';
  if(data.fStatus[0].fStatus === 'CHECKED2') status = 'Completed'

  const handleClickNotulen = (v) => {
    navigate('/detailnotulen', { state: {fID: v.fID}});
  }

  return (
    <div onClick={() => handleClickNotulen(data)} className="Notulen_Container">
      <text style={{ fontSize: "14px" }}>{dayjs(data.fNotulenDate).format('DD MMM YYYY').toString()}</text>

      <img className="LaporanImage" src={`${API_URL}/files/${data.fProject.fImages[0].filename}`} alt="" style={{ marginTop: '10px', marginBottom: '10px' }} />
      <div style={{ height: '54px' }}>
        <text style={{ fontSize: '18px', color: '#D0A360', fontWeight: 'bold' }}>{data.fCategory}</text>
      </div>
      <div style={{ height: '10px' }} />
      <text style={{ fontWeight: 'bold', fontSize: '14px' }}>Notes:</text>
      <></>
      <div className="NotulenNotesWrapper">
      <text className="NotulenNotes" style={{ fontSize: '14px' }}>{data.fKet}</text>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginTop: '15px' }}>
        <img src={`${API_URL}/files/${data.fUser.fImages}`} alt="" style={{ width: '40px', height: '40px', marginRight: '10px', borderRadius: 100 }} />
        <text style={{ fontSize: '14px', fontWeight: 600 }}>{data.fUser.fName}</text>
      </div>

      <div style={{ borderRadius: 100, borderColor: '#D0A360', color: '#D0A360', borderStyle: 'solid', borderWidth: '3px', width: '85%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '15px', marginLeft: 'auto', marginRight: 'auto', padding: '4px 0' }} >
        <text style={{ fontSize: '14px', fontWeight: 'bold'}}>{status}</text>
      </div>
    </div>
  );
};

export default Notulencard;
