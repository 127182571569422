import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";

import file from "../../assets/file.png";
import bin from "../../assets/bin.png";
import Dropdown from "../../Components/Dropdown/Dropdown";
import DownloadCard from "../../Components/DownloadCard/DownloadCard";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import projectlogo from "../../assets/projectberjalan.png";

import "./AddProject.css";
import "@fontsource/poppins";

import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import axios from "axios";
import { jwtDecode } from "jwt-decode";

import dayjs from "dayjs";
import SingleFileUploadComponent from "../../Components/SingleFileUploadComponent/SingleFileUploadComponent";
import fileDownload from "js-file-download";
require("dayjs/locale/de");

const djs = dayjs().locale("de").format();

const AddProject = () => {
  const inputImagesRef = useRef(null);
  const inputKontrakRef = useRef(null);
  const inputRABRef = useRef(null);
  const inputBOQRef = useRef(null);
  const inputTimelineRef = useRef(null);

  // Pasang di semua screen yang ga butuh data dari yang sebelumnya
  const API_URL = process.env.REACT_APP_API_URL;
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState("Pilih Owner");
  const [selectedClientData, setSelectedData] = useState();

  const [data, setData] = useState({
    fProjectName: "",
    fProjectClientID: "",
    fProjectClientImage: "",
    fClientName: "",
    fImages: "",
    fKet: "",
    fKontrak: "",
    fRAB: "",
    fBOQ: "",
    fTimeline: "",
  });

  const [images, setImages] = useState([]);
  const [kontrak, setKontrak] = useState("");
  const [RAB, setRAB] = useState("");
  const [BOQ, setBOQ] = useState("");
  const [timeline, setTimeline] = useState("");

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    getClient();
  }, [token]);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getClient = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getallclient`,
        {
          fID: fID,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setClients(response.data);
      selectedClient(response.data[0].fName);
    } catch (error) {}
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      if (
        response.data.user[0].fRole !== "BOSS" &&
        response.data.user[0].fRole !== "PM"
      ) {
        navigate("/");
      }
      setUser(response.data.user[0]);
      setToken(response.data.accessToken);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };
  // sampai sini

  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const handleImageClick = () => {
    inputImagesRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImages([file, ...images]);
    }
  };

  const removeImage = (indexToRemove) => {
    setImages((prevFiles) =>
      prevFiles.filter((images, index) => index !== indexToRemove)
    );
  };

  const handleImageDelete = (i) => {
    removeImage(i);
  };

  const validationForm = (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (!data.fProjectName.trim()) {
      validationErrors.project = "harus diisi";
    }

    if (selectedClient === "Pilih Owner") {
      validationErrors.owner = "pilih owner";
    }

    if (!data.fKet.trim()) {
      validationErrors.ket = "harus diisi";
    }

    if (images.length === 0) {
      validationErrors.image = "minimal 1 gambar";
    }

    if (!kontrak) {
      validationErrors.kontrak = "pilih Kontrak";
    }

    if (!RAB) {
      validationErrors.rab = "pilih RAB";
    }

    if (!BOQ) {
      validationErrors.boq = "pilih BOQ";
    }

    if (!timeline) {
      validationErrors.timeline = "pilih Timeline";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0 && !loading) {
      handleSaveButton();
    }
  };

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  const handleSaveButton = async () => {
    setLoading(true);
    try {
      data.fProjectClientID = selectedClientData.fID;
      data.fProjectClientImage = selectedClientData.fImages;
      data.fClientName = selectedClient;
      data.fImages = images;
      data.fKontrak = kontrak;
      data.fRAB = RAB;
      data.fBOQ = BOQ;
      data.fTimeline = timeline;

      let tempImages = [];
      for (let i = 0; i < data.fImages.length; i++) {
        if (typeof data.fImages[i].filename !== "string") {
          const base64File = await blobToBase64(data.fImages[i]);
          tempImages.push({
            file: base64File,
            ext: getFileExtension(data.fImages[i].name),
            size: data.fImages[i].size,
            type: data.fImages[i].type
          });
        }else{
          tempImages.push(data.fImages[i]);
        }
      }

      const bodyData = {
        fProjectName: data.fProjectName,
        fProjectClientID: data.fProjectClientID,
        fProjectClientImage: data.fProjectClientImage,
        fClientName: data.fClientName,
        fKet: data.fKet,
        fKontrak: data.fKontrak,
        fRAB: data.fRAB,
        fBOQ: data.fBOQ,
        fTimeline: data.fTimeline,
        fImages: tempImages
      }

      await axiosJWT.post(
        `${API_URL}/addproject`,
        bodyData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate('/');
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "130px",
        minHeight: "100vh",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: projectlogo,
          title: "Tambah Project",
          name: "Vincent",
          job: "SPV",
          type: "Add",
        }}
      />

      <div className="AddNotulenContainer">
        <div className="AddNotulenInput">
          <text>
            Nama Project
            {errors.project && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.project}
              </span>
            )}
          </text>
          <input
            onChange={(e) => {
              data.fProjectName = e.target.value;
            }}
            className="AddNotulenInputText"
            type="text"
            placeholder="ProjectName"
          />
        </div>

        <div className="AddNotulenInput">
          <text>
            Owner
            {errors.owner && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.owner}
              </span>
            )}
          </text>
          <Dropdown
            selected={selectedClient}
            setSelected={setSelectedClient}
            setSelectedData={setSelectedData}
            options={clients}
            type={"fName"}
          />
        </div>

        <div className="AddNotulenInput">
          <text>
            {"Foto Sampul (Maks.5)"}
            {errors.image && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.image}
              </span>
            )}
          </text>
          {images.length < 5 && (
            <div>
              <div
                onClick={handleImageClick}
                className="AddNotulenFile"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px 10px",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <img
                  style={{ width: "27px", height: "27px" }}
                  src={file}
                  alt=""
                />
                <text style={{ textAlign: "center" }}>
                  <span style={{ color: "#818CF8" }}>click to browse</span>
                </text>
              </div>
              <input
                type="file"
                ref={inputImagesRef}
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </div>
          )}
        </div>
        {/* download card */}
        <div className="AddProjectCenterer">
          <div className="AddProjectDownloadCardContainer">
            {images.map((d, i) => {
              return (
                <div>
                  <div className="AddProjectDownloadCardWrapper">
                    <img
                      className="AddProjectDownloadImage"
                      src={URL.createObjectURL(d)}
                      alt=""
                    />
                    <div
                      style={{ backgroundColor: "#378D23", marginTop: "10px" }}
                      className="AddProjectDownloadButton"
                      onClick={() => {
                        handleDownload(URL.createObjectURL(d), d.name);
                      }}
                    >
                      Download
                    </div>
                    <div
                      onClick={() => {
                        handleImageDelete(i);
                      }}
                      style={{
                        backgroundColor: "#BC1C12",
                        marginBottom: "20px",
                      }}
                      className="AddProjectDownloadButton"
                    >
                      Delete
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Kontrak */}
        <SingleFileUploadComponent
          data={kontrak}
          setData={setKontrak}
          typeFile={"Kontrak"}
          compRef={inputKontrakRef}
        />
        <div className="AddNotulenInput">
          {errors.kontrak && (
            <span style={{ fontSize: "14px", color: "red" }}>
              *{errors.kontrak}
            </span>
          )}
        </div>

        {/* RAB */}
        <SingleFileUploadComponent
          data={RAB}
          setData={setRAB}
          typeFile={"RAB"}
          compRef={inputRABRef}
        />

        <div className="AddNotulenInput">
          {errors.rab && (
            <span style={{ fontSize: "14px", color: "red" }}>
              *{errors.rab}
            </span>
          )}
        </div>

        {/* BOQ */}
        <SingleFileUploadComponent
          data={BOQ}
          setData={setBOQ}
          typeFile={"BOQ"}
          compRef={inputBOQRef}
        />

        <div className="AddNotulenInput">
          {errors.boq && (
            <span style={{ fontSize: "14px", color: "red" }}>
              *{errors.boq}
            </span>
          )}
        </div>

        {/* Timeline */}
        <SingleFileUploadComponent
          data={timeline}
          setData={setTimeline}
          typeFile={"Timeline"}
          compRef={inputTimelineRef}
        />

        <div className="AddNotulenInput">
          {errors.timeline && (
            <span style={{ fontSize: "14px", color: "red" }}>
              *{errors.timeline}
            </span>
          )}
        </div>

        <div className="AddNotulenInput">
          <text>
            Keterangan{" "}
            {errors.ket && (
              <span style={{ fontSize: "14px", color: "red" }}>
                *{errors.ket}
              </span>
            )}
          </text>
          <div className="AddNotulenInputNotes">
            <textarea
              style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
              minLength={150}
              onChange={(e) => {
                data.fKet = e.target.value;
              }}
              className="AddNotulenInputNote"
              type="text"
              placeholder="Keterangan"
            >
              {data.fKet}
            </textarea>
          </div>
        </div>

        <div style={{ display: "flex", width: "95%" }}>
          <div
            onClick={validationForm}
            className="AddProgressButton"
            style={{ opacity: loading ? "0.7" : "1" }}
          >
            {loading && <span>Loading...</span>}
            {!loading && <span>Simpan</span>}
          </div>
        </div>
      </div>
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default AddProject;
