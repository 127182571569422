import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";

import file from "../../assets/file.png";

import pembayaranlogo from "../../assets/pembayaran.png";

import "@fontsource/poppins";

import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";
require("dayjs/locale/de");

const djs = dayjs().locale("de").format();

const AddPembayaran = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [project, setProject] = useState([]);
  const [selectedProject, setSelectedProject] = useState("Pilih Project");
  const [selectedProjectData, setSelectedProjectData] = useState();

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("Pilih User");
  const [selectedUserData, setSelectedUserData] = useState();

  const [taskName, setTaskName] = useState("");
  const [deadline, setDeadline] = useState(dayjs(new Date()));

  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [ket, setKet] = useState("");
  const [price, setPrice] = useState();
  const [category, setCategory] = useState("");

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    getAllProject();
  }, [user]);

  useEffect(() => {
    getAllUsers();
  }, [selectedProjectData]);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      if (
        response.data.user[0].fRole !== "BOSS" &&
        response.data.user[0].fRole !== "PM" &&
        response.data.user[0].fRole !== "FINANCE"
      ) {
        navigate("/");
      }
      setUser(response.data.user[0]);
      setSelectedProject(response.data.project.fProjectName);
      setSelectedProjectData(response.data.project);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllProject = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getprojectdropdown`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProject(response.data);
    } catch (error) {}
  };

  const getAllUsers = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getallprojectdropdown`,
        {
          fID: selectedProjectData.fID,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setUsers(response.data);
    } catch (error) {}
  };

  const validationForm = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!title.trim()) {
      validationErrors.title = "harus diisi";
    }
    if (!ket.trim()) {
      validationErrors.ket = "harus diisi";
    }
    if (!category.trim()) {
      validationErrors.category = "harus diisi";
    }
    if (!price) {
      validationErrors.price = "harus diisi";
    }

    if (!image) {
      validationErrors.image = "pilih gambar";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0 && !loading) {
      handleSaveButton();
    }
  };

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  const handleSaveButton = async () => {
    setLoading(true);

    try {
      const base64File = await blobToBase64(image);
      const tempFile = {
        file: base64File,
        ext: getFileExtension(image.name),
        size: image.size,
        type: image.type,
      };

      const bodyData = {
        fPaymentName: title,
        fPrice: price,
        fCategory: category,
        fKet: ket,
        fImage: tempFile
      }

      await axiosJWT.post(`${API_URL}/addpembayaran`, bodyData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      navigate("/");
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "135px",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: pembayaranlogo,
          title: "Add Pembayaran",
          name: user.fName,
          job: user.fRole,
        }}
      />

      <div className="AddNotulenContainer">
        <div className="AddNotulenInput">
          <text>
            Nama Pembayaran
            {errors.title && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.title}
              </span>
            )}
          </text>
          <input
            value={title}
            onChange={(v) => {
              setTitle(v.target.value);
            }}
            className="AddNotulenInputText"
            type="text"
            placeholder="Judul"
          />
        </div>

        <div className="AddNotulenInput">
          <text>
            Kategori
            {errors.category && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.category}
              </span>
            )}
          </text>
          <input
            value={category}
            onChange={(v) => {
              setCategory(v.target.value);
            }}
            className="AddNotulenInputText"
            type="text"
            placeholder="Beli Item"
          />
        </div>

        <div className="AddNotulenInput">
          <text>
            Jumlah
            {errors.price && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.price}
              </span>
            )}
          </text>
          <input
            value={price}
            onChange={(v) => {
              setPrice(v.target.value);
            }}
            className="AddNotulenInputText"
            type="number"
            placeholder="100000"
          />
        </div>

        <div className="AddNotulenInput">
          <text>
            Foto Bukti
            {errors.image && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.image}
              </span>
            )}
          </text>
          <div>
            {image ? (
              <img
                onClick={() => handleImageClick()}
                style={{
                  width: "300px",
                  height: "150px",
                  objectFit: "cover",
                  backgroundColor: "white",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  boxSizing: "border-box",
                  borderRadius: 7,
                }}
                src={URL.createObjectURL(image)}
                alt=""
              />
            ) : (
              <div
                onClick={() => handleImageClick()}
                style={{
                  width: "300px",
                  height: "150px",
                  objectFit: "cover",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  boxSizing: "border-box",
                  backgroundColor: "white",
                  borderRadius: 7,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <img
                  style={{ width: "27px", height: "27px" }}
                  src={file}
                  alt=""
                />
                <text style={{ textAlign: "center" }}>
                  <span style={{ color: "#818CF8" }}>click to browse</span>
                </text>
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
        </div>

        <div className="AddNotulenInput">
          <text style={{ fontWeight: "bold" }}>
            Keterangan
            {errors.ket && (
              <span
                style={{ color: "red", fontSize: "14px", fontWeight: "normal" }}
              >
                {" "}
                *{errors.ket}
              </span>
            )}
          </text>
          <div className="AddNotulenInputNotes">
            <textarea
              style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
              minLength={150}
              onChange={(v) => {
                setKet(v.target.value);
              }}
              className="AddNotulenInputNote"
              type="text"
              placeholder="Keterangan"
            >
              {ket}
            </textarea>
          </div>
        </div>

        <div style={{ display: "flex", width: "95%" }}>
          <div
            onClick={validationForm}
            className="AddProgressButton"
            style={{ opacity: loading ? "0.7" : "1" }}
          >
            {loading && <span>Loading...</span>}
            {!loading && <span>Simpan</span>}
          </div>
        </div>
      </div>
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default AddPembayaran;
