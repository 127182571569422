import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";

import file from "../../assets/file.png";
import DownloadCard from "../../Components/DownloadCard/DownloadCard";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import planninglogo from "../../assets/planning.png";

import "./AddPlanning.css";
import "@fontsource/poppins";

import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";
require("dayjs/locale/de");

const djs = dayjs().locale("de").format();

const AddPlanning = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const inputFilesRef = useRef(null);

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [planName, setPlanName] = useState("");
  const [date, setDate] = useState(dayjs(new Date()));
  const [ket, setKet] = useState("");
  const [files, setFiles] = useState([]);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      if (response.data.user[0].fRole !== "SPV") {
        navigate("/");
      }
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const handleFileClick = () => {
    inputFilesRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFiles([file, ...files]);
    }
  };

  const removeFile = (indexToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((files, index) => index !== indexToRemove)
    );
  };

  const handleFileDelete = (i) => {
    removeFile(i);
  };

  const validationForm = (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (!planName.trim()) {
      validationErrors.name = "Harus diisi";
    }

    if (!ket.trim()) {
      validationErrors.ket = "Harus diisi";
    }

    if (files.length === 0) {
      validationErrors.file = "Minimal 1 file";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      handleSaveButton();
    }
  };

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  const handleSaveButton = async () => {
    setLoading(true);

    let tempFiles = [];
    for (let i = 0; i < files.length; i++) {
      const base64File = await blobToBase64(files[i]);
      tempFiles.push({
        file: base64File,
        ext: getFileExtension(files[i].name),
        size: files[i].size,
        type: files[i].type,
      });
    }

    const bodyData = {
      fPlanningName: planName,
      fPlanningKet: ket,
      fPlanningDate: date,
      fPlanningFile: tempFiles
    }

    try {
      await axiosJWT.post(
        `${API_URL}/addplanning`,
        bodyData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "135px",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: planninglogo,
          title: "Add Planning",
          name: user.fName,
          job: user.fRole,
        }}
      />

      <div className="AddNotulenContainer">
        <div className="AddNotulenInput">
          <text style={{ fontWeight: "bold" }}>
            Nama Planning
            {errors.name && (
              <span
                style={{ fontWeight: "normal", fontSize: "14px", color: "red" }}
              >
                {" "}
                *{errors.name}
              </span>
            )}
          </text>
          <input
            value={planName}
            onChange={(v) => setPlanName(v.target.value)}
            className="AddNotulenInputText"
            type="text"
            placeholder="Planning"
          />
        </div>

        <div className="AddNotulenInput">
          <text style={{ fontWeight: "bold" }}>Planning Date</text>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ width: "50%" }}>
              <DatePicker
                format="DD/MM/YYYY"
                disablePast={true}
                value={date}
                onChange={(v) => {
                  setDate(v);
                }}
              />
            </div>
          </LocalizationProvider>
        </div>
        <div className="AddNotulenInput">
          <text>
            {"File (Maks.3)"}
            {errors.file && (
              <span
                style={{ fontWeight: "normal", fontSize: "14px", color: "red" }}
              >
                {" "}
                *{errors.file}
              </span>
            )}
          </text>
          {files.length < 3 && (
            <div>
              <div
                onClick={handleFileClick}
                className="AddNotulenFile"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px 10px",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <img
                  style={{ width: "27px", height: "27px" }}
                  src={file}
                  alt=""
                />
                <text style={{ textAlign: "center" }}>
                  <span style={{ color: "#818CF8" }}>click to browse</span>
                </text>
              </div>
              <input
                type="file"
                ref={inputFilesRef}
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </div>
          )}
        </div>

        <div style={{ gap: "15px" }} className="AddNotulenInput">
          {files.map((d, i) => {
            return <DownloadCard file={d} typeFile={`File ${i + 1}`} />;
          })}
        </div>

        <div className="AddNotulenInput">
          <text style={{ fontWeight: "bold" }}>
            Keterangan
            {errors.ket && (
              <span
                style={{ fontWeight: "normal", fontSize: "14px", color: "red" }}
              >
                {" "}
                *{errors.ket}
              </span>
            )}
          </text>
          <div className="AddNotulenInputNotes">
            <textarea
              style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
              minLength={150}
              onChange={(v) => {
                setKet(v.target.value);
              }}
              className="AddNotulenInputNote"
              type="text"
              placeholder="Keterangan"
            >
              {ket}
            </textarea>
          </div>
        </div>

        <div style={{ display: "flex", width: "95%" }}>
          <div onClick={validationForm} className="AddProgressButton">
            {loading && <span>Loading...</span>}
            {!loading && <span>Simpan</span>}
          </div>
        </div>
      </div>
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default AddPlanning;
