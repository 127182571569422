import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";

import file from "../../assets/file.png";
import bin from "../../assets/bin.png";
import plusicon from "../../assets/plusicon.png";
import minusicon from "../../assets/minusicon.png";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import tambahprogresslogo from "../../assets/tambahprogress.png";

import "./ViewAllUserProject.css";
import "@fontsource/poppins";

import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";
require("dayjs/locale/de");

const djs = dayjs().locale("de").format();

const ViewAllUserProject = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const { state } = useLocation();
  const { data } = state;
  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [allRelatedUsers, setAllRelatedUsers] = useState([]);
  const [allOptionUsers, setAllOptionUsers] = useState([]);
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    getAllUsers();
  }, [token]);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID, page: 1, limit: 3, type: 'PM'
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(new Date(response.data.data[0].fCheckInDateTime));
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate('/')
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate('/')
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllUsers = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getalluserproject`,
        {
          fID: data.fID,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAllRelatedUsers(response.data.allProjectUsers);
      setAllOptionUsers(response.data.AllOptionUsers);
    } catch (error) {}
  };

  const handleSaveButton = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/adduserproject`,
        {
          fID: data.fID,
          data: allRelatedUsers,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate('/')
    } catch (error) {
      console.log(error);
    }
  };

  const onHandleClickAddUser = async (v, i) => {
    setAllRelatedUsers([...allRelatedUsers, v]);
    setAllOptionUsers((prevList) => prevList.filter((d, index) => index !== i));
  };

  const onHandleClickRemoveUser = async (v, i) => {
    setAllOptionUsers([...allOptionUsers, v]);
    setAllRelatedUsers((prevList) =>
      prevList.filter((d, index) => index !== i)
    );
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "130px",
        minHeight: "100vh",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: tambahprogresslogo,
          title: "Add Progress",
          name: user.fName,
          job: user.fRoleAlias,
        }}
      />

      <div className="ViewAllUserProjectContainer">
        <div
          onClick={handleSaveButton}
          style={{ borderRadius: 5 }}
          className="ViewAlluserProjectAddButton"
        >
          Simpan
        </div>
        <text
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            marginBottom: "20px",
            color: "#D0A360",
          }}
        >
          {data.fProjectName}
        </text>

        <text
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          User Terkait
        </text>

        <div className="ViewAllUserProjectListContainer">
          {allRelatedUsers
            .sort((a, b) =>
              a.fName.toUpperCase() < b.fName.toUpperCase() ? -1 : 1
            )
            .map((d, i) => {
              const cond = i % 2 === 0;
              return (
                <div
                  style={{
                    borderRadius: 100,
                    backgroundColor: cond ? "#B2DEFC" : "#D6EAF9",
                  }}
                  className="ViewAllUserProjectListWrapper"
                >
                  <div className="ViewAllUserProjectProfileWrapper">
                    <img
                      style={{ borderRadius: 100 }}
                      className="ViewAllUserProjectImages"
                      src={`${API_URL}/files/${d.fImages}`}
                    />
                    <div className="UserInfoContainer">
                      <text style={{ fontSize: "18px" }}>{d.fName}</text>
                      <div />
                      <text>{d.fRoleAlias}</text>
                    </div>
                  </div>
                  {(d.fRole !== "BOSS" && d.fRole !== 'CLIENT') && (
                    <img
                      onClick={() => onHandleClickRemoveUser(d, i)}
                      style={{ width: "30px", marginRight: "15px" }}
                      src={minusicon}
                      alt=""
                    />
                  )}
                </div>
              );
            })}
        </div>

        <text
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            marginTop: "30px",
            marginBottom: "10px",
          }}
        >
          Semua User
        </text>

        <div className="ViewAllUserProjectListContainer">
          {allOptionUsers
            .sort((a, b) =>
              a.fName.toUpperCase() < b.fName.toUpperCase() ? -1 : 1
            )
            .map((d, i) => {
              const cond = i % 2 === 0;
              return (
                <div
                  style={{
                    borderRadius: 100,
                    backgroundColor: cond ? "#B2DEFC" : "#D6EAF9",
                  }}
                  className="ViewAllUserProjectListWrapper"
                >
                  <div className="ViewAllUserProjectProfileWrapper">
                    <img
                      style={{ borderRadius: 100 }}
                      className="ViewAllUserProjectImages"
                      src={`${API_URL}/files/${d.fImages}`}
                    />
                    <div className="UserInfoContainer">
                      <text style={{ fontSize: "18px" }}>{d.fName}</text>
                      <div />
                      <text>{d.fRoleAlias}</text>
                    </div>
                  </div>
                  {d.fRole !== "BOSS" && (
                    <img
                      onClick={() => {
                        onHandleClickAddUser(d, i);
                      }}
                      style={{ width: "30px", marginRight: "15px" }}
                      src={plusicon}
                      alt=""
                    />
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default ViewAllUserProject;
