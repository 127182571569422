import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";
import DownloadCard from "../../Components/DownloadCard/DownloadCard";
import kasbonlogo from "../../assets/kasbon.png";

import ReactSignatureCanvas from "react-signature-canvas";

import "@fontsource/poppins";

import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";

import { animated } from "react-spring";

import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";

import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";

const DetailKasbon = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const AnimatedDiv = animated.div;

  const { state } = useLocation();
  const { fID } = state;
  const [index, setIndex] = useState(0);
  const [data, setData] = useState({
    fProject: {
      fProjectName: "",
      fImages: [
        {
          filename: "",
        },
      ],
    },
    fUser: {
      fImages: "",
    },
    fStatus: [{ fStatus: "NEW" }],
  });

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [valueInput, setValueInput] = useState();
  const [ketInput, setKetInput] = useState("");

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    getDetailKasbon();
  }, [user, reload]);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getDetailKasbon = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getdetailkasbon`,
        { fID: fID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data);
      setValueInput(response.data.fKasbonAmount);
    } catch (error) {}
  };

  const renderTextWithLineBreaks = (text) => {
    return (text || "").split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  }

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  let status = data.fStatus[0].fStatus;

  if (data.fStatus[0].fStatus === "NEW") status = "Need SPV Sign";
  if (data.fStatus[0].fStatus === "CHECKED1") status = "Need Approval";
  if (data.fStatus[0].fStatus === "APPROVED") status = "Need Final Sign";
  if (data.fStatus[0].fStatus === "REJECTED") status = "Rejected";
  if (data.fStatus[0].fStatus === "CHECKED2") status = "Completed";

  const supervisorSignRef = useRef(null);
  const mandorSignRef = useRef(null);
  const ownerPMSignRef = useRef(null);

  const handleClearSupervisor = () => {
    if (supervisorSignRef.current) {
      supervisorSignRef.current.clear();
    }
  };

  const handleClearMandor = () => {
    if (mandorSignRef.current) {
      mandorSignRef.current.clear();
    }
  };

  const handleClearOwnerPM = () => {
    if (ownerPMSignRef.current) {
      ownerPMSignRef.current.clear();
    }
  };

  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  const validationForm = (type) => {
    const validationErrors = {};

    if (type === "firstttd") {
      if (supervisorSignRef.current.isEmpty()) {
        validationErrors.spv = "wajib TTD";
      }

      if (mandorSignRef.current.isEmpty()) {
        validationErrors.mandor = "wajib TTD";
      }

      setErrors(validationErrors);
      if (Object.keys(validationErrors).length === 0 && !loading) {
        handleSaveSignSPVMandor();
      }
    }

    if (type === "secondttd") {
      if (ownerPMSignRef.current.isEmpty()) {
        validationErrors.pmowner = "wajib TTD";
      }

      setErrors(validationErrors);
      if (Object.keys(validationErrors).length === 0 && !loading) {
        handleSaveSignPMOwner();
      }
    }

    if (type === "approval" || type === "reject") {
      if (!valueInput) {
        validationErrors.value = "harus diisi";
      }

      if (!ketInput.trim()) {
        validationErrors.ket = "harus diisi";
      }

      setErrors(validationErrors);

      if (type === "approval") {
        if (Object.keys(validationErrors).length === 0 && !loading) {
          handleClickApprove();
        }
      }

      if (type === "reject") {
        if (Object.keys(validationErrors).length === 0 && !loading) {
          handleclickReject();
        }
      }
    }
  };

  const handleClickApprove = async () => {
    setLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append("fID", data.fID);
    bodyFormData.append("fKasbonAmount", valueInput);
    bodyFormData.append("fKetApproval", ketInput);
    bodyFormData.append("fProjectID", data.fProject.fID);
    bodyFormData.append("fStatus", "APPROVED");

    try {
      await axiosJWT.post(`${API_URL}/updatestatuskasbon`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      setReload(!reload);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleclickReject = async () => {
    setLoading(true);

    var bodyFormData = new FormData();
    bodyFormData.append("fID", data.fID);
    bodyFormData.append("fKasbonAmount", valueInput);
    bodyFormData.append("fKetApproval", ketInput);
    bodyFormData.append("fProjectID", data.fProject.fID);
    bodyFormData.append("fStatus", "REJECTED");

    try {
      await axiosJWT.post(`${API_URL}/updatestatuskasbon`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      setReload(!reload);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const handleSaveSignSPVMandor = async () => {
    setLoading(true);

    try {
      let tempAssign = [];
      const imgDataSignSPV = dataURLtoBlob(
        supervisorSignRef.current.toDataURL("image/png")
      );
      const base64SignSPV = await blobToBase64(imgDataSignSPV);
      tempAssign.push({
        file: base64SignSPV,
        ext: ".png",
        size: imgDataSignSPV.size,
        type: imgDataSignSPV.type,
      });

      const imgDataSignMandor = dataURLtoBlob(
        mandorSignRef.current.toDataURL("image/png")
      );
      const base64SignMandor = await blobToBase64(imgDataSignMandor);
      tempAssign.push({
        file: base64SignMandor,
        ext: ".png",
        size: imgDataSignMandor.size,
        type: imgDataSignMandor.type,
      });

      const bodyData = {
        fProjectID: data.fProject.fID,
        fKasbonID: data.fID,
        fStatus: "CHECKED1",
        fUserID: [data.fUser.fID, data.fProject.fProjectClientID],
        fRole: ["SPV", "MANDOR"],
        fAssign: tempAssign,
      };

      await axiosJWT.post(`${API_URL}/addkasbonassign`, bodyData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setReload(!reload);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSaveSignPMOwner = async () => {
    setLoading(true);
    try {
      let tempAssign = {};
      const imgDataSignOwnerPM = dataURLtoBlob(
        ownerPMSignRef.current.toDataURL("image/png")
      );
      const base64SignOwnerPM = await blobToBase64(imgDataSignOwnerPM);
      tempAssign = {
        file: base64SignOwnerPM,
        ext: ".png",
        size: imgDataSignOwnerPM.size,
        type: imgDataSignOwnerPM.type,
      };

      const bodyData = {
        fProjectID: data.fProject.fID,
        fKasbonID: data.fID,
        fStatus: "CHECKED2",
        fUserID: data.fUser.fID,
        fRole: "PM/BOSS",
        fAssign: tempAssign,
      };

      await axiosJWT.post(`${API_URL}/addkasbonassign`, bodyData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setReload(!reload);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleClickDelete = async () => {
    try {
      setLoading(true)
      await axiosJWT.post(`${API_URL}/deletekasbon`, {fID: data.fID}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setLoading(false)
      navigate(-1);
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        fontSize: "16px",
        paddingTop: "155px",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: kasbonlogo,
          title: "Detail Kasbon",
          name: user.fName,
          job: user.fRoleAlias,
        }}
      />

      <div className="DetailNotulenContainer">
        <text
          style={{
            color: "#D0A360",
            fontWeight: "bold",
            fontSize: "20px",
            textAlign: "center",
            width: "100%",
          }}
        >
          {data.fKasbonName}
        </text>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "90%",
            fontSize: "16px",
            alignItems: "flex-start",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <img
              style={{ width: "40px", height: "40px", borderRadius: 100 }}
              src={`${API_URL}/files/${data.fUser.fImages}`}
              alt=""
            />
            <text style={{ fontWeight: "bold" }}>{data.fUser.fName}</text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "15px",
              alignItems: "flex-end",
            }}
          >
            <text>
              {dayjs(data.fKasbonDate).format("DD MMM YYYY").toString()}
            </text>
            <div
              style={{
                padding: "0 25px",
                color: "#D0A360",
                borderRadius: 100,
                border: "2px solid #D0A360",
                fontWeight: "bold",
                boxSizing: "border-box",
              }}
            >
              {status}
            </div>
          </div>
        </div>

        <Carousel
          style={{ width: "90%" }}
          activeIndex={index}
          onSelect={handleSelect}
        >
          {data.fProject.fImages.map((d) => {
            return (
              <Carousel.Item>
                <img
                  style={{
                    width: "100%",
                    aspectRatio: "743/443",
                    objectFit: "cover",
                    margin: "5px 0",
                  }}
                  src={`${API_URL}/files/${d.filename}`}
                  alt=""
                />
              </Carousel.Item>
            );
          })}
        </Carousel>
        <div style={{ width: "100%", height: "5px" }} />
      </div>

      {/* Keterangan */}
      <div style={{ marginTop: "10px" }} className="DetailNotulenContainer">
        <div style={{ display: "flex", flexDirection: "column", width: "90%" }}>
          <text style={{ fontSize: "16px", fontWeight: "bold" }}>Type</text>
          <div style={{ height: "5px" }} />
          <text style={{ fontSize: "16px" }}>{data.fKasbonType}</text>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "90%",
            marginTop: "10px",
          }}
        >
          <text style={{ fontSize: "16px", fontWeight: "bold" }}>
            Keterangan
          </text>
          <div style={{ height: "5px" }} />
          <text style={{ fontSize: "16px" }}>
            {renderTextWithLineBreaks(data.fKasbonKet)}
          </text>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "90%",
            marginTop: "10px",
          }}
        >
          <text style={{ fontSize: "16px", fontWeight: "bold" }}>Jumlah</text>
          <div style={{ height: "5px" }} />
          <text style={{ fontSize: "16px" }}>{data.fKasbonAmount}</text>
        </div>

        <div style={{ width: "100%", height: "5px" }} />
      </div>

      {/* TTD SPV + Mandor View */}
      {(data.fStatus[0].fStatus === "CHECKED1" ||
        data.fStatus[0].fStatus === "APPROVED" ||
        data.fStatus[0].fStatus === "REJECTED" ||
        data.fStatus[0].fStatus === "CHECKED2") &&
        data.fChecked1 && (
          <div style={{ marginTop: "10px" }} className="DetailNotulenContainer">
            <div
              style={{ display: "flex", flexDirection: "column", width: "90%" }}
            >
              <text style={{ fontSize: "16px", fontWeight: "bold" }}>
                Tanda Tangan Persetujuan
              </text>
              <div style={{ height: "10px" }} />

              <div className="notulenSignaturesContainerWrapper">
                <div className="notulenSignaturesContainer">
                  <text style={{ fontWeight: "bold" }}>
                    {data.fChecked1[0].fRole}
                  </text>
                  <img
                    className="notulenSignatureCanvasImage"
                    src={`${API_URL}/files/${data.fChecked1[0].fAssign.filename}`}
                    alt=""
                  />
                </div>

                <div className="notulenSignaturesContainer">
                  <text style={{ fontWeight: "bold" }}>
                    {data.fChecked1[1].fRole}
                  </text>
                  <img
                    className="notulenSignatureCanvasImage"
                    src={`${API_URL}/files/${data.fChecked1[1].fAssign.filename}`}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div style={{ width: "100%", height: "5px" }} />
          </div>
        )}

      {/* TTD SPV + Mandor */}
      {user.fRole === "SPV" && data.fStatus[0].fStatus === "NEW" && (
        <div style={{ marginTop: "10px" }} className="DetailNotulenContainer">
          <div
            style={{ display: "flex", flexDirection: "column", width: "90%" }}
          >
            <text style={{ fontSize: "16px", fontWeight: "bold" }}>
              Tanda Tangan Persetujuan
            </text>
            <div style={{ height: "10px" }} />

            <div className="notulenSignaturesContainerWrapper">
              <div className="notulenSignaturesContainer">
                <text style={{ fontWeight: "bold" }}>SPV</text>
                {errors.spv && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {" "}
                    *{errors.spv}
                  </span>
                )}
                <ReactSignatureCanvas
                  penColor="black"
                  clearOnResize={false}
                  canvasProps={{ className: "notulenSignatureCanvas" }}
                  ref={supervisorSignRef}
                />
                <div
                  onClick={() => {
                    handleClearSupervisor();
                  }}
                  style={{ borderRadius: 5 }}
                  className="notulenSignatureDeleteButton"
                >
                  Delete
                </div>
              </div>

              <div className="notulenSignaturesContainer">
                <text style={{ fontWeight: "bold" }}>Mandor</text>
                {errors.mandor && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {" "}
                    *{errors.mandor}
                  </span>
                )}
                <ReactSignatureCanvas
                  penColor="black"
                  clearOnResize={false}
                  canvasProps={{ className: "notulenSignatureCanvas" }}
                  ref={mandorSignRef}
                />
                <div
                  onClick={() => {
                    handleClearMandor();
                  }}
                  style={{ borderRadius: 5 }}
                  className="notulenSignatureDeleteButton"
                >
                  Delete
                </div>
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              validationForm("firstttd");
            }}
            style={{
              width: "85%",
              backgroundColor: "#D0A360",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px 0",
              marginTop: "25px",
              borderRadius: 3,
              opacity: loading ? "0.7" : "1",
            }}
          >
            {loading && <span>Loading...</span>}
            {!loading && <span>Simpan</span>}
          </div>

          <div
            onClick={() => {
              handleClickDelete();
            }}
            style={{
              width: "85%",
              backgroundColor: "#5D5D5D",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px 0",
              marginTop: "10px",
              borderRadius: 3,
            }}
          >
            Hapus
          </div>
          <div style={{ width: "100%", height: "5px" }} />
        </div>
      )}

      {/* PM/BOSS APPROVAL */}
      {(user.fRole === "PM" || user.fRole === "BOSS") &&
        data.fStatus[0].fStatus === "CHECKED1" && (
          <div
            style={{
              width: "100%",
              maxWidth: "1024px",
              margin: "10px auto ",
              backgroundColor: "white",
              padding: "20px 5%",
              boxSizing: "border-box",
              fontSize: "16px",
            }}
          >
            <div style={{ width: "100%", fontWeight: "bold" }}>
              Jumlah Disetujui
              {errors.value && (
                <span
                  style={{
                    color: "red",
                    fontSize: "14px",
                    fontWeight: "normal",
                  }}
                >
                  {" "}
                  *{errors.value}
                </span>
              )}
            </div>
            <input
              value={valueInput}
              onChange={(v) => {
                setValueInput(v.target.value);
              }}
              className="DetailNotulenInputText"
              type="text"
              placeholder="jumlah"
            />

            <div
              style={{ width: "100%", fontWeight: "bold", marginTop: "25px" }}
            >
              Keterangan
              {errors.ket && (
                <span
                  style={{
                    color: "red",
                    fontSize: "14px",
                    fontWeight: "normal",
                  }}
                >
                  {" "}
                  *{errors.ket}
                </span>
              )}
            </div>
            <div className="DetailNotulenInputNotes">
              <textarea
                style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
                minLength={150}
                value={ketInput}
                onChange={(v) => {
                  setKetInput(v.target.value);
                }}
                className="AddNotulenInputNote"
                type="text"
                placeholder="Keterangan"
              ></textarea>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                gap: "15px",
                alignItems: "center",
                margin: "30px auto",
                marginBottom: "15px",
                color: "white",
                fontWeight: "bold",
              }}
            >
              <div
                onClick={() => {
                  validationForm("approval");
                }}
                style={{
                  width: "100%",
                  backgroundColor: "#D0A360",
                  boxSizing: "border-box",
                  padding: "10px 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 5,
                  opacity: loading ? "0.7" : "1",
                }}
              >
                {loading && <span>Loading...</span>}
                {!loading && <span>Approve</span>}
              </div>
              <div
                onClick={() => {
                  validationForm("reject");
                }}
                style={{
                  width: "100%",
                  backgroundColor: "red",
                  boxSizing: "border-box",
                  padding: "10px 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 5,
                  opacity: loading ? "0.7" : "1",
                }}
              >
                {loading && <span>Loading...</span>}
                {!loading && <span>Reject</span>}
              </div>
            </div>
          </div>
        )}

      {/* PM/BOSS APPROVAL */}
      {(data.fStatus[0].fStatus === "APPROVED" ||
        data.fStatus[0].fStatus === "REJECTED" ||
        data.fStatus[0].fStatus === "CHECKED2") &&
        data.fChecked1 && (
          <div
            style={{
              width: "100%",
              maxWidth: "1024px",
              margin: "10px auto ",
              backgroundColor: "white",
              padding: "20px 5%",
              boxSizing: "border-box",
              fontSize: "16px",
            }}
          >
            <div
              style={{
                width: "100%",
                fontWeight: "bold",
                marginBottom: "20px",
                color: status === "Rejected" ? "red" : "green",
              }}
            >
              {data.fStatus[0].fStatus === "APPROVED" && <span>APPROVED</span>}
              {data.fStatus[0].fStatus === "REJECTED" && <span>REJECTED</span>}
              {data.fStatus[0].fStatus === "CHECKED2" && <span>APPROVED</span>}
            </div>

            <div style={{ width: "100%", marginBottom: "15px" }}>
              <div style={{ width: "100%", fontWeight: "bold" }}>
                Jumlah yang disetujui
              </div>
              <text>{data.fAmountApproval}</text>
            </div>

            <div style={{ width: "100%", fontWeight: "bold" }}>Keterangan</div>
            <text>{renderTextWithLineBreaks(data.fKetApproval)}</text>
          </div>
        )}

      {/* TTD BOSS/PM */}
      {user.fID === data.fStatus[0].fCreatedByID &&
        data.fStatus[0].fStatus === "APPROVED" && (
          <div style={{ marginTop: "10px" }} className="DetailNotulenContainer">
            <div
              style={{ display: "flex", flexDirection: "column", width: "90%" }}
            >
              <text style={{ fontSize: "16px", fontWeight: "bold" }}>
                Tanda Tangan Persetujuan Final
              </text>
              <div style={{ height: "10px" }} />

              <div className="notulenSignaturesContainerWrapper">
                <div className="notulenSignaturesContainer">
                  <text style={{ fontWeight: "bold" }}>PM/Owner</text>
                  {errors.pmowner && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {" "}
                      *{errors.pmowner}
                    </span>
                  )}
                  <ReactSignatureCanvas
                    penColor="black"
                    clearOnResize={false}
                    canvasProps={{ className: "notulenSignatureCanvas" }}
                    ref={ownerPMSignRef}
                  />
                  <div
                    onClick={() => {
                      handleClearOwnerPM();
                    }}
                    style={{ borderRadius: 5 }}
                    className="notulenSignatureDeleteButton"
                  >
                    Delete
                  </div>
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                validationForm("secondttd");
              }}
              style={{
                width: "85%",
                backgroundColor: "#D0A360",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
                marginTop: "25px",
                borderRadius: 3,
                opacity: loading ? "0.7" : "1",
              }}
            >
              {loading && <span>Loading...</span>}
              {!loading && <span>Simpan</span>}
            </div>
            <div style={{ width: "100%", height: "5px" }} />
          </div>
        )}

      {/* TTD BOSS/PM + Client View */}
      {data.fStatus[0].fStatus === "CHECKED2" && (
        <div style={{ marginTop: "10px" }} className="DetailNotulenContainer">
          <div
            style={{ display: "flex", flexDirection: "column", width: "90%" }}
          >
            <text style={{ fontSize: "16px", fontWeight: "bold" }}>
              Tanda Tangan Persetujuan Final
            </text>
            <div style={{ height: "10px" }} />

            <div className="notulenSignaturesContainerWrapper">
              <div className="notulenSignaturesContainer">
                <text style={{ fontWeight: "bold" }}>
                  {data.fChecked2[0].fRole}
                </text>
                <img
                  className="notulenSignatureCanvasImage"
                  src={`${API_URL}/files/${data.fChecked2[0].fAssign.filename}`}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div style={{ width: "100%", height: "5px" }} />
        </div>
      )}

      <div style={{ height: "30px" }} />
    </div>
  );
};

export default DetailKasbon;
