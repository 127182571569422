import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";

import file from "../../assets/file.png";
import bin from "../../assets/bin.png";
import Dropdown from "../../Components/Dropdown/Dropdown";
import DownloadCard from "../../Components/DownloadCard/DownloadCard";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import tambahprogresslogo from "../../assets/tambahprogress.png";

import "./EditProgressProject.css";
import "@fontsource/poppins";

import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";
require("dayjs/locale/de");

const djs = dayjs().locale("de").format();

const EditProgressProject = () => {
  const inputImagesRef = useRef(null);

  const { state } = useLocation();
  const { data } = state;
  const [date, setDate] = useState(dayjs(data.fProgressDate));
  const [percentage, setPercentage] = useState(data.fProgressPercentage);
  const [images, setImages] = useState(data.fProgressImages);
  const [ket, setKet] = useState(data.fKet);

  // Pasang di semua screen yang ga butuh data dari yang sebelumnya
  const API_URL = process.env.REACT_APP_API_URL;
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });

  const navigate = useNavigate();

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      if (
        response.data.user[0].fRole !== "BOSS" &&
        response.data.user[0].fRole !== "PM"
      ) {
        navigate("/");
      }
      setUser(response.data.user[0]);
      setToken(response.data.accessToken);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };
  // sampai sini

  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const handleImageClick = () => {
    inputImagesRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImages([file, ...images]);
    }
  };

  const removeImage = (indexToRemove) => {
    setImages((prevFiles) =>
      prevFiles.filter((images, index) => index !== indexToRemove)
    );
  };

  const handleImageDelete = (i) => {
    removeImage(i);
  };

  const validationForm = (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (!percentage) {
      validationErrors.progress = "harus diisi";
    }

    if (images.length === 0) {
      validationErrors.file = "minimal 1 gambar";
    }

    if (!ket.trim()) {
      validationErrors.ket = "harus diisi";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0 && !loading) {
      handleSaveButton();
    }
  };

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  const handleSaveButton = async () => {
    setLoading(true);
    let tempImages = [];
    for (let i = 0; i < images.length; i++) {
      if (typeof images[i].filename !== "string") {
        const base64File = await blobToBase64(images[i]);
        tempImages.push({
          file: base64File,
          ext: getFileExtension(images[i].name),
          size: images[i].size,
          type: images[i].type
        });
      }else{
        tempImages.push(images[i]);
      }
    }

    const bodyData = {
      fID: data.fID,
      fProjectID: data.fProjectID,
      fProgressDate: date,
      fProgressPercentage: percentage,
      fKet: ket,
      fProgressImages: tempImages
    }

    try {
      await axiosJWT.post(
        `${API_URL}/updateprojectprogress`,
        bodyData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteButton = async () => {
    setLoading(true);
    const bodyData = {
      fID: data.fID,
      fProjectID: data.fProjectID
    }
    try {
      await axiosJWT.post(
        `${API_URL}/deleteprojectprogress`,
        bodyData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "130px",
        minHeight: "100vh",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: tambahprogresslogo,
          title: "Edit Progress",
          name: "Vincent",
          job: "SPV",
          type: "Add",
        }}
      />

      <div className="AddNotulenContainer">
        <div className="AddNotulenInput">
          <text>Tanggal</text>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ width: "50%" }}>
              <DatePicker
                format="DD/MM/YYYY"
                value={date}
                defaultValue={dayjs(data.fProgressDate)}
                onChange={(v) => {
                  setDate(v);
                }}
              />
            </div>
          </LocalizationProvider>
        </div>

        <div className="AddNotulenInput">
          <text>
            {`Progress (%)`}
            {errors.progress && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.progress}
              </span>
            )}
          </text>
          <input
            className="AddNotulenInputText"
            type="number"
            value={percentage}
            onChange={(e) => {
              setPercentage(e.target.value);
            }}
            placeholder="100"
          />
        </div>

        <div className="AddNotulenInput">
          <text>
            {"Gambar"}
            {images.length > 10 ? (
              <span style={{ color: "red", fontWeight: "bold" }}>
                {" "}
                (Maks 10)
              </span>
            ) : (
              " (Maks 10)"
            )}
            {errors.file && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.file}
              </span>
            )}
          </text>
          <div>
            <div
              onClick={handleImageClick}
              className="AddNotulenFile"
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "20px 10px",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <img
                style={{ width: "27px", height: "27px" }}
                src={file}
                alt=""
              />
              <text style={{ textAlign: "center" }}>
                <span style={{ color: "#818CF8" }}>click to browse</span>
              </text>
            </div>
            <input
              type="file"
              ref={inputImagesRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
        </div>

        {/* download card */}
        <div className="AddProjectCenterer">
          <div className="AddProjectDownloadCardContainer">
            {images.map((d, i) => {
              return (
                <div>
                  <div className="AddProjectDownloadCardWrapper">
                    <img
                      className="AddProjectDownloadImage"
                      src={
                        typeof d.filename !== "string"
                          ? URL.createObjectURL(d)
                          : `${API_URL}/files/${d.filename}`
                      }
                      alt=""
                    />
                    <div
                      style={{ backgroundColor: "#378D23", marginTop: "10px" }}
                      className="AddProjectDownloadButton"
                      onClick={() => {
                        typeof d.filename !== "string"
                          ? handleDownload(URL.createObjectURL(d), d.name)
                          : handleDownload(
                              `${API_URL}/files/${d.filename}`,
                              d.filename
                            );
                      }}
                    >
                      Download
                    </div>
                    <div
                      onClick={() => {
                        handleImageDelete(i);
                      }}
                      style={{
                        backgroundColor: "#BC1C12",
                        marginBottom: "20px",
                      }}
                      className="AddProjectDownloadButton"
                    >
                      Delete
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="AddNotulenInput">
          <text>
            Keterangan
            {errors.ket && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.ket}
              </span>
            )}
          </text>
          <div className="AddNotulenInputNotes">
            <textarea
              style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
              minLength={150}
              onChange={(v) => {
                setKet(v.target.value);
              }}
              className="AddNotulenInputNote"
              type="text"
              placeholder="Keterangan"
            >
              {ket}
            </textarea>
          </div>
        </div>

        <div style={{ display: "flex", width: "95%" }}>
          <div
            onClick={validationForm}
            style={{
              marginBottom: "15px",
              fontWeight: "bold",
              opacity: loading ? "0.7" : "1",
            }}
            className="AddProgressButton"
          >
            {loading && <span>Loading...</span>}
            {!loading && <span>Simpan</span>}
          </div>
        </div>

        <div style={{ display: "flex", width: "95%" }}>
          <div
            onClick={() => {
              if (!loading) {
                handleDeleteButton();
              }
            }}
            style={{
              backgroundColor: "red",
              fontWeight: "bold",
              opacity: loading ? "0.7" : "1",
            }}
            className="AddProgressButton"
          >
            {loading && <span>Loading...</span>}
            {!loading && <span>Hapus</span>}
          </div>
        </div>
      </div>
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default EditProgressProject;
