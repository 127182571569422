import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";

import file from "../../assets/file.png";
import bin from "../../assets/bin.png";
import Dropdown from "../../Components/Dropdown/Dropdown";
import DownloadCard from "../../Components/DownloadCard/DownloadCard";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers/MultiSectionDigitalClock";

import tasklogo from "../../assets/task.png";

import "@fontsource/poppins";

import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";
require("dayjs/locale/de");

const djs = dayjs().locale("de").format();

const AddAbsensiRequest = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [typeIzin, setTypeIzin] = useState([
    { name: "Izin" },
    { name: "Sakit" },
    { name: "Cuti" },
  ]);
  const [selectedTypeIzin, setSelectedTypeIzin] = useState(typeIzin[0].name);
  const [selectedTypeIzinData, setSelectedTypeIzinData] = useState(typeIzin[0]);

  const [dateTimeStart, setDateTimeStart] = useState(dayjs(new Date()));
  const [dateTimeFinish, setDateTimeFinish] = useState(dayjs(new Date()));
  const [ket, setKet] = useState("");
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false)

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      if (
        response.data.user[0].fRole === "BOSS" ||
        response.data.user[0].fRole === "CLIENT"
      ) {
        navigate("/");
      }
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const validationForm = (e) => {
    e.preventDefault();
    let temp = '';
    if(!ket.trim()){
      temp = 'harus diisi'
    }

    setError(temp);

    if(temp.trim().length === 0 && !loading){
      handleSaveButton()
    }
  }

  const handleSaveButton = async () => {
    setLoading(true)
    var bodyFormData = new FormData();
    bodyFormData.append("fRequestType", selectedTypeIzin);
    bodyFormData.append("fStartDateTime", dateTimeStart);
    bodyFormData.append("fFinishDateTime", dateTimeFinish);
    bodyFormData.append("fKet", ket);

    try {
      const response = await axiosJWT.post(
        `${API_URL}/addabsensirequest`,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  const DateComp = () => {
    return (
      <div
        className="AddNotulenContainer"
        style={{ width: "100%", paddingTop: "0", marginTop: "0" }}
      >
        {(selectedTypeIzin === "Sakit" || selectedTypeIzin === "Izin") && (
          <div className="AddNotulenInput">
            <text style={{ fontWeight: "bold" }}>Tanggal</text>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div style={{ width: "50%" }}>
                <DatePicker
                  format="DD/MM/YYYY"
                  disablePast={true}
                  value={dateTimeStart}
                  onChange={(v) => {
                    setDateTimeStart(v);
                    setDateTimeFinish(v);
                  }}
                />
              </div>
            </LocalizationProvider>
          </div>
        )}

        {selectedTypeIzin === "Cuti" && (
          <div className="AddNotulenInput">
            <text style={{ fontWeight: "bold" }}>Tanggal Mulai</text>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div style={{ width: "50%" }}>
                <DatePicker
                  format="DD/MM/YYYY"
                  disablePast={true}
                  value={dateTimeStart}
                  onChange={(v) => {
                    setDateTimeStart(v);
                  }}
                />
              </div>
            </LocalizationProvider>
          </div>
        )}

        {selectedTypeIzin === "Izin" && (
          <div className="AddNotulenInput">
            <text style={{ fontWeight: "bold" }}>Waktu Mulai</text>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div style={{ width: "50%" }}>
                <MultiSectionDigitalClock
                  skipDisabled
                  value={dateTimeStart}
                  onChange={(v) => {
                    setDateTimeStart(v);
                  }}
                  shouldDisableTime={false}
                  ampm={false}
                />
              </div>
            </LocalizationProvider>
          </div>
        )}

        {selectedTypeIzin === "Cuti" && (
          <div className="AddNotulenInput">
            <text style={{ fontWeight: "bold" }}>Tanggal Selesai</text>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div style={{ width: "50%" }}>
                <DatePicker
                  format="DD/MM/YYYY"
                  disablePast={true}
                  minDate={dateTimeStart}
                  value={dateTimeFinish}
                  onChange={(v) => {
                    setDateTimeFinish(v);
                  }}
                />
              </div>
            </LocalizationProvider>
          </div>
        )}

        {selectedTypeIzin === "Izin" && (
          <div className="AddNotulenInput">
            <text style={{ fontWeight: "bold" }}>Waktu Selesai</text>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div style={{ width: "50%" }}>
                <MultiSectionDigitalClock
                  skipDisabled
                  value={dateTimeFinish}
                  onChange={(v) => {
                    setDateTimeFinish(v);
                  }}
                  shouldDisableTime={false}
                  ampm={false}
                />
              </div>
            </LocalizationProvider>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "135px",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: tasklogo,
          title: "Add Cuti/Izin",
          name: user.fName,
          job: user.fRole,
        }}
      />

      <div className="AddNotulenContainer">
        <div className="AddNotulenInput">
          <text style={{ fontWeight: "bold" }}>Jenis Absen</text>
          <Dropdown
            selected={selectedTypeIzin}
            setSelected={setSelectedTypeIzin}
            setSelectedData={setSelectedTypeIzinData}
            options={typeIzin}
            type={"name"}
          />
        </div>

        <DateComp />

        <div className="AddNotulenInput">
          <text style={{ fontWeight: "bold" }}>Keterangan {error && <span style={{ color: "red", fontSize: '14px', fontWeight: 'normal' }}>*Harus diisi</span>}</text>
          <div className="AddNotulenInputNotes">
            <textarea
              style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
              minLength={150}
              onChange={(v) => {
                setKet(v.target.value);
              }}
              value={ket}
              className="AddNotulenInputNote"
              type="text"
              placeholder="Keterangan"
            ></textarea>
          </div>
        </div>

        <div style={{ display: "flex", width: "95%" }}>
          <div
            onClick={validationForm}
            className="AddProgressButton"
            style={{ opacity: loading ? "0.7" : "1" }}
          >
            {loading && <span>Loading...</span>}
            {!loading && <span>Simpan</span>}
          </div>
        </div>
      </div>
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default AddAbsensiRequest;
