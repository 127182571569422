import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";
import ProgressSPV from "../../Components/ProgressSPV/ProgressSPV";
import progress from "../../assets/progressspv.png";
import profile from "../../assets/profile.png";
import calonclientlogo from "../../assets/calonclient.png";

import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";

import check from "../../assets/check.png";

import "@fontsource/poppins";

import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";

const DetailClient = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const { state } = useLocation();
  const { fID } = state;
  const [index, setIndex] = useState(0);
  const [data, setData] = useState({
    fID: "",
    fName: "",
    fProjectName: "",
    fPhoneNumber: "",
    fAddress: "",
    fKet: "",
    fImages: "",
    fKet: "",
    fActive: "",
    fMarketing: {
      fImages: "",
      fName: "",
    },
  });

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    getClient();
  }, [user]);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getClient = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/detailclient`,
        { fID: fID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data);
    } catch (error) {}
  };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleClickUpdate = () => {
    navigate("/updateclient", { state: { data: data } });
  };

  const handleClickAddProgress = () => {
    navigate("/addprogressclient", { state: { data: data } });
  };

  const handleClickUpdateProgress = (d) => {
    navigate('/updateprogressclient', { state: { data: d } })
  }

  const handleClickDelete = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/deleteclient`,
        { fID: fID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1);
    } catch (error) {}
  };

  const renderTextWithLineBreaks = (text) => {
    return (text || "").split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        font: "16px",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: calonclientlogo,
          title: "Detail Owner",
          name: user.fName,
          job: user.fRoleAlias,
          type: "detail",
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0 5%",
          paddingTop: "25px",
        }}
        className="DetailProgressSPVContainer"
      >
        <div
          onClick={handleClickAddProgress}
          style={{
            width: "100%",
            padding: "10px 0",
            backgroundColor: "#22B449",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "white",
            fontSize: "16px",
            borderRadius: 3,
          }}
        >
          Add Progress
        </div>

        <div
          onClick={handleClickUpdate}
          style={{
            width: "100%",
            padding: "10px 0",
            backgroundColor: "#D0A360",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "white",
            fontSize: "16px",
            borderRadius: 3,
          }}
        >
          Edit
        </div>

        <div
          onClick={handleClickDelete}
          style={{
            width: "100%",
            padding: "10px 0",
            backgroundColor: "red",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "white",
            fontSize: "16px",
            borderRadius: 3,
          }}
        >
          Hapus
        </div>

        <img
          style={{
            width: "200px",
            aspectRatio: "1/1",
            objectFit: "cover",
            borderRadius: 1000,
            margin: "45px 0",
          }}
          src={data.fImages ? `${API_URL}/files/${data.fImages}` : profile}
          alt=""
        />
        <text
          style={{
            fontSize: "20px",
            textAlign: "center",
            fontWeight: "bold",
            color: "#D0A360",
          }}
        >
          {data.fName}
        </text>
        <text
          style={{ fontSize: "16px", textAlign: "center", color: "#8C8C8C" }}
        >
          {data.fProjectName}
        </text>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: "25px",
            marginBottom: "25px",
          }}
        >
          <text style={{ fontWeight: "bold" }}>Nomor HP</text>
          <text>{data.fPhoneNumber}</text>

          <text style={{ fontWeight: "bold", marginTop: "20px" }}>Alamat</text>
          <text>{renderTextWithLineBreaks(data.fAddress)}</text>

          <text style={{ fontWeight: "bold", marginTop: "20px" }}>
            Keterangan
          </text>
          <text>{renderTextWithLineBreaks(data.fKet)}</text>
        </div>

        <div style={{ width: "100%", height: "30px" }} />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "25px",
          marginTop: "10px",
          backgroundColor: "#F0DBBB",
          boxSizing: "border-box",
        }}
        className="DetailProgressSPVContainer"
      >
        <text style={{ fontWeight: "bold", fontSize: "20px" }}>Progress</text>

        {data.fProgress &&
          data.fProgress.map((d) => {
            return (
              <div
              onClick={() => handleClickUpdateProgress(d)}
                style={{
                  backgroundColor: "white",
                  width: "95%",
                  marginTop: "15px",
                  paddingTop: "15px",
                  borderRadius: 7,
                  display: 'flex',
                  flexDirection: 'column',
                  boxSizing: "border-box",
                }}
              >
                <text style={{ paddingLeft: "15px" }}>
                  {dayjs(d.fProgressDate)
                    .format("DD MMM YYYY, HH:mm")
                    .toString()}
                </text>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "15px",
                    paddingLeft: "15px",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                >
                  {d.fProgressImages.map((image) => {
                    return (
                      <img
                        style={{
                          width: "45%",
                          aspectRatio: "1/1",
                          maxWidth: "210px",
                        }}
                        src={`${API_URL}/files/${image.filename}`}
                        alt=""
                      />
                    );
                  })}
                </div>
                <text style={{ paddingLeft: '15px', fontWeight: 'bold', fontSize: '16px', marginBottom: '10px' }}>Keterangan</text>
                <text style={{ paddingLeft: '15px', fontSize: '16px' }}>{renderTextWithLineBreaks(d.fKet)}</text>

                <div style={{ height: '20px' }} />
              </div>
            );
          })}

        <div style={{ width: "100%", height: "30px" }} />
      </div>

      <div style={{ height: "30px" }} />
    </div>
  );
};

export default DetailClient;
