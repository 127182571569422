import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";

import file from "../../assets/file.png";
import bin from "../../assets/bin.png";
import Dropdown from "../../Components/Dropdown/Dropdown";
import DownloadCard from "../../Components/DownloadCard/DownloadCard";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import notulen from "../../assets/notulen.png";

import "./AddNotulen.css";
import "@fontsource/poppins";

import ReactSignatureCanvas from "react-signature-canvas";
import html2canvas from "html2canvas";

import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";
require("dayjs/locale/de");

const djs = dayjs().locale("de").format();

const AddNotulen = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const dataRefs = useRef(null);

  const fileRefs = useRef({});

  const works = [
    { name: "Perubahan" },
    { name: "Tambah Kurang" },
    { name: "Persetujuan Material" },
  ];

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [dateTime, setDateTime] = useState(dayjs(new Date()));
  const [ket, setKet] = useState("");
  const [files, setFiles] = useState([]);

  const [project, setProject] = useState([]);
  const [selectedProject, setSelectedProject] = useState("Pilih Project");
  const [selectedProjectData, setSelectedProjectData] = useState();

  const [selectedWorkType, setSelectedWorkType] = useState(works[0].name);
  const [selectedWorkData, setSelectedWorkData] = useState(works[0]);
  const [cost, setCost] = useState();

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState([]);

  const handleDownloadCanva = async (i) => {
    const canvas = await html2canvas(files[i].ref.current);
    const imgData = canvas.toDataURL("image/png");

    handleDownload(imgData, files[i].file.name);
  };

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    getAllProject();
  }, [user]);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      if (
        response.data.user[0].fRole !== "SPV" &&
        response.data.user[0].fRole !== "PM" &&
        response.data.user[0].fRole !== "BOSS"
      ) {
        navigate("/");
      }
      setUser(response.data.user[0]);
      if (response.data.project === "undefined") {
        setSelectedProject("Pilih Project");
      } else {
        setSelectedProject(response.data.project.fProjectName);
        setSelectedProjectData(response.data.project);
      }
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllProject = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getprojectdropdown`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProject(response.data);
    } catch (error) {}
  };

  const handleFileClick = () => {
    inputRef.current.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const temp = {
        file: file,
        ref: dataRefs,
        ket: "",
        active: true,
        type: file.type,
      };

      setFiles([...files, temp]);
    }
  };

  const validationForm = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (selectedProject === "Pilih Project") {
      validationErrors.project = "harus dipilih";
    }
    if (files.length === 0) {
      validationErrors.file = "minimal 1 file";
    }
    if (!ket.trim()) {
      validationErrors.ket = "harus diisi";
    }

    if (user.fRole === "PM" || user.fRole === "BOSS") {
      if (!cost) {
        validationErrors.cost = "harus diisi";
      }
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0 && !loading) {
      handleSaveButton();
    }
  };

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  const handleSaveButton = async () => {
    setLoading(true);
    try {
      const tempData = {
        fProjectID: selectedProjectData.fID,
        fProjectName: selectedProjectData.fProjectName,
        fNotulenDate: dateTime,
        fFile: files.filter((file) => file.active),
        fKet: ket,
        fCategory: selectedWorkType,
        fCost: cost === undefined ? 0 : cost,
      };

      let tempFiles = [];
      let cleanFiles = [];
      for (let i = 0; i < tempData.fFile.length; i++) {
        if (tempData.fFile[i].file.type[0] === "i") {
          const canvas = await html2canvas(tempData.fFile[i].ref.current);
          cleanFiles.push({ data: canvas, type: "image" });
        } else {
          cleanFiles.push({ data: tempData.fFile[i].file, type: "file" });
        }
      }

      for (let i = 0; i < cleanFiles.length; i++) {
        if (cleanFiles[i].type === "image") {
          const imgData = dataURLtoBlob(
            cleanFiles[i].data.toDataURL("image/png")
          );
          const base64File = await blobToBase64(imgData);
          tempFiles.push({
            file: base64File,
            ext: ".png",
            size: imgData.size,
            type: imgData.type,
            ket: tempData.fFile[i].ket,
          });
        } else {
          const base64File = await blobToBase64(cleanFiles[i].data);
          tempFiles.push({
            file: base64File,
            ext: getFileExtension(cleanFiles[i].data.name),
            size: cleanFiles[i].data.size,
            type: cleanFiles[i].data.type,
            ket: tempData.fFile[i].ket,
          });
        }
      }

      const bodyData = {
        fProjectID: selectedProjectData.fID,
        fProjectName: selectedProjectData.fProjectName,
        fNotulenDate: dateTime,
        fFile: tempFiles,
        fKet: ket,
        fCategory: selectedWorkType,
        fCost: cost === undefined ? 0 : cost,
      };

      const response = await axiosJWT.post(`${API_URL}/addnotulen`, bodyData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      navigate("/");
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  }

  const removeFile = (indexToRemove) => {
    let temp = [...files];
    temp[indexToRemove].active = false;

    setFiles(temp);
  };

  const handleFileDelete = (i) => {
    removeFile(i);
  };

  const handleClearCanva = (i) => {
    if (fileRefs.current[i]) {
      fileRefs.current[i].clear();
    }
  };

  const handleClickButtonChange = () => {
    fileRefs[1].current = fileRefs[0].current;
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "130px",
        minHeight: "100vh",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: notulen,
          title: "Tambah Notulen",
          name: user.fName,
          job: user.fRoleAlias,
          type: "Add",
          update: false,
          delete: false,
        }}
      />

      <div className="AddNotulenContainer">
        <div className="AddNotulenInput">
          <text>
            Project
            {errors.project && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.project}
              </span>
            )}
          </text>
          <Dropdown
            selected={
              selectedProject === "" ? "Pilih Project" : selectedProject
            }
            setSelected={setSelectedProject}
            setSelectedData={setSelectedProjectData}
            options={project}
            type={"fProjectName"}
          />
        </div>

        <div className="AddNotulenInput">
          <text>Tanggal</text>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ width: "50%" }}>
              <DatePicker
                format="DD/MM/YYYY"
                disableFuture={true}
                value={dateTime}
                defaultValue={dateTime}
                onChange={(v) => {
                  setDateTime(v);
                }}
              />
            </div>
          </LocalizationProvider>
        </div>

        <div className="AddProgressInput">
          <text>
            {"File (Maks 5)"}
            {errors.file && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.file}
              </span>
            )}
          </text>
          {files.filter((file) => file.active).length < 5 && (
            <div>
              <div
                onClick={handleFileClick}
                className="DetailProgressImage"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px 10px",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <img
                  style={{ width: "27px", height: "27px" }}
                  src={file}
                  alt=""
                />
                <text style={{ textAlign: "center" }}>
                  <span style={{ color: "#818CF8" }}>click to browse</span>
                </text>
              </div>
              <input
                type="file"
                ref={inputRef}
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </div>
          )}

          <div
            style={{
              width: "95%",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {files.map((d, i) => {
              return (
                <div>
                  {d.file.type[0] === "i" && (
                    <div
                      style={{
                        height: d.active ? "auto" : "0",
                        marginBottom: d.active ? "10px" : 0,
                        boxShadow: d.active ? "10px" : "none",
                        overflow: "hidden",
                      }}
                      className="AddNotulenDownloadCardWrapper"
                    >
                      <div
                        ref={d.ref}
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <img
                          className="notulenSignatureImage"
                          src={URL.createObjectURL(d.file)}
                          alt=""
                        />
                        <text>{d.active}</text>
                        <ReactSignatureCanvas
                          clearOnResize={false}
                          dotSize={() => 1}
                          penColor="red"
                          canvasProps={{
                            className: "AddNotulenDownloadImage",
                          }}
                          ref={(ref) => {
                            fileRefs.current[i] = ref;
                          }}
                        />
                      </div>

                      <div className="AddNotulenInputNotes">
                        <textarea
                          style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
                          minLength={150}
                          value={d.ket}
                          onChange={(v) => {
                            let temp = [...files];
                            temp[i].ket = v.target.value;
                            setFiles(temp);
                          }}
                          className="AddNotulenInputNote"
                          type="text"
                          placeholder="Keterangan"
                        ></textarea>
                      </div>
                      <div
                        style={{
                          backgroundColor: "#378D23",
                          marginTop: "10px",
                          width: "95%",
                        }}
                        className="notulenSignatureDeleteButton"
                        onClick={() => {
                          handleDownloadCanva(i);
                        }}
                      >
                        Download
                      </div>

                      <div
                        onClick={() => {
                          handleClearCanva(i);
                        }}
                        style={{ borderRadius: 5, width: "95%" }}
                        className="notulenSignatureDeleteButton"
                      >
                        Clear
                      </div>

                      <div
                        onClick={() => {
                          handleFileDelete(i);
                        }}
                        style={{
                          backgroundColor: "#BC1C12",
                          marginBottom: "20px",
                          width: "95%",
                        }}
                        className="notulenSignatureDeleteButton"
                      >
                        Delete
                      </div>
                    </div>
                  )}
                  {d.file.type[0] === "v" && (
                    <div>
                      <div
                        style={{
                          height: d.active ? "auto" : "0",
                          marginBottom: d.active ? "10px" : 0,
                          boxShadow: d.active ? "10px" : "none",
                          overflow: "hidden",
                        }}
                        className="AddNotulenDownloadCardWrapper"
                      >
                        <video className="AddNotulenDownloadVideo" controls>
                          <source src={URL.createObjectURL(d.file)} />
                        </video>
                        <div className="AddNotulenInputNotes">
                          <textarea
                            style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
                            minLength={150}
                            value={d.ket}
                            onChange={(v) => {
                              let temp = [...files];
                              temp[i].ket = v.target.value;
                              setFiles(temp);
                            }}
                            className="AddNotulenInputNote"
                            type="text"
                            placeholder="Keterangan"
                          ></textarea>
                        </div>

                        <div
                          style={{
                            backgroundColor: "#378D23",
                            marginTop: "10px",
                          }}
                          className="AddProjectDownloadButton"
                          onClick={() => {
                            handleDownload(
                              URL.createObjectURL(d.file),
                              d.file.name
                            );
                          }}
                        >
                          Download
                        </div>
                        <div
                          onClick={() => {
                            handleFileDelete(i);
                          }}
                          style={{
                            backgroundColor: "#BC1C12",
                            marginBottom: "20px",
                          }}
                          className="AddProjectDownloadButton"
                        >
                          Delete
                        </div>
                      </div>
                    </div>
                  )}
                  {d.file.type[0] !== "i" && d.file.type[0] !== "v" && (
                    <div>
                      <div
                        style={{
                          height: d.active ? "auto" : "0",
                          marginBottom: d.active ? "10px" : 0,
                          boxShadow: d.active ? "10px" : "none",
                          overflow: "hidden",
                        }}
                        className="AddNotulenDownloadCardWrapper"
                      >
                        <div className="AddNotulenDownloadVideo">
                          <text style={{ fontWeight: "bold" }}>Filename</text>
                          <text>{d.file.name}</text>
                          <text
                            style={{ fontWeight: "bold", marginTop: "10px" }}
                          >
                            Size
                          </text>
                          <text>{humanFileSize(d.file.size)}</text>
                        </div>
                        <div className="AddNotulenInputNotes">
                          <textarea
                            style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
                            minLength={150}
                            value={d.ket}
                            onChange={(v) => {
                              let temp = [...files];
                              temp[i].ket = v.target.value;
                              setFiles(temp);
                            }}
                            className="AddNotulenInputNote"
                            type="text"
                            placeholder="Keterangan"
                          ></textarea>
                        </div>

                        <div
                          style={{
                            backgroundColor: "#378D23",
                            marginTop: "10px",
                          }}
                          className="AddProjectDownloadButton"
                          onClick={() => {
                            handleDownload(
                              URL.createObjectURL(d.file),
                              d.file.name
                            );
                          }}
                        >
                          Download
                        </div>
                        <div
                          onClick={() => {
                            handleFileDelete(i);
                          }}
                          style={{
                            backgroundColor: "#BC1C12",
                            marginBottom: "20px",
                          }}
                          className="AddProjectDownloadButton"
                        >
                          Delete
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        {user.fRole === "BOSS" || user.fRole === "PM" ? (
          <div style={{ marginTop: "10px" }} className="AddNotulenInput">
            <text>Jenis Barang/pekerjaan</text>
            <Dropdown
              selected={selectedWorkType}
              setSelected={setSelectedWorkType}
              setSelectedData={setSelectedWorkData}
              options={works}
              type={"name"}
            />
          </div>
        ) : (
          <div
            style={{ gap: "5px", marginTop: "10px" }}
            className="AddNotulenInput"
          >
            <text>Jenis Barang/pekerjaan</text>
            <div />
            <text>Perubahan</text>
          </div>
        )}

        {(user.fRole === "PM" || user.fRole === "BOSS") && (
          <div className="AddNotulenInput">
            <text>
              Cost
              {errors.cost && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {" "}
                  *{errors.cost}
                </span>
              )}
            </text>
            <input
              value={cost}
              onChange={(v) => {
                setCost(v.target.value);
              }}
              className="AddNotulenInputText"
              type="number"
              placeholder="10000"
            />
          </div>
        )}

        <div className="AddNotulenInput">
          <text>
            Keterangan/Qty
            {errors.ket && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.ket}
              </span>
            )}
          </text>
          <div className="AddNotulenInputNotes">
            <textarea
              style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
              minLength={150}
              value={ket}
              onChange={(v) => {
                setKet(v.target.value);
              }}
              className="AddNotulenInputNote"
              type="text"
              placeholder="Keterangan"
            ></textarea>
          </div>
        </div>

        <div style={{ display: "flex", width: "95%" }}>
          <div
            onClick={validationForm}
            className="AddProgressButton"
            style={{ opacity: loading ? "0.7" : "1" }}
          >
            {loading && <span>Loading...</span>}
            {!loading && <span>Simpan</span>}
          </div>
        </div>
      </div>
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default AddNotulen;
