import "./TaskCard.css";
import profile from '../../assets/profile.png'

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const TaskCard = ({ data }) => {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const handleClickTask = (v) => {
    navigate('/detailtask', { state: {fID: data.fID}});
  }

  return (
    <div onClick={() => handleClickTask(data)} className="Task_Container">
      <text style={{ fontSize: "14px" }}>{dayjs(data.fTaskDeadline).format('DD MMM YYYY').toString()}</text>

      <div style={{ height: '54px', marginTop: '10px' }}>
        <text style={{ fontSize: '18px', color: '#D0A360', fontWeight: 'bold' }}>{data.fTaskName}</text>
      </div>   
      <div style={{ height: '10px' }} />
      <text style={{ fontWeight: 'bold', fontSize: '14px' }}>Notes:</text>
      <></>

      <div className="TaskNotesWrapper">
      <text className="TaskNotes" style={{ fontSize: '14px' }}>{data.fKet}</text>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginTop: '15px' }}>
        <img src={`${API_URL}/files/${data.fProfile.fImages}`} alt="" style={{ width: '40px', height: '40px', marginRight: '10px', borderRadius: 100 }} />
        <text style={{ fontSize: '14px', fontWeight: 600 }}>{data.fProfile.fName}</text>
      </div>

      <div style={{ borderRadius: 100, borderColor: '#D0A360', color: '#D0A360', borderStyle: 'solid', borderWidth: '3px', width: '85%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '15px', marginLeft: 'auto', marginRight: 'auto', padding: '4px 0' }} >
        <text style={{ fontSize: '14px', fontWeight: 'bold'}}>{data.fStatus}</text>
      </div>
    </div>
  );
};

export default TaskCard;
