import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";

import file from "../../assets/file.png";
import bin from "../../assets/bin.png";
import Dropdown from "../../Components/Dropdown/Dropdown";
import DownloadCard from "../../Components/DownloadCard/DownloadCard";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import tasklogo from "../../assets/task.png";

import "@fontsource/poppins";

import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";

import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";

const djs = dayjs().locale("de").format();

const DetailAbsensi = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const { state } = useLocation();
  const { fID } = state;

  const [data, setData] = useState({
    fUser: {
      fName: "",
    },
    fProjectCheckIn: {
      fProjectName: "",
    },
    fProjectCheckOut: {
      fProjectName: "",
    },
  });

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    getDetailAbsensi();
  }, [user]);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getDetailAbsensi = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getdetailabsensi`,
        { fID: fID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data);
    } catch (error) {}
  };

  const renderTextWithLineBreaks = (text) => {
    return (text || "").split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "135px",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: tasklogo,
          title: "Detail Absensi",
          name: user.fName,
          job: user.fRoleAlias,
        }}
      />

      <div className="AddNotulenContainer">
        <div style={{ marginBottom: "20px" }} className="AddNotulenInput">
          <div>
            <img
              style={{
                width: "70px",
                height: "70px",
                borderRadius: 100,
                marginRight: "15px",
              }}
              src={`${API_URL}/files/${data.fUser.fImages}`}
              alt=""
            />
            <text style={{ fontWeight: "bold" }}>{data.fUser.fName}</text>
          </div>
        </div>

        <div className="AddNotulenInput">
          <text style={{ fontWeight: "bold" }}>Check-In</text>
          <img
            src={`${API_URL}/files/${data.fCheckInImage}`}
            style={{ width: "90%" }}
          />
        </div>

        <div className="AddNotulenInput">
          <text style={{ fontWeight: "bold" }}>Waktu Check-In</text>
          <text>
            {dayjs(data.fCheckInDateTime).format("DD MMM YYYY, HH:mm").toString()}
          </text>
        </div>

        {(user.fRole === "SPV" || user.fRole === "SUPIR") && (
          <div className="AddNotulenInput">
            <text style={{ fontWeight: "bold" }}>Check-In Project</text>
            <text>{data.fProjectCheckIn.fProjectName}</text>
          </div>
        )}

        <div className="AddNotulenInput">
          <text style={{ fontWeight: "bold" }}>Check-In Keterangan</text>
          <text>{renderTextWithLineBreaks(data.fCheckInKet)}</text>
        </div>

        {data.fCheckOut === "Y" && (
          <div className="AddNotulenInput">
            <text style={{ fontWeight: "bold" }}>Check-Out</text>
            <img
              src={`${API_URL}/files/${data.fCheckOutImage}`}
              style={{ width: "90%" }}
            />
          </div>
        )}

        <div className="AddNotulenInput">
          <text style={{ fontWeight: "bold" }}>Waktu Check-Out</text>
          {data.fCheckOut === "Y" ? (
            <text>
              {dayjs(data.fCheckOutDateTime).format("DD MMM YYYY, HH:mm").toString()}
            </text>
          ) : (
            <text>-</text>
          )}{" "}
        </div>

        {(user.fRole === "SPV" || user.fRole === "SUPIR") && (
          <div className="AddNotulenInput">
            <text style={{ fontWeight: "bold" }}>Check-Out Project</text>
            {data.fCheckOut === "Y" ? (
              <text>{data.fProjectCheckOut.fProjectName}</text>
            ) : (
              <text>-</text>
            )}
          </div>
        )}

        <div className="AddNotulenInput">
          <text style={{ fontWeight: "bold" }}>Check-Out Keterangan</text>
          {data.fCheckOut === "Y" ? (
            <text>{renderTextWithLineBreaks(data.fCheckOutKet)}</text>
          ) : (
            <text>-</text>
          )}
        </div>

        <div style={{ height: "30px", width: "1px" }} />
      </div>
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default DetailAbsensi;
