import profile from '../../assets/profile.png'

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const PengajuanItemsCard = ({ data }) => {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const handleClickItem = (v) => {
    navigate('/updateitem', { state: {data: v}});
  }

  return (
    <div style={{ width: '200px', overflow: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '285px' }} onClick={() => handleClickItem(data)} className="Task_Container">
        <img style={{ width: '100%', aspectRatio: '1/1', borderRadius: 7 }} src={`${API_URL}/files/${data.fItemImage.filename}`} alt='' />
        <text style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center', marginTop: '20px' }}>{data.fItemName}</text>
    </div>
  );
};

export default PengajuanItemsCard;
