import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import SupervisorAbout from './Pages/HomePage/SupervisorAbout';
import HomePage from './Pages/HomePage/HomePage';

import ViewAllAbsensi from './Pages/Absensi/ViewAllAbsensi';
import DetailAbsensi from './Pages/Absensi/DetailAbsensi';

import ViewAllProject from './Pages/Project/ViewAllProject';
import ViewAllUserProject from './Pages/Project/ViewAllUserProject';
import DetailProject from './Pages/Project/DetailProject';
import AddProject from './Pages/Project/AddProject';
import EditProject from './Pages/Project/EditProject';
import ViewAllProjectProgress from './Pages/Project/ViewAllProjectProgress';

import EditProgressProject from './Pages/Project/EditProgressProject';
import AddProgressProject from './Pages/Project/AddProgressProject';

import ViewAllProgressSPV from './Pages/ProgressSPV/ViewAllProgressSPV';
import DetailProgressSPV from './Pages/ProgressSPV/DetailProgressSPV';
import AddProgressSPV from './Pages/ProgressSPV/AddProgressSPV';
import UpdateProgressSPV from './Pages/ProgressSPV/UpdateProgressSPV';

import ViewAllNotulen from './Pages/Notulen/ViewAllNotulen';
import DetailNotulen from './Pages/Notulen/DetailNotulen';
import AddNotulen from './Pages/Notulen/AddNotulen';
import UpdateNotulen from './Pages/Notulen/UpdateNotulen';

import ViewAllTask from './Pages/Task/ViewAllTask';
import AddTask from './Pages/Task/AddTask';
import DetailTask from './Pages/Task/DetailTask';

import ViewAllPengajuan from './Pages/Pengajuan/ViewAllPengajuan';
import AddPengajuan from './Pages/Pengajuan/AddPengajuan';
import DetailPengajuan from './Pages/Pengajuan/DetailPengajuan';
import AddSuratJalan from './Pages/Pengajuan/AddSuratJalan';

import LoginPage from './Pages/LoginPage/LoginPage';
import DetailProfile from './Pages/Profile/DetailProfile';
import AddUser from './Pages/Profile/AddUser';
import NotFound from './Pages/NotFound';

import ScrollToTop from './Components/ScrollToTop';
import UpdateTask from './Pages/Task/UpdateTask';

import AddPlanning from './Pages/Planning/AddPlanning';
import ViewAllPlanning from './Pages/Planning/ViewAllPlanning';
import DetailPlanning from './Pages/Planning/DetailPlanning';

import AddKasbon from './Pages/Kasbon/AddKasbon';
import DetailKasbon from './Pages/Kasbon/DetailKasbon';
import ViewAllKasbon from './Pages/Kasbon/ViewAllKasbon';

import AddAbsensiRequest from './Pages/AbsensiRequest/AddAbsensiRequest';
import ViewAllAbsensiRequest from './Pages/AbsensiRequest/ViewAllAbsensiRequest';
import DetailAbsensiRequest from './Pages/AbsensiRequest/DetailAbsensiRequest';
import ViewAllProjectNotulen from './Pages/Project/ViewAllProjectNotulen';
import ViewAllProjectPengajuan from './Pages/Project/ViewAllProjectPengajuan';
import ViewAllUser from './Pages/Profile/ViewAllUser';
import ChangePassword from './Pages/Profile/ChangePassword';
import ViewAllActivity from './Pages/Activity/ViewAllActivity';
import DetailActivity from './Pages/Activity/DetailActivity';
import AddActivity from './Pages/Activity/AddActivity';
import UpdateActivity from './Pages/Activity/UpdateActivity';
import DetailClient from './Pages/Client/DetailClient';
import AddClient from './Pages/Client/AddClient';
import UpdateClient from './Pages/Client/UpdateClient';
import ViewAllClient from './Pages/Client/ViewAllClient';
import AddProgressClient from './Pages/Client/AddProgressClient';
import UpdateProgressClient from './Pages/Client/UpdateProgressClient';
import AddPengajuanItems from './Pages/PengajuanItems/AddPengajuanItems';
import ViewAllPengajuanItems from './Pages/PengajuanItems/ViewAllPengajuanItems';
import UpdatePengajuanItems from './Pages/PengajuanItems/UpdatePengajuanItems';
import DetailPembelian from './Pages/Pembelian/DetailPembelian';
import AddPembelian from './Pages/Pembelian/AddPembelian';
import ListPengajuan from './Pages/Pembelian/ListPengajuan';
import UpdatePembelian from './Pages/Pembelian/UpdatePembelian';
import ViewAllPembelian from './Pages/Pembelian/ViewAllPembelian';
import AddPembayaran from './Pages/Pembayaran/AddPembayaran';
import DetailPembayaran from './Pages/Pembayaran/DetailPembayaran';
import ViewAllPembayaran from './Pages/Pembayaran/ViewAllPembayaran';
import RekapAbsensi from './Pages/Absensi/RekapAbsensi';

const App = () => {
  return (
    <Router>
      <ScrollToTop/>
      <Routes>
        <Route exact path='/' element={<HomePage />} />
        <Route path='/profile' element={<DetailProfile />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/about' element={<SupervisorAbout />} />
        <Route path='/adduser' element={<AddUser />} />
        <Route path='/alluser' element={<ViewAllUser />} />
        <Route path='/changepassword' element={<ChangePassword />} />
        
        <Route path='/allabsensi' element={<ViewAllAbsensi />} />
        <Route path='/detailabsensi' element={<DetailAbsensi />} />
        <Route path='/addabsensirequest' element={<AddAbsensiRequest />} />
        <Route path='/allabsensirequest' element={<ViewAllAbsensiRequest />} />
        <Route path='/detailabsensirequest' element={<DetailAbsensiRequest />} />
        <Route path='/rekapabsensi' element={<RekapAbsensi />} />

        <Route path='/allproject' element={<ViewAllProject />} />
        <Route path='/alluserproject' element={<ViewAllUserProject />} />
        <Route path='/detailproject' element={<DetailProject />} />
        <Route path='/addproject' element={<AddProject />} />
        <Route path='/editproject' element={<EditProject />} />
        <Route path='/allprojectprogress' element={<ViewAllProjectProgress />} />

        <Route path='/allprojectnotulen' element={<ViewAllProjectNotulen />} />
        <Route path='/allprojectpengajuan' element={<ViewAllProjectPengajuan />} />

        <Route path='/editprogressproject' element={<EditProgressProject />} />
        <Route path='/addprogressproject' element={<AddProgressProject />} />

        <Route path='/allprogress' element={<ViewAllProgressSPV />} />
        <Route path='/detailprogress' element={<DetailProgressSPV />} />
        <Route path='/addprogress' element={<AddProgressSPV />} />
        <Route path='/updateprogress' element={<UpdateProgressSPV />} />

        <Route path='/allactivity' element={<ViewAllActivity />} />
        <Route path='/detailactivity' element={<DetailActivity />} />
        <Route path='/addactivity' element={<AddActivity />} />
        <Route path='/updateactivity' element={<UpdateActivity />} />

        <Route path='/allclient' element={<ViewAllClient />} />
        <Route path='/detailclient' element={<DetailClient />} />
        <Route path='/addclient' element={<AddClient />} />
        <Route path='/updateclient' element={<UpdateClient />} />
        <Route path='/addprogressclient' element={<AddProgressClient />} />
        <Route path='/updateprogressclient' element={<UpdateProgressClient />} />

        <Route path='/allnotulen' element={<ViewAllNotulen />} />
        <Route path='/detailnotulen' element={<DetailNotulen />} />
        <Route path='/addnotulen' element={<AddNotulen />} />
        <Route path='/updatenotulen' element={<UpdateNotulen />} />

        <Route path='/alltask' element={<ViewAllTask />} />
        <Route path='/addtask' element={<AddTask />} />
        <Route path='/edittask' element={<UpdateTask />} />
        <Route path='/detailtask' element={<DetailTask />} />
        
        <Route path='/allpengajuan' element={<ViewAllPengajuan />} />
        <Route path='/detailpengajuan' element={<DetailPengajuan />} />
        <Route path='/addpengajuan' element={<AddPengajuan />} />
        <Route path='/addsuratjalan' element={<AddSuratJalan />} />

        <Route path='/additems' element={<AddPengajuanItems />} />
        <Route path='/updateitem' element={<UpdatePengajuanItems />} />
        <Route path='/allitems' element={<ViewAllPengajuanItems />} />

        <Route path='/addplanning' element={<AddPlanning />} />
        <Route path='/allplanning' element={<ViewAllPlanning />} />
        <Route path='/detailplanning' element={<DetailPlanning />} />

        <Route path='/allkasbon' element={<ViewAllKasbon />} />
        <Route path='/addkasbon' element={<AddKasbon />} />
        <Route path='/detailkasbon' element={<DetailKasbon />} />

        <Route path='/addpembelian' element={<AddPembelian />} />
        <Route path='/editpembelian' element={<UpdatePembelian />} />
        <Route path='/detailpembelian' element={<DetailPembelian />} />
        <Route path='/detailpembayaran' element={<DetailPembayaran />} />
        <Route path='/listpengajuan' element={<ListPengajuan />} />
        <Route path='/allpembelian' element={<ViewAllPembelian />} />

        <Route path='/addpembayaran' element={<AddPembayaran />} />
        <Route path='/detailpembayaran' element={<DetailPembayaran />} />
        <Route path='/allpembayaran' element={<ViewAllPembayaran />} />
        
        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </Router>
  );
}

export default App;