import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import profile from "../../assets/profile.png";
import calonclientlogo from "../../assets/calonclient.png";

import "@fontsource/poppins";

import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Dropdown from "../../Components/Dropdown/Dropdown";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

const AddClient = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 426px)").matches
  );

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  const navigate = useNavigate();

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      if (
        response.data.user[0].fRole !== "BOSS" &&
        response.data.user[0].fRole !== "PM" &&
        response.data.user[0].fRole !== "MARKETING"
      ) {
        navigate("/");
      }
      setUser(response.data.user[0]);
      setToken(response.data.accessToken);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const DetailProfileSmall = () => {
    const inputRef = useRef(null);
    const [image, setImage] = useState("");
    const [name, setName] = useState("");
    const [projectName, setProjectName] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [ket, setKet] = useState("");

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleImageClick = () => {
      inputRef.current.click();
    };
    const handleImageChange = (event) => {
      const file = event.target.files[0];
      setImage(file);
    };

    const validationForm = (e) => {
      e.preventDefault();
      const validationErrors = {};

      if (!name.trim()) {
        validationErrors.name = "harus diisi";
      }

      if (!projectName.trim()) {
        validationErrors.project = "harus diisi";
      }

      if (!phone.trim()) {
        validationErrors.phone = "harus diisi";
      }

      if (!address.trim()) {
        validationErrors.address = "harus diisi";
      }

      if (!ket.trim()) {
        validationErrors.ket = "harus diisi";
      }

      if (image === "") {
        validationErrors.image = "foto profile diperlukan";
      }

      setErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0 && !loading) {
        handleCreateUser();
      }
    };

    const handleCreateUser = async () => {
      setLoading(true);
      var bodyFormData = new FormData();
      bodyFormData.append("fName", name);
      bodyFormData.append("fProjectName", projectName);
      bodyFormData.append("fPhoneNumber", phone);
      bodyFormData.append("fAddress", address);
      bodyFormData.append("fImages", image);
      bodyFormData.append("fKet", ket);

      try {
        const response = await axiosJWT.post(
          `${API_URL}/addclient`,
          bodyFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        navigate("/");
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    return (
      <div
        style={{
          backgroundColor: "#F3F4F8",
          fontFamily: "Poppins",
          color: "#282828",
          minHeight: "100vh",
          paddingTop: "152px",
          paddingBottom: "25px",
        }}
      >
        <Navbar profileData={user} />

        <div className="DetailProfileContainer">
          {/* image input */}
          <div onClick={handleImageClick}>
            {image ? (
              <img
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "cover",
                  borderRadius: 100,
                }}
                src={URL.createObjectURL(image)}
                alt=""
              />
            ) : (
              <img
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "cover",
                  borderRadius: 100,
                }}
                src={profile}
                alt=""
              />
            )}
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>

          <div className="DetailProfileUneditableContainer">
            {/* Name */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">Nama Calon Owner</text>
              {errors.name && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {" "}
                  *{errors.name}
                </span>
              )}
              <div />
              <input
                className="DetailProfileInputSmall"
                type="text"
                placeholder="calon owner"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
              />
            </div>

            {/* Project Name */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">Nama Project</text>
              {errors.project && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {" "}
                  *{errors.project}
                </span>
              )}
              <div />
              <input
                className="DetailProfileInputSmall"
                type="text"
                placeholder="project"
                onChange={(e) => {
                  setProjectName(e.target.value);
                }}
                value={projectName}
              />
            </div>

            {/* phone */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">No Hp</text>
              {errors.phone && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {" "}
                  *{errors.phone}
                </span>
              )}
              <div />
              <input
                className="DetailProfileInputSmall"
                type="text"
                placeholder="08111111111"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                value={phone}
              />
            </div>

            {/* Alamat */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">Alamat</text>
              {errors.address && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {" "}
                  *{errors.address}
                </span>
              )}
              <div />
              <textarea
                className="DetailProfileInputBig"
                type="text"
                style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
                placeholder="alamat"
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                value={address}
              />
            </div>

            {/* Keterangan */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">Keterangan</text>
              {errors.ket && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {" "}
                  *{errors.ket}
                </span>
              )}
              <div />
              <textarea
                className="DetailProfileInputBig"
                type="text"
                style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
                placeholder="keterangan"
                onChange={(e) => {
                  setKet(e.target.value);
                }}
                value={ket}
              />
            </div>
          </div>

          {errors.image && (
            <span
              className="DetailProfileLabel"
              style={{
                color: "red",
                fontSize: "14px",
                alignSelf: "flex-start",
                marginLeft: "10%",
              }}
            >
              {" "}
              *{errors.image}
            </span>
          )}

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "80%",
              gap: "15px",
              marginTop: "25px",
            }}
          >
            <div
              style={{
                backgroundColor: "#D0A360",
                marginTop: "10px",
                padding: "10px 0",
                borderRadius: 3,
              }}
              className="AddProjectDownloadButton"
              onClick={validationForm}
            >
              {loading && <span>Loading...</span>}
              {!loading && <span>Tambah Owner</span>}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const DetailProfileBig = () => {
    const inputRef = useRef(null);
    const [image, setImage] = useState("");
    const [name, setName] = useState("");
    const [projectName, setProjectName] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [ket, setKet] = useState("");

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleImageClick = () => {
      inputRef.current.click();
    };
    const handleImageChange = (event) => {
      const file = event.target.files[0];
      setImage(file);
    };

    const validationForm = (e) => {
      e.preventDefault();
      const validationErrors = {};

      if (!name.trim()) {
        validationErrors.name = "harus diisi";
      }

      if (!projectName.trim()) {
        validationErrors.project = "harus diisi";
      }

      if (!phone.trim()) {
        validationErrors.phone = "harus diisi";
      }

      if (!address.trim()) {
        validationErrors.address = "harus diisi";
      }

      if (!ket.trim()) {
        validationErrors.ket = "harus diisi";
      }

      if (image === "") {
        validationErrors.image = "foto profile diperlukan";
      }

      setErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0 && !loading) {
        handleCreateUser();
      }
    };

    const handleCreateUser = async () => {
      setLoading(true);
      var bodyFormData = new FormData();
      bodyFormData.append("fName", name);
      bodyFormData.append("fProjectName", projectName);
      bodyFormData.append("fPhoneNumber", phone);
      bodyFormData.append("fAddress", address);
      bodyFormData.append("fImages", image);
      bodyFormData.append("fKet", ket);

      try {
        const response = await axiosJWT.post(
          `${API_URL}/addclient`,
          bodyFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        navigate("/");
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    return (
      <div
        style={{
          backgroundColor: "#F3F4F8",
          fontFamily: "Poppins",
          color: "#282828",
          minHeight: "100vh",
          paddingTop: "152px",
          paddingBottom: "25px",
        }}
      >
        <Navbar profileData={user} />

        <div className="DetailProfileContainer">
          {/* image input */}
          <div onClick={handleImageClick}>
            {image ? (
              <img
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "cover",
                  borderRadius: 100,
                }}
                src={URL.createObjectURL(image)}
                alt=""
              />
            ) : (
              <img
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "cover",
                  borderRadius: 100,
                }}
                src={profile}
                alt=""
              />
            )}
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>

          <div className="DetailProfileUneditableContainer">
            {/* Nama */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">Nama Calon Owner</text>
                {errors.name && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.name}*
                  </span>
                )}
              </div>
              <div className=" DetailProfileInputSizeRight2">
                <input
                  style={{ marginTop: 0 }}
                  className="DetailProfileInputSmall"
                  type="text"
                  placeholder="nama"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                />
              </div>
            </div>

            {/* Project Name */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">Nama Project</text>
                {errors.project && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.project}*
                  </span>
                )}
              </div>
              <div className=" DetailProfileInputSizeRight2">
                <input
                  style={{ marginTop: 0 }}
                  className="DetailProfileInputSmall"
                  type="text"
                  placeholder="project"
                  onChange={(e) => {
                    setProjectName(e.target.value);
                  }}
                  value={projectName}
                />
              </div>
            </div>

            {/* phone */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">No Hp</text>
                {errors.phone && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.phone}*
                  </span>
                )}
              </div>
              <div className=" DetailProfileInputSizeRight2">
                <input
                  style={{ marginTop: 0 }}
                  className="DetailProfileInputSmall"
                  type="text"
                  placeholder="08111111111"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  value={phone}
                />
              </div>
            </div>

            {/* Alamat */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">Alamat</text>
                {errors.address && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.address}*
                  </span>
                )}
              </div>
              <div className=" DetailProfileInputSizeRight2">
                <textarea
                  className="DetailProfileInputBig"
                  type="text"
                  style={{ fontFamily: "Poppins", marginTop: 0 }}
                  placeholder="alamat"
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  value={address}
                />
              </div>
            </div>

            {/* Keterangan */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">Keterangan</text>
                {errors.ket && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.ket}*
                  </span>
                )}
              </div>
              <div className=" DetailProfileInputSizeRight2">
                <textarea
                  className="DetailProfileInputBig"
                  type="text"
                  style={{ fontFamily: "Poppins", marginTop: 0 }}
                  placeholder="keterangan"
                  onChange={(e) => {
                    setKet(e.target.value);
                  }}
                  value={ket}
                />
              </div>
            </div>
          </div>

          {errors.image && (
            <span
              className="DetailProfileLabel"
              style={{
                color: "red",
                fontSize: "14px",
                alignSelf: "flex-start",
                marginLeft: "10%",
              }}
            >
              {" "}
              *{errors.image}
            </span>
          )}

          <div
            style={{
              backgroundColor: "#D0A360",
              marginTop: "10px",
              padding: "10px 0",
              borderRadius: 3,
            }}
            className="AddProjectDownloadButton"
            onClick={validationForm}
          >
            {loading && <span>Loading...</span>}
            {!loading && <span>Tambah Owner</span>}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {!matches && <DetailProfileSmall />}
      {matches && <DetailProfileBig />}
    </div>
  );
};

export default AddClient;
