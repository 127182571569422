import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import profile from "../../assets/profile.png";

import "@fontsource/poppins";

import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Dropdown from "../../Components/Dropdown/Dropdown";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

const AddUser = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 426px)").matches
  );

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  const navigate = useNavigate();

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      if (
        response.data.user[0].fRole !== "BOSS" &&
        response.data.user[0].fRole !== "PM"
      ) {
        navigate("/");
      }
      setUser(response.data.user[0]);
      setToken(response.data.accessToken);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const DetailProfileSmall = () => {
    const inputRef = useRef(null);
    const [image, setImage] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [nameUser, setNameUser] = useState("");
    const [roleAlias, setRoleAlias] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [password, setPassword] = useState("");

    const [errors, setErrors] = useState({});
    const [msg, setMsg] = useState('')

    const [loading, setLoading] = useState(false);

    const [optionRole, setOptionRole] = useState([
      { name: "BOSS" },
      { name: "PM" },
      { name: "SPV" },
      { name: "SUPIR" },
      { name: "CLIENT" },
      { name: "MARKETING" },
      { name: "FINANCE" },
      { name: "HRD" },
    ]);
    const [selectedRoleData, setSelectedRoleData] = useState({ name: "BOSS" });
    const [selectedRole, setSelectedRole] = useState("Pilih Role");

    const handleImageClick = () => {
      inputRef.current.click();
    };
    const handleImageChange = (event) => {
      const file = event.target.files[0];
      setImage(file);
    };

    const validationForm = (e) => {
      e.preventDefault();
      const validationErrors = {};
      if (!username.trim()) {
        validationErrors.username = "harus diisi";
      }

      if (!email.trim()) {
        validationErrors.email = "harus diisi";
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        validationErrors.email = "tidak valid";
      }

      if (!nameUser.trim()) {
        validationErrors.name = "harus diisi";
      }

      if (!roleAlias.trim()) {
        validationErrors.alias = "harus diisi";
      }

      if (selectedRole === 'Pilih Role') {
        validationErrors.role = "harus dipilih";
      }

      if (!address.trim()) {
        validationErrors.address = "harus diisi";
      }

      if (!phone.trim()) {
        validationErrors.phone = "harus diisi";
      }

      if (!password.trim()) {
        validationErrors.password = "harus diisi";
      } else if (password.length < 6) {
        validationErrors.password = "harus diisi setidaknya 6 karakter";
      }

      if(image === '') {
        validationErrors.image = "foto profile diperlukan"
      }

      setErrors(validationErrors)

      if(Object.keys(validationErrors).length === 0) {
        handleCreateUser()
    }
    };

    const handleCreateUser = async () => {
      setLoading(true);
      var bodyFormData = new FormData();
      bodyFormData.append("fUserName", username);
      bodyFormData.append("fEmail", email);
      bodyFormData.append("fName", nameUser);
      bodyFormData.append("fRoleAlias", roleAlias);
      bodyFormData.append("fRole", selectedRole);
      bodyFormData.append("fAddress", address);
      bodyFormData.append("fPhoneNumber", phone);
      bodyFormData.append("fPassword", password);
      bodyFormData.append("fImages", image);

      try {
        await axiosJWT.post(`${API_URL}/users`, bodyFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
        navigate("/");
      } catch (error) {
        setLoading(false)
        if(error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };

    return (
      <div
        style={{
          backgroundColor: "#F3F4F8",
          fontFamily: "Poppins",
          color: "#282828",
          minHeight: "100vh",
          paddingTop: "152px",
          paddingBottom: "25px",
        }}
      >
        <Navbar profileData={user} />

        <div className="DetailProfileContainer">
          {/* image input */}
          <div onClick={handleImageClick}>
            {image ? (
              <img
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "cover",
                  borderRadius: 100,
                }}
                src={URL.createObjectURL(image)}
                alt=""
              />
            ) : (
              <img
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "cover",
                  borderRadius: 100,
                }}
                src={profile}
                alt=""
              />
            )}
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>

          <div className="DetailProfileUneditableContainer">
            {/* Username */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">Username</text>
              {errors.username && <span style={{ color: 'red', fontSize: '14px' }}> *{errors.username}</span>}
              <div />
              <input
                className="DetailProfileInputSmall"
                type="text"
                placeholder="username"
                name="username"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                value={username}
              />
            </div>

            {/* Email */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">Email</text>
              {errors.email && <span style={{ color: 'red', fontSize: '14px' }}> *{errors.email}</span>}
              <div />
              <input
                className="DetailProfileInputSmall"
                type="email"
                name="email"
                placeholder="john@gmail.com"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />
            </div>

            {/* Nama */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">Nama</text>
              {errors.name && <span style={{ color: 'red', fontSize: '14px' }}> *{errors.name}</span>}
              <div />
              <input
                className="DetailProfileInputSmall"
                type="text"
                name="name"
                placeholder="name"
                onChange={(e) => {
                  setNameUser(e.target.value);
                }}
                value={nameUser}
              />
            </div>

            {/* Role Alias */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">Role Alias</text>
              {errors.alias && <span style={{ color: 'red', fontSize: '14px' }}> *{errors.alias}</span>}
              <div />
              <input
                className="DetailProfileInputSmall"
                type="text"
                name="alias"
                placeholder="role alias"
                onChange={(e) => {
                  setRoleAlias(e.target.value);
                }}
                value={roleAlias}
              />
            </div>

            {/* Role */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">Role</text>
              {errors.role && <span style={{ color: 'red', fontSize: '14px' }}> *{errors.role}</span>}
              <div />
              <div style={{ width: "95%" }}>
                <Dropdown
                  selected={selectedRole}
                  setSelected={setSelectedRole}
                  setSelectedData={setSelectedRoleData}
                  options={optionRole}
                  type={"name"}
                />
              </div>
            </div>

            {/* Nomor Telepon */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">Nomor Telepon</text>
              {errors.phone && <span style={{ color: 'red', fontSize: '14px' }}> *{errors.phone}</span>}
              <div />
              <input
                className="DetailProfileInputSmall"
                type="number"
                name="telp"
                placeholder="phone number"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                value={phone}
              />
            </div>

            {/* Alamat */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">Alamat</text>
              {errors.address && <span style={{ color: 'red', fontSize: '14px' }}> *{errors.address}</span>}
              <div />
              <textarea
                className="DetailProfileInputBig"
                type="number"
                name="address"
                style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
                placeholder="phone number"
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                value={address}
              />
            </div>

            {/* Password */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">Password</text>
              {errors.password && <span style={{ color: 'red', fontSize: '14px' }}> *{errors.password}</span>}
              <div />
              <input
                className="DetailProfileInputSmall"
                type="password"
                name="password"
                placeholder="******"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
              />
            </div>
          </div>

          {errors.image && <span className="DetailProfileLabel" style={{ color: 'red', fontSize: '14px', alignSelf: 'flex-start', marginLeft: '10%' }}> *{errors.image}</span>}
          {msg && <span className="DetailProfileLabel" style={{ color: 'red', fontSize: '14px', alignSelf: 'flex-start', marginLeft: '10%' }}> *{msg}</span>}

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "80%",
              gap: "15px",
              marginTop: "25px",
            }}
          >
            <div
              style={{
                backgroundColor: "#D0A360",
                marginTop: "10px",
                padding: "10px 0",
                borderRadius: 3,
                opacity: loading ? "0.7" : "1",
              }}
              className="AddProjectDownloadButton"
              onClick={validationForm}
            >
              {loading && <span>Loading...</span>}
              {!loading && <span>Buat User</span>}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const DetailProfileBig = () => {
    const inputRef = useRef(null);
    const [image, setImage] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [nameUser, setNameUser] = useState("");
    const [roleAlias, setRoleAlias] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [password, setPassword] = useState("");

    const [errors, setErrors] = useState({});
    const [msg, setMsg] = useState('')

    const [loading, setLoading] = useState(false);

    const [optionRole, setOptionRole] = useState([
      { name: "BOSS" },
      { name: "PM" },
      { name: "SPV" },
      { name: "SUPIR" },
      { name: "CLIENT" },
      { name: "MARKETING" },
      { name: "FINANCE" },
      { name: "HRD" },
    ]);

    const [selectedRoleData, setSelectedRoleData] = useState({ name: "BOSS" });

    const [selectedRole, setSelectedRole] = useState("Pilih Role");

    const handleImageClick = () => {
      inputRef.current.click();
    };
    const handleImageChange = (event) => {
      const file = event.target.files[0];
      setImage(file);
    };

    const validationForm = (e) => {
      e.preventDefault();
      const validationErrors = {};
      if (!username.trim()) {
        validationErrors.username = "harus diisi";
      }

      if (!email.trim()) {
        validationErrors.email = "harus diisi";
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        validationErrors.email = "tidak valid";
      }

      if (!nameUser.trim()) {
        validationErrors.name = "harus diisi";
      }

      if (!roleAlias.trim()) {
        validationErrors.alias = "harus diisi";
      }

      if (selectedRole === 'Pilih Role') {
        validationErrors.role = "harus dipilih";
      }

      if (!address.trim()) {
        validationErrors.address = "harus diisi";
      }

      if (!phone.trim()) {
        validationErrors.phone = "harus diisi";
      }

      if (!password.trim()) {
        validationErrors.password = "harus diisi";
      } else if (password.length < 6) {
        validationErrors.password = "harus diisi setidaknya 6 karakter";
      }

      if(image === '') {
        validationErrors.image = "foto profile diperlukan"
      }

      setErrors(validationErrors)

      if(Object.keys(validationErrors).length === 0) {
        handleCreateUser()
    }
    };

    const handleCreateUser = async () => {
      setLoading(true);
      var bodyFormData = new FormData();
      bodyFormData.append("fUserName", username);
      bodyFormData.append("fEmail", email);
      bodyFormData.append("fName", nameUser);
      bodyFormData.append("fRoleAlias", roleAlias);
      bodyFormData.append("fRole", selectedRole);
      bodyFormData.append("fAddress", address);
      bodyFormData.append("fPhoneNumber", phone);
      bodyFormData.append("fPassword", password);
      bodyFormData.append("fImages", image);

      try {
        await axiosJWT.post(`${API_URL}/users`, bodyFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
        navigate("/");
      } catch (error) {
        setLoading(false)
        if(error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };

    return (
      <div
        style={{
          backgroundColor: "#F3F4F8",
          fontFamily: "Poppins",
          color: "#282828",
          minHeight: "100vh",
          paddingTop: "152px",
          paddingBottom: "25px",
        }}
      >
        <Navbar profileData={user} />

        <div className="DetailProfileContainer">
          {/* image input */}
          <div onClick={handleImageClick}>
            {image ? (
              <img
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "cover",
                  borderRadius: 100,
                }}
                src={URL.createObjectURL(image)}
                alt=""
              />
            ) : (
              <img
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "cover",
                  borderRadius: 100,
                }}
                src={profile}
                alt=""
              />
            )}
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>

          <div className="DetailProfileUneditableContainer">
            {/* Username */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">Username</text>
                {errors.username && <span style={{ color: 'red', fontSize: '14px' }}>{errors.username}*</span>}
              </div>
              <div className=" DetailProfileInputSizeRight2">
                <input
                  style={{ marginTop: 0 }}
                  className="DetailProfileInputSmall"
                  type="text"
                  placeholder="username"
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  value={username}
                />
              </div>
            </div>

            {/* Email */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">Email</text>
                {errors.email && <span style={{ color: 'red', fontSize: '14px' }}>{errors.email}*</span>}
              </div>
              <div className=" DetailProfileInputSizeRight2">
                <input
                  style={{ marginTop: 0 }}
                  className="DetailProfileInputSmall"
                  type="email"
                  placeholder="john@gmail.com"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                />
              </div>
            </div>

            {/* Nama */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">Nama</text>
                {errors.name && <span style={{ color: 'red', fontSize: '14px' }}>{errors.name}*</span>}
              </div>
              <div className=" DetailProfileInputSizeRight2">
                <input
                  style={{ marginTop: 0 }}
                  className="DetailProfileInputSmall"
                  type="text"
                  placeholder="nama"
                  onChange={(e) => {
                    setNameUser(e.target.value);
                  }}
                  value={nameUser}
                />
              </div>
            </div>

            {/* Role Alias */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">Role Alias</text>
                {errors.alias && <span style={{ color: 'red', fontSize: '14px' }}>{errors.alias}*</span>}
              </div>
              <div className=" DetailProfileInputSizeRight2">
                <input
                  style={{ marginTop: 0 }}
                  className="DetailProfileInputSmall"
                  type="text"
                  placeholder="alias"
                  onChange={(e) => {
                    setRoleAlias(e.target.value);
                  }}
                  value={roleAlias}
                />
              </div>
            </div>

            {/* Role */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">Role</text>
                {errors.role && <span style={{ color: 'red', fontSize: '14px' }}>{errors.role}*</span>}
              </div>
              <div className=" DetailProfileInputSizeRight3">
                <Dropdown
                  selected={selectedRole}
                  setSelected={setSelectedRole}
                  setSelectedData={setSelectedRoleData}
                  options={optionRole}
                  type={"name"}
                />
              </div>
            </div>

            {/* Nomor Telepon */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">Nomor Telepon</text>
                {errors.phone && <span style={{ color: 'red', fontSize: '14px' }}>{errors.phone}*</span>}
              </div>
              <div className=" DetailProfileInputSizeRight2">
                <input
                  style={{ marginTop: 0 }}
                  className="DetailProfileInputSmall"
                  type="number"
                  placeholder="phone number"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  value={phone}
                />
              </div>
            </div>

            {/* Alamat */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">Alamat</text>
                {errors.address && <span style={{ color: 'red', fontSize: '14px' }}>{errors.address}*</span>}
              </div>
              <div className=" DetailProfileInputSizeRight2">
                <textarea
                  className="DetailProfileInputBig"
                  type="number"
                  style={{ fontFamily: "Poppins", marginTop: 0 }}
                  placeholder="alamat"
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  value={address}
                />
              </div>
            </div>

            {/* Password */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">Password</text>
                {errors.password && <span className="DetailProfileLabel" style={{ color: 'red', fontSize: '14px' }}>{errors.password}*</span>}
              </div>
              <div className=" DetailProfileInputSizeRight2">
                <input
                  style={{ marginTop: 0 }}
                  className="DetailProfileInputSmall"
                  type="password"
                  placeholder="******"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                />
              </div>
            </div>
          </div>

          {errors.image && <span className="DetailProfileLabel" style={{ color: 'red', fontSize: '14px', alignSelf: 'flex-start', marginLeft: '10%' }}> *{errors.image}</span>}
          {msg && <span className="DetailProfileLabel" style={{ color: 'red', fontSize: '14px', alignSelf: 'flex-start', marginLeft: '10%' }}> *{msg}</span>}

          <div
            style={{
              backgroundColor: "#D0A360",
              marginTop: "10px",
              padding: "10px 0",
              borderRadius: 3,
              opacity: loading ? "0.7" : "1",
            }}
            className="AddProjectDownloadButton"
            onClick={validationForm}
          >
            {loading && <span>Loading...</span>}
            {!loading && <span>Buat User</span>}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {!matches && <DetailProfileSmall />}
      {matches && <DetailProfileBig />}
    </div>
  );
};

export default AddUser;
