import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";
import ProgressSPV from "../../Components/ProgressSPV/ProgressSPV";
import progress from "../../assets/progressspv.png";
import profile from "../../assets/profile.png";

import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";

import check from "../../assets/check.png";

import "./DetailProgressSPV.css";
import "@fontsource/poppins";

import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";

const DetailProgressSPV = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const { state } = useLocation();
  const { fID } = state;
  const [index, setIndex] = useState(0);
  const [data, setData] = useState({
    fID: "",
    fProjectID: "",
    fUserID: "",
    fRole: "",
    fCheck: "",
    fDateTime: new Date(),
    fImages: [],
    fKet: "",
    fActive: "",
    fProject: {
      fProjectName: "",
      fImages: [],
    },
    fUser: {
      fName: "",
    },
  });

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [loading, setLoading] = useState(false);

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    getProject();
  }, [user]);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getProject = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getsingleprogressspv`,
        { fID: fID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data);
    } catch (error) {}
  };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleEditClick = () => {
    navigate("/updateprogress", { state: { data: data } });
  };

  const handleDeleteClick = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/deleteprogressspv`,
        { fID: fID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/", { state: { fID: data.fID } });
    } catch (error) {}
  };

  const handleCheckClick = async () => {
    setLoading(true);
    try {
      const response = await axiosJWT.post(
        `${API_URL}/checkprogressspv`,
        { fID: fID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/", { state: { fID: data.fID } });
    } catch (error) {}
  };

  const renderTextWithLineBreaks = (text) => {
    return (text || "").split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        font: "16px",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: progress,
          title: "Detail Progress SPV",
          name: user.fName,
          job: user.fRoleAlias,
          type: "detail",
        }}
      />

      <div className="DetailProgressSPVContainer">
        <text
          style={{
            width: "100%",
            color: "#D0A360",
            fontWeight: "bold",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          {data.fProject.fProjectName}
        </text>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "90%",
            fontSize: "14px",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <img
              style={{ width: "40px", height: "40px" }}
              src={profile}
              alt=""
            />
            <text style={{ fontSize: "16px" }}>{data.fUser.fName}</text>
          </div>
          <text style={{ fontSize: "14px" }}>
            {dayjs(data.fDateTime).format("DD MMM YYYY, HH:mm").toString()}
          </text>
        </div>

        <div
          style={{
            width: "90%",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Carousel
            style={{ width: "90%" }}
            activeIndex={index}
            onSelect={handleSelect}
          >
            {data.fProject.fImages.map((d) => {
              return (
                <Carousel.Item>
                  <img
                    style={{
                      width: "100%",
                      aspectRatio: "743/443",
                      objectFit: "cover",
                      margin: "5px 0",
                    }}
                    src={`${API_URL}/files/${d.filename}`}
                    alt=""
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
          {data.fCheck === "Y" && (
            <div
              style={{
                position: "absolute",
                fontSize: "45px",
                backgroundColor: "rgba(31,105,14,0.7)",
                padding: "10px 20px",
                color: "white",
                borderRadius: 7,
              }}
            >
              Checked
            </div>
          )}
        </div>

        <div style={{ width: "90%", fontSize: "16px", fontWeight: "bold" }}>
          Foto progress
        </div>

        <div
          style={{
            width: "90%",
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          {data.fImages.map((d) => {
            return (
              <img
                className="DetailProgressImage"
                src={`${API_URL}/files/${d.filename}`}
                alt=""
              />
            );
          })}
        </div>

        <div
          style={{
            width: "90%",
            fontSize: "16px",
            marginTop: "10px",
            fontWeight: "bold",
          }}
        >
          Keterangan
        </div>
        <text style={{ width: "90%", fontSize: "16px", textAlign: "justify" }}>
          {renderTextWithLineBreaks(data.fKet)}
        </text>

        {data.fCheck !== "Y" && user.fRole === "SPV" && (
          <div
            onClick={() => {
              handleEditClick();
            }}
            style={{
              width: "90%",
              marginTop: "15px",
              backgroundColor: "#D0A360",
              color: "white",
              padding: "10px 0",
              display: "flex",
              flexDirection: "column",
              fontWeight: "bold",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 7,
            }}
          >
            Edit
          </div>
        )}

        {data.fCheck !== "Y" && user.fRole === "SPV" && (
          <div
            onClick={() => {
              handleDeleteClick();
            }}
            style={{
              width: "90%",
              marginTop: "15px",
              backgroundColor: "#D74949",
              color: "white",
              padding: "10px 0",
              display: "flex",
              flexDirection: "column",
              fontWeight: "bold",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 7,
            }}
          >
            Delete
          </div>
        )}

        {data.fCheck !== "Y" &&
          (user.fRole === "PM" || user.fRole === "BOSS") && (
            <div
              onClick={() => {
                if (!loading) {
                  handleCheckClick();
                }
              }}
              style={{
                width: "90%",
                marginTop: "15px",
                backgroundColor: "#D0A360",
                color: "white",
                padding: "10px 0",
                display: "flex",
                flexDirection: "column",
                fontWeight: "bold",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 7,
                opacity: loading ? '0.7' : '1'
              }}
            >
              {loading && <span>Loading...</span>}
              {!loading && <span>Tandai</span>}
            </div>
          )}
        <div style={{ width: "100%", height: "30px" }} />
      </div>
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default DetailProgressSPV;
