import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import profile from "../../assets/profile.png";

import "./DetailProfile.css";
import "@fontsource/poppins";

import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Dropdown from "../../Components/Dropdown/Dropdown";

const ChangePassword = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const { state } = useLocation();
  const { fID } = state;

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 1,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 426px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const DetailProfileSmall = () => {
    const [password, setPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [confPassword, setConfPassword] = useState();
    const [warning, setWarning] = useState(false);
    const [warningPassLength, setWarningPassLength] = useState(true);
    const [warningNewLength, setWarningNewLength] = useState(true);
    const [warningConfLength, setWarningConfLength] = useState(true);
    const [msg, setMsg] = useState('')

    const handleSaveButton = async () => {
      const same = newPassword === confPassword;
      setWarning(!same)
      if (same && !warningNewLength && !warningConfLength) {
        var bodyFormData = new FormData();
        bodyFormData.append('fID', fID);
        bodyFormData.append("fPassword", password);
        bodyFormData.append("fNewPassword", newPassword);

        try {
          const response = await axiosJWT.post(
            `${API_URL}/changepassword`,
            bodyFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          navigate("/");
        } catch (error) {
            if(error.response) {
                setMsg(error.response.data.msg);
              }
        }
      }
    };

    return (
      <div
        style={{
          backgroundColor: "#F3F4F8",
          fontFamily: "Poppins",
          color: "#282828",
          minHeight: "100vh",
          paddingTop: "152px",
          paddingBottom: "25px",
        }}
      >
        <Navbar profileData={user} />

        <div className="DetailProfileContainer">
        {msg && <text style={{ width: '80%', margin: 'auto', color: 'red' }}>*{msg}</text>}

          <div className="DetailProfileUneditableContainer">
            {/* Username */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">Password</text>
              <div />
              <input
                value={password}
                onChange={(v) => {
                  setPassword(v.target.value);
                  if (v.target.value.length > 5) {
                    setWarningPassLength(false);
                  } else {
                    setWarningPassLength(true);
                  }
                }}
                className="DetailProfileInputSmall"
                type="password"
                placeholder="username"
              />
              {warningPassLength && (
                <div>
                  <div style={{ height: "5px" }} />
                  <text style={{ color: "red" }}>*Min.6 Huruf/Angka</text>
                </div>
              )}
            </div>

            {/* New Password */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">New Password</text>
              <div />
              <input
                value={newPassword}
                onChange={(v) => {
                  setNewPassword(v.target.value);
                  if (v.target.value.length > 5) {
                    setWarningNewLength(false);
                  } else {
                    setWarningNewLength(true);
                  }
                }}
                className="DetailProfileInputSmall"
                type="password"
                placeholder="username"
              />
              {warningNewLength && (
                <div>
                  <div style={{ height: "5px" }} />
                  <text style={{ color: "red" }}>*Min.6 Huruf/Angka</text>
                </div>
              )}
            </div>

            {/* Confirm */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">Konfirmasi Password</text>
              <div />
              <input
                value={confPassword}
                onChange={(v) => {
                  setConfPassword(v.target.value);
                  if (v.target.value.length > 5) {
                    setWarningConfLength(false);
                  } else {
                    setWarningConfLength(true);
                  }
                }}
                style={{border: warning ? "2px solid red" : "",}}
                className="DetailProfileInputSmall"
                type="password"
                placeholder="username"
              />

              {warningConfLength && (
                <div>
                  <div style={{ height: "5px" }} />
                  <text style={{ color: "red" }}>*Min.6 Huruf/Angka</text>
                </div>
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "80%",
              gap: "15px",
              marginTop: "25px",
            }}
          >
            <div
              onClick={handleSaveButton}
              className="DetaiProfileButtonSmall1"
              style={{
                borderRadius: "2px",
                fontSize: "14px",
                height: "50px",
                color: "white",
                backgroundColor: "#D1A361",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Simpan
            </div>
          </div>
        </div>
      </div>
    );
  };

  const DetailProfileBig = () => {
    const [password, setPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [confPassword, setConfPassword] = useState();
    const [warning, setWarning] = useState(false);
    const [warningPassLength, setWarningPassLength] = useState(true);
    const [warningNewLength, setWarningNewLength] = useState(true);
    const [warningConfLength, setWarningConfLength] = useState(true);
    const [msg, setMsg] = useState('')

    const handleSaveButton = async () => {
      const same = newPassword === confPassword;
      setWarning(!same)
      if (same && !warningNewLength && !warningConfLength) {
        var bodyFormData = new FormData();
        bodyFormData.append('fID', fID);
        bodyFormData.append("fPassword", password);
        bodyFormData.append("fNewPassword", newPassword);

        try {
          const response = await axiosJWT.post(
            `${API_URL}/changepassword`,
            bodyFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          navigate("/");
        } catch (error) {
            if(error.response) {
                setMsg(error.response.data.msg);
              }
        }
      }
    };

    return (
      <div
        style={{
          backgroundColor: "#F3F4F8",
          fontFamily: "Poppins",
          color: "#282828",
          minHeight: "100vh",
          paddingTop: "152px",
          paddingBottom: "25px",
        }}
      >
        <Navbar profileData={user} />

        <div className="DetailProfileContainer">
        {msg && <text style={{ width: '80%', margin: 'auto', color: 'red' }}>*{msg}</text>}

          <div className="DetailProfileUneditableContainer">
            {/* Password */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">Password</text>
              </div>
              <div className=" DetailProfileInputSizeRight2">
                <input
                  value={password}
                  onChange={(v) => {
                    setPassword(v.target.value);
                    if (v.target.value.length > 5) {
                      setWarningPassLength(false);
                    } else {
                      setWarningPassLength(true);
                    }
                  }}
                  style={{ marginTop: 0 }}
                  className="DetailProfileInputSmall"
                  type="password"
                  placeholder="******"
                />
                {warningPassLength && (
                  <text style={{ color: "red", marginTop: "5px" }}>
                    *Min.6 Huruf/Angka
                  </text>
                )}
              </div>
            </div>

            {/* New Password */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">New Password</text>
              </div>
              <div className=" DetailProfileInputSizeRight2">
                <input
                  value={newPassword}
                  onChange={(v) => {
                    setNewPassword(v.target.value);
                    if (v.target.value.length > 5) {
                      setWarningNewLength(false);
                    } else {
                      setWarningNewLength(true);
                    }
                  }}
                  style={{ marginTop: 0 }}
                  className="DetailProfileInputSmall"
                  type="password"
                  placeholder="******"
                />
                {warningNewLength && (
                  <text style={{ color: "red", marginTop: "5px" }}>
                    *Min.6 Huruf/Angka
                  </text>
                )}
              </div>
            </div>

            {/* Konfirmasi */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">Konfirmasi</text>
              </div>
              <div className=" DetailProfileInputSizeRight2">
                <input
                  value={confPassword}
                  onChange={(v) => {
                    setConfPassword(v.target.value);
                    if (v.target.value.length > 5) {
                      setWarningConfLength(false);
                    } else {
                      setWarningConfLength(true);
                    }
                  }}
                  style={{
                    marginTop: 0,
                    border: warning ? "2px solid red" : "",
                  }}
                  className="DetailProfileInputSmall"
                  type="password"
                  placeholder="******"
                />
                {warningConfLength && (
                  <text style={{ color: "red", marginTop: "5px" }}>
                    *Min.6 Huruf/Angka
                  </text>
                )}
              </div>
            </div>
          </div>

          <div
            style={{
              width: "90%",
              display: "flex",
              flexDirection: "row",
              marginTop: "25px",
            }}
          >
            <div
              onClick={handleSaveButton}
              className="DetailProfileCalcLeft"
              style={{
                width: "50%",
                borderRadius: "2px",
                fontSize: "16px",
                height: "50px",
                marginRight: "15px",
                color: "white",
                backgroundColor: "#D1A361",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Simpan
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {!matches && <DetailProfileSmall />}
      {matches && <DetailProfileBig />}
    </div>
  );
};

export default ChangePassword;
