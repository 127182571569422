import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";

import file from "../../assets/file.png";
import bin from "../../assets/bin.png";
import Dropdown from "../../Components/Dropdown/Dropdown";
import DownloadCard from "../../Components/DownloadCard/DownloadCard";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import tasklogo from "../../assets/task.png";

import "./AddTask.css";
import "@fontsource/poppins";

import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";

import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";

const djs = dayjs().locale("de").format();

const DetailTask = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const { state } = useLocation();
  const { fID } = state;

  const [data, setData] = useState({
    fProfileAssign: {
      fName: ''
    },
    fProfileCreator: {
      fName: ''
    }
  });

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [loading, setLoading] = useState(false);

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    getDetailTask();
  }, [user]);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID, page: 1, limit: 3, type: 'PM'
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(new Date(response.data.data[0].fCheckInDateTime));
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate('/')
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate('/')
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getDetailTask = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getdetailtask`,
        { fID: fID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data);
    } catch (error) {}
  };

  const handleProcessButton = async () => {
    setLoading(true)
    try {
      const response = await axiosJWT.post(
        `${API_URL}/updatestatustask`,
        { fID: fID, fStatus: 'ONPROGRESS' },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1)
    } catch (error) {}
  }

  const handleDoneButton = async () => {
    setLoading(true)
    try {
      const response = await axiosJWT.post(
        `${API_URL}/updatestatustask`,
        { fID: fID, fStatus: 'COMPLETED' },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1)
    } catch (error) {}
  }

  const handleEditButton = () => {
    navigate("/edittask", {
      state: { data: data },
    });
  }

  const handleCancelButton = async () => {
    setLoading(true)
    try {
      const response = await axiosJWT.post(
        `${API_URL}/updatestatustask`,
        { fID: fID, fStatus: 'CANCELED' },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1)
    } catch (error) {}
  }

  const renderTextWithLineBreaks = (text) => {
    return (text || '').split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "135px",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: tasklogo,
          title: "Detail Task",
          name: user.fName,
          job: user.fRoleAlias,
        }}
      />

      <div className="AddNotulenContainer">
      <div style={{ padding: '5px 25px', color: '#D0A360', borderRadius: 100, border: '3px solid #D0A360', fontWeight: 'bold', boxSizing: 'border-box', width: '95%', marginBottom: '25px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>{data.fStatus}</div>

        <div className="AddNotulenInput">
          <text style={{ fontWeight: 'bold' }}>Nama Task</text>
          <text>{data.fTaskName}</text>
        </div>

        <div className="AddNotulenInput">
          <text>Deadline</text>
          <text>{dayjs(data.fTaskDeadline).format("DD MMM YYYY").toString()}</text>
        </div>

        <div className="AddNotulenInput">
          <text style={{ fontWeight: 'bold' }}>Project</text>
          <text>{data.fProjectName}</text>
        </div>

        <div className="AddNotulenInput">
          <text style={{ fontWeight: 'bold' }}>Created by</text>
          <text>{data.fProfileCreator.fName}</text>
        </div>

        <div className="AddNotulenInput">
          <text style={{ fontWeight: 'bold' }}>Assign To</text>
          <text>{data.fProfileAssign.fName}</text>
        </div>

        <div className="AddNotulenInput">
          <text style={{ fontWeight: 'bold' }}>Keterangan</text>
          <text>{renderTextWithLineBreaks(data.fKet)}</text>
        </div>

        {(data.fUserID === user.fID) && data.fStatus === "NEW" && (
          <div style={{ display: "flex", width: "95%" }}>
            <div
              onClick={() => {
                if(!loading){
                  handleProcessButton();
                }
              }}
              className="AddProgressButton"
              style={{ opacity: loading ? '0.7' : '1' }}
            >
            {loading && <span>Loading...</span>}
            {!loading && <span>Proses</span>}
            </div>
          </div>
        )}

        {(data.fUserID === user.fID) && data.fStatus === "ONPROGRESS" && (
          <div style={{ display: "flex", width: "95%" }}>
            <div
              onClick={() => {
                if(!loading){
                  handleDoneButton();
                }
              }}
              className="AddProgressButton"
              style={{ opacity: loading ? '0.7' : '1' }}
            >
            {loading && <span>Loading...</span>}
            {!loading && <span>Done</span>}
            </div>
          </div>
        )}

        {(data.fCreatedByID === user.fID) &&
          data.fStatus === "NEW" && (
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                margin: "0 auto",
                alignItems: "center",
                gap: "15px",
                color: "white",
                opacity: loading ? '0.7' : '1'
              }}
            >
              <div
                onClick={() => {
                  if(!loading){
                    handleEditButton();
                  }
                }}
                style={{
                  width: "95%",
                  backgroundColor: "#D0A360",
                  padding: "5px 0",
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: 5,
                  opacity: loading ? '0.7' : '1'
                }}
              >
            {loading && <span>Loading...</span>}
            {!loading && <span>Edit</span>}
              </div>

              <div
                onClick={() => {
                  if(!loading){
                    handleCancelButton();
                  }
                }}
                style={{
                  width: "95%",
                  backgroundColor: "red",
                  padding: "5px 0",
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: 5,
                  opacity: loading ? '0.7' : '1'
                }}
              >
            {loading && <span>Loading...</span>}
            {!loading && <span>Cancel</span>}
              </div>
            </div>
          )}

        <div style={{ height: "30px", width: '1px' }} />
      </div>
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default DetailTask;
