import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";
import ProgressSPV from "../../Components/ProgressSPV/ProgressSPV";
import progress from "../../assets/progressspv.png";
import profile from "../../assets/profile.png";
import Dropdown from "../../Components/Dropdown/Dropdown";
import imagePicker from "../../assets/image.png";
import { DatePicker } from "@mui/x-date-pickers";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers/MultiSectionDigitalClock";
import { TimeField } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import bin from "../../assets/bin.png";

import "./AddProgressSPV.css";
import "@fontsource/poppins";

import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";
require("dayjs/locale/de");

const UpdateProgressSPV = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const inputRef = useRef(null);

  const { state } = useLocation();
  const { data } = state;
  const [dateTime, setDateTime] = useState(dayjs(data.fDateTime));
  const [images, setImages] = useState(data.fImages);
  const [ket, setKet] = useState(data.fKet);
  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [project, setProject] = useState([]);
  const [selectedProject, setSelectedProject] = useState(
    data.fProject.fProjectName
  );
  const [selectedProjectData, setSelectedProjectData] = useState(data.fProject);

  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    getAllProject();
  }, [user]);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID, page: 1, limit: 3, type: 'PM'
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(new Date(response.data.data[0].fCheckInDateTime));
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate('/')
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate('/')
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      if(response.data.user[0].fRole !== 'SPV'){
        navigate('/')
      }
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllProject = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getprojectdropdown`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProject(response.data);
    } catch (error) {}
  };
  const navigate = useNavigate();

  const handleImageClick = () => {
    inputRef.current.click();
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImages([file, ...images]);
    }
  };

  const validationForm = (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (images.length === 0) {
      validationErrors.image = "pilih minimal 1 gambar";
    }

    if (!ket.trim()) {
      validationErrors.ket = "harus diisi";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0 && !loading) {
      handleSaveButton();
    }
  };

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  const handleSaveButton = async () => {
    setLoading(true)

    try {
      let tempImages = [];
      for (let i = 0; i < images.length; i++) {
        if (typeof images[i].filename !== "string") {
          const base64File = await blobToBase64(images[i]);
          tempImages.push({
            file: base64File,
            ext: getFileExtension(images[i].name),
            size: images[i].size,
            type: images[i].type
          });
        }else{
          tempImages.push(images[i]);
        }
      }

      const bodyData = {
        fID: data.fID,
        fProjectID: selectedProjectData.fID,
        fDateTime: dateTime,
        fImages: tempImages,
        fKet: ket
      }

      await axiosJWT.post(
        `${API_URL}/updateprogressspv`,
        bodyData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1);
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  };

  const removeImage = (indexToRemove) => {
    setImages((prevImages) =>
      prevImages.filter((image, index) => index !== indexToRemove)
    );
  };

  const handleImageDelete = (i) => {
    removeImage(i);
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "135px",
        fontSize: "16px",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: progress,
          title: "Update Progress SPV",
          name: user.fName,
          job: user.fRoleAlias,
        }}
      />

      <div className="AddProgressSPVContainer">
        <div className="AddProgressInput">
          <text>Project</text>
          <Dropdown
            selected={selectedProject}
            setSelected={setSelectedProject}
            setSelectedData={setSelectedProjectData}
            options={project}
            type={"fProjectName"}
          />
        </div>

        <div className="AddProgressInput">
          <text>Tanggal</text>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ width: "50%" }}>
              <DatePicker
              format="DD/MM/YYYY"
                value={dateTime}
                disabled
                onChange={(v) => {
                  setDateTime(v);
                }}
              />
            </div>
          </LocalizationProvider>
        </div>

        <div className="AddProgressInput">
          <text>Jam</text>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ width: "50%" }}>
              <MultiSectionDigitalClock
                skipDisabled
                disableFuture={true}
                value={dateTime}
                onChange={(v) => {
                  setDateTime(v);
                }}
                shouldDisableTime={false}
                ampm={false}
              />
            </div>
          </LocalizationProvider>
        </div>

        <div className="AddProgressInput">
          <text>{"Foto progress (Maks 3)"}{errors.image && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.image}
              </span>
            )}</text>
          {images.length < 3 && (
            <div onClick={handleImageClick}>
              <div
                className="DetailProgressImage"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px 10px",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <img
                  style={{ width: "27px", height: "27px" }}
                  src={imagePicker}
                  alt=""
                />
                <text style={{ textAlign: "center" }}>
                  Drop your image or{" "}
                  <span style={{ color: "#818CF8" }}>click to browse</span>
                </text>
              </div>
              <input
                type="file"
                accept="image/*"
                ref={inputRef}
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </div>
          )}

          <div
            style={{
              width: "95%",
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            {images.map((d, i) => {
              return (
                <div className="AddProgressImageContainer">
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                      margin: "0px",
                    }}
                    src={
                      typeof d.filename !== "string"
                        ? URL.createObjectURL(d)
                        : `${API_URL}/files/${d.filename}`
                    }
                    alt=""
                  />
                  <div
                    onClick={() => {
                      handleImageDelete(i);
                    }}
                    style={{ borderRadius: 3 }}
                    className="AddProgressImageDeleteButton"
                  >
                    Delete
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="AddProgressInput">
          <text>Keterangan{errors.ket && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.ket}
              </span>
            )}</text>
          <div className="AddProgressInputNotes">
            <textarea
              style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
              minLength={150}
              value={ket}
              onChange={(v) => {
                setKet(v.target.value);
              }}
              className="AddProgressInputNote"
              type="text"
              placeholder="Keterangan"
            ></textarea>
          </div>
        </div>

        <div style={{ display: "flex", width: "95%" }}>
          <div
            onClick={validationForm}
            className="AddProgressButton"
            style={{ opacity: loading ? '0.7' : '1' }}
          >
            {loading && <span>Loading...</span>}
            {!loading && <span>Update</span>}
          </div>
        </div>
      </div>
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default UpdateProgressSPV;
