import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";
import pengajuanlogo from "../../assets/pengajuan.png";
import closeicon from "../../assets/closered.png";
import profile from "../../assets/profile.png";
import { Checkbox } from "@mui/material";

import "./DetailPengajuan.css";
import "@fontsource/poppins";

import "bootstrap/dist/css/bootstrap.min.css";

import { useSpring, animated } from "react-spring";

import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";

const DetailPengajuan = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const AnimatedDiv = animated.div;

  const [modalProgress, setModalProgress] = useState(false);
  const [checked, setChecked] = useState(true);
  const { state } = useLocation();
  const { fID } = state;
  const [index, setIndex] = useState(0);
  const [update, setUpdate] = useState(false);
  const [data, setData] = useState({
    fID: "",
    fProjectID: "",
    fPengajuanName: "",
    fDateTime: new Date(),
    fKet: "",
    fPengajuanItem: [],
    fSumStatus: {
      new: 0,
      checked: 0,
      rejected: 0,
      purchased: 0,
    },
    fProject: {
      fProjectName: "",
      fImages: [
        {
          filename: "",
        },
      ],
    },
    fProfile: {
      fName: "",
      fImages: "",
    },
  });

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    getPengajuan();
  }, [user, update]);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getPengajuan = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getdetailpengajuan`,
        { fID: fID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data);
    } catch (error) {}
  };

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  modalProgress
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleClickAllProgress = () => {
    setModalProgress(true);
  };

  const handleClickAddSuratJalan = () => {
    navigate("/addsuratjalan", {
      state: { data: data },
    });
  };

  const renderTextWithLineBreaks = (text) => {
    return (text || "").split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const ModalAllProgress = () => {
    return (
      <div
        className="AddPengajuanCenterModal"
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          zIndex: "10000000",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "1024px",
        }}
      >
        <div
          style={{
            width: "95%",
            height: "85%",
            backgroundColor: "white",
            borderRadius: "12px",
            boxSizing: "border-box",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            fontSize: "16px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
            position: "relative",
          }}
        >
          <text
            style={{
              color: "#D0A360",
              fontSize: "20px",
              fontWeight: "bold",
              marginTop: "15px",
            }}
          >
            All Progress
          </text>

          <div
            style={{
              width: "50%",
              height: "3px",
              borderRadius: "100",
              backgroundColor: "#D0A360",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          />

          <div
            style={{
              width: "100%",
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <div style={{ fontSize: "16px", marginBottom: "20px" }}>
              <text style={{ fontWeight: "bold" }}>
                New{" "}
                {`(${
                  data.fPengajuanItem.filter(
                    (item) => item.fStatus[0].fStatus === "NEW"
                  ).length
                })`}
              </text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  marginTop: "10px",
                }}
              >
                {data.fPengajuanItem
                  .filter((item) => item.fStatus[0].fStatus === "NEW")
                  .map((d) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          gap: "15px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "stretch",
                            borderRadius: 5,
                          }}
                          src={`${API_URL}/files/${d.fItem.fItemImage.filename}`}
                          alt=""
                        />
                        <div className="AddSuratJalanItemInputJumlah">
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <text style={{ fontWeight: "bold" }}>Nama</text>
                            <text>{d.fItem.fItemName}</text>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div style={{ fontSize: "16px", marginBottom: "20px" }}>
              <text style={{ fontWeight: "bold" }}>
                Approved{" "}
                {`(${
                  data.fPengajuanItem.filter(
                    (item) => item.fStatus[0].fStatus === "CHECKED"
                  ).length
                })`}
              </text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  marginTop: "10px",
                }}
              >
                {data.fPengajuanItem
                  .filter((item) => item.fStatus[0].fStatus === "CHECKED")
                  .map((d) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          gap: "15px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "stretch",
                            borderRadius: 5,
                          }}
                          src={`${API_URL}/files/${d.fItem.fItemImage.filename}`}
                          alt=""
                        />
                        <div className="AddSuratJalanItemInputJumlah">
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <text style={{ fontWeight: "bold" }}>Nama</text>
                            <text>{d.fItem.fItemName}</text>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div style={{ fontSize: "16px", marginBottom: "20px" }}>
              <text style={{ fontWeight: "bold" }}>
                Rejected{" "}
                {`(${
                  data.fPengajuanItem.filter(
                    (item) => item.fStatus[0].fStatus === "REJECTED"
                  ).length
                })`}
              </text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  marginTop: "10px",
                }}
              >
                {data.fPengajuanItem
                  .filter((item) => item.fStatus[0].fStatus === "REJECTED")
                  .map((d) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          gap: "15px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "stretch",
                            borderRadius: 5,
                          }}
                          src={`${API_URL}/files/${d.fItem.fItemImage.filename}`}
                          alt=""
                        />
                        <div className="AddSuratJalanItemInputJumlah">
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <text style={{ fontWeight: "bold" }}>Nama</text>
                            <text>{d.fItem.fItemName}</text>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div style={{ fontSize: "16px", marginBottom: "20px" }}>
              <text style={{ fontWeight: "bold" }}>
                Purchased{" "}
                {`(${
                  data.fPengajuanItem.filter(
                    (item) => item.fStatus[0].fStatus === "PURCHASED"
                  ).length
                })`}
              </text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  marginTop: "10px",
                }}
              >
                {data.fPengajuanItem
                  .filter((item) => item.fStatus[0].fStatus === "PURCHASED")
                  .map((d) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          gap: "15px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "stretch",
                            borderRadius: 5,
                          }}
                          src={`${API_URL}/files/${d.fItem.fItemImage.filename}`}
                          alt=""
                        />
                        <div className="AddSuratJalanItemInputJumlah">
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <text style={{ fontWeight: "bold" }}>Nama</text>
                            <text>{d.fItem.fItemName}</text>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div style={{ fontSize: "16px", marginBottom: "20px" }}>
              <text style={{ fontWeight: "bold" }}>
                Completed{" "}
                {`(${
                  data.fPengajuanItem.filter(
                    (item) => item.fStatus[0].fStatus === "COMPLETED"
                  ).length
                })`}
              </text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  marginTop: "10px",
                }}
              >
                {data.fPengajuanItem
                  .filter((item) => item.fStatus[0].fStatus === "COMPLETED")
                  .map((d) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          gap: "15px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "stretch",
                            borderRadius: 5,
                          }}
                          src={`${API_URL}/files/${d.fItem.fItemImage.filename}`}
                          alt=""
                        />
                        <div className="AddSuratJalanItemInputJumlah">
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <text style={{ fontWeight: "bold" }}>Nama</text>
                            <text>{d.fItem.fItemName}</text>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <img
            onClick={() => {
              setModalProgress(false);
            }}
            style={{
              width: "30px",
              height: "30px",
              position: "absolute",
              top: "15px",
              right: "15px",
            }}
            src={closeicon}
            alt=""
          />
        </div>
      </div>
    );
  };

  const ItemCard = ({ d, i }) => {
    const [checked, setChecked] = useState(
      d.fStatus[0].fStatus === "REJECTED" ? false : true
    );
    const [ket, setKet] = useState("");
    const [qty, setQty] = useState(d.fQuantity);
    
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)

    const validationForm = (e) => {
      e.preventDefault();
      const validationErrors = {};
      if(!ket.trim()){
        validationErrors.ket = 'harus diisi'
      }
      if(!qty){
        validationErrors.qty = 'harus diisi'
      }

      setErrors(validationErrors)

      if (Object.keys(validationErrors).length === 0 && !loading) {
        handleClickSave();
      }
    }

    const handleClickSave = async () => {
      setLoading(true)

      try {
        var bodyFormData = new FormData();
        bodyFormData.append("fProjectID", data.fProject.fID);
        bodyFormData.append("fPengajuanID", data.fID);
        bodyFormData.append("fPengajuanxItemID", d.fID);
        bodyFormData.append("fQuantity", qty);
        bodyFormData.append("fKetApproval", ket);
        bodyFormData.append("fSuratJalanQuantity", 0);
  
        if (checked) {
          bodyFormData.append("fStatus", "CHECKED");
        } else {
          bodyFormData.append("fStatus", "REJECTED");
        }

        const response = await axiosJWT.post(
          `${API_URL}/checkitempengajuan`,
          bodyFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUpdate(!update);
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    };
    return (
      <div className="DetailPengajuanListItemSuratJalanWrapper">
        {i !== 0 && (
          <div
            style={{
              height: "3px",
              width: "95%",
              backgroundColor: "#B6B6B6",
              margin: "20px auto",
            }}
          />
        )}
        <div className="DetailPengajuanListItemWrapper">
          <img
            style={{ width: "120px", height: "120px" }}
            src={`${API_URL}/files/${d.fItem.fItemImage.filename}`}
            alt=""
          />
          <div className="DetailPengajuanTextTitleNumberContainer">
            <div className="DetailPengajuanTextTitleNumberWrapper">
              <text style={{ fontWeight: "bold" }}>Nama Barang</text>
              <div />
              <text>{d.fItem.fItemName}</text>
            </div>
            <div className="DetailPengajuanTotalSampaiContainer">
              <div className="DetailPengajuanTextTitleNumberWrapperTotalSampai">
                <text style={{ fontWeight: "bold" }}>Total</text>
                {errors.qty && <span style={{ color: 'red', fontSize: '14px', fontWeight: 'normal' }}> *{errors.qty}</span>}
                <div />
                {(user.fRole === "PM" || user.fRole === "BOSS") &&
                d.fStatus[0].fStatus === "NEW" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-end",
                      gap: "10px",
                    }}
                  >
                    <input
                      value={qty}
                      onChange={(v) => {
                        setQty(v.target.value);
                      }}
                      style={{
                        fontSize: "16px",
                        width: "50%",
                        padding: "10px 5px",
                        boxSizing: "border-box",
                        borderRadius: 7,
                        border: "1px solid rgba(0, 0, 0, 0.2)",
                        textAlign: "center",
                      }}
                      type="text"
                      placeholder="0"
                    />
                    <text>{d.fDenom}</text>
                  </div>
                ) : (
                  <text>
                    {qty} {d.fDenom}
                  </text>
                )}
              </div>

              {d.fStatus[0].fStatus !== "NEW" &&
                d.fStatus[0].fStatus !== "REJECTED" && (
                  <div className="DetailPengajuanTextTitleNumberWrapperTotalSampai">
                    <text style={{ fontWeight: "bold" }}>Sampai</text>
                    <div />
                    <text>
                      {d.fArrived} {d.fDenom}
                    </text>
                  </div>
                )}
            </div>
          </div>
        </div>

        <div
          style={{
            fontSize: "16px",
            width: "95%",
            margin: "10px auto",
          }}
        >
          <text style={{ fontWeight: "bold" }}>Keterangan</text>
          <br />
          <text>{renderTextWithLineBreaks(d.fKet)}</text>
        </div>

        <div className="DetailPengajuanItemApprovalWrapper">
          <text>Approved?</text>
          <Checkbox
            disabled={
              (user.fRole === "BOSS" || user.fRole === "PM") &&
              d.fStatus[0].fStatus === "NEW"
                ? false
                : true
            }
            checked={checked}
            onChange={(v) => {
              setChecked(v.target.checked);
            }}
          />
        </div>

        {(user.fRole === "BOSS" || user.fRole === "PM") &&
          d.fStatus[0].fStatus === "NEW" && (
            <div
              style={{
                fontSize: "16px",
                width: "95%",
                margin: "10px auto",
              }}
            >
              <text style={{ fontWeight: "bold" }}>
                Keterangan Approve/Reject{errors.ket && <span style={{ color: 'red', fontSize: '14px', fontWeight: 'normal' }}> *{errors.ket}</span>}
              </text>
              <br />
              <div
                style={{ marginTop: "10px" }}
                className="AddPengajuanInputNotesItem"
              >
                <textarea
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    lineHeight: 1.6,
                  }}
                  minLength={150}
                  onChange={(v) => {
                    setKet(v.target.value);
                  }}
                  className="AddPengajuanInputNote"
                  type="text"
                  placeholder="Keterangan"
                >
                  {ket}
                </textarea>
              </div>

              <div
                onClick={validationForm}
                style={{
                  width: "100%",
                  backgroundColor: "#D0A360",
                  color: "white",
                  padding: "10px 0",
                  boxSizing: "border-box",
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: 5,
                  fontWeight: "bold",
                  opacity: loading ? "0.7" : "1",
                }}
              >
                {loading && <span>Loading...</span>}
                {!loading && <span>Simpan</span>}
              </div>
            </div>
          )}

        {d.fStatus[0].fStatus !== "NEW" && (
          <div
            style={{
              fontSize: "16px",
              width: "95%",
              margin: "10px auto",
            }}
          >
            <text style={{ fontWeight: "bold", marginBottom: "15px" }}>
              Keterangan Approve/Reject
            </text>
            <br />
            <text>{renderTextWithLineBreaks(d.fKetApproval)}</text>
          </div>
        )}

        <div className="DetailPengajuanSuratJalanContainer">
          {d.fSuratJalanItem.map((v, i) => {
            d.fsumArrived += v.fSuratJalanQuantity;
            return (
              <div className="DetailPengajuanSuratJalanWrapper">
                {i !== 0 && (
                  <div
                    style={{
                      height: "4px",
                      width: "80%",
                      backgroundColor: "#4888C3",
                      borderRadius: 100,
                      margin: "20px auto",
                    }}
                  />
                )}
                <div className="DetailPengajuanSuratJalanValueWrapper">
                  <text className="DetailPengajuanSuratJalanTitle">
                    Tanggal
                  </text>
                  <div />
                  <text className="DetailPengajuanSuratJalanValue">
                    {dayjs(v.fDateTime).format("DD MMM YYYY").toString()}
                  </text>
                </div>

                <div className="DetailPengajuanSuratJalanValueWrapper">
                  <text className="DetailPengajuanSuratJalanTitle">Nama</text>
                  <div />
                  <text className="DetailPengajuanSuratJalanValue">
                    {v.fSuratJalan.fPOName}
                  </text>
                </div>

                <div className="DetailPengajuanSuratJalanValueWrapper">
                  <text className="DetailPengajuanSuratJalanTitle">
                    No PreOrder
                  </text>
                  <div />
                  <text className="DetailPengajuanSuratJalanValue">
                    {v.fSuratJalan.fPONo}
                  </text>
                </div>

                <div className="DetailPengajuanSuratJalanValueWrapper">
                  <text className="DetailPengajuanSuratJalanTitle">Jumlah</text>
                  <div />
                  <text className="DetailPengajuanSuratJalanValue">
                    {v.fQuantity}
                  </text>
                </div>

                <div className="DetailPengajuanSuratJalanValueWrapper">
                  <text className="DetailPengajuanSuratJalanTitle">
                    Keterangan
                  </text>
                  <div />
                  <text className="DetailPengajuanSuratJalanValue">
                    {renderTextWithLineBreaks(v.fSuratJalan.fSuratJalanKet)}
                  </text>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "145px",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: pengajuanlogo,
          title: "Detail Pengajuan",
          name: user.fName,
          job: user.fRoleAlias,
          type: "detail",
        }}
      />

      {user.fRole !== "CLIENT" && (
        <div
          onClick={handleClickAllProgress}
          style={{
            width: "95%",
            backgroundColor: "#184476",
            margin: "10px auto",
            padding: "10px 0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxSizing: "border-box",
            color: "white",
            fontWeight: "bold",
            borderRadius: 5,
            maxWidth: "972.8px",
          }}
        >
          Lihat Semua Progress
        </div>
      )}

      {user.fRole !== "CLIENT" && (
        <div
          onClick={handleClickAddSuratJalan}
          style={{
            width: "95%",
            backgroundColor: "#D0A360",
            margin: "10px auto",
            padding: "10px 0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxSizing: "border-box",
            color: "white",
            fontWeight: "bold",
            borderRadius: 5,
            maxWidth: "972.8px",
          }}
        >
          Add Pre-Order
        </div>
      )}

      <div className="DetailPengajuanContainer">
        <text
          style={{
            color: "#D0A360",
            fontWeight: "bold",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          {data.fPengajuanName}
        </text>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "90%",
            fontSize: "16px",
            alignItems: "flex-start",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
              fontSize: "16px",
            }}
          >
            <img
              style={{ width: "50px", height: "50px", borderRadius: 100 }}
              src={`${API_URL}/files/${data.fProfile.fImages}`}
              alt=""
            />
            <text>{data.fProfile.fName}</text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "15px",
              alignItems: "flex-end",
            }}
          >
            <text>
              {dayjs(data.fDateTime).format("DD MMM YYYY").toString()}
            </text>
            {/* <div
              style={{
                padding: "0 25px",
                color: "#D0A360",
                borderRadius: 100,
                border: "2px solid #D0A360",
                fontWeight: "bold",
                boxSizing: "border-box",
              }}
            >
              {data.fStatus[0].fName}
            </div> */}
          </div>
        </div>

        <img
          style={{
            width: "90%",
            aspectRatio: "743/443",
            objectFit: "cover",
            margin: "5px 0",
          }}
          src={`${API_URL}/files/${data.fProject.fImages[0].filename}`}
          alt=""
        />
        <div
          style={{
            width: "90%",
            fontSize: "16px",
            marginTop: "10px",
            fontWeight: "bold",
          }}
        >
          Keterangan
        </div>
        <text style={{ width: "90%", fontSize: "16px", textAlign: "justify" }}>
          {renderTextWithLineBreaks(data.fKet)}
        </text>

        <div
          style={{
            width: "90%",
            fontSize: "16px",
            marginTop: "10px",
            fontWeight: "bold",
          }}
        >
          Pengajuan
        </div>

        <div className="DetailPengajuanListItemContainer">
          {data.fPengajuanItem.map((d, index) => {
            return <ItemCard d={d} i={index} />;
          })}
        </div>

        <div style={{ height: "30px" }} />
      </div>
      {modalProgress && <ModalAllProgress />}
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default DetailPengajuan;
