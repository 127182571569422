import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import kasbonlogo from "../../assets/kasbon.png";

import "@fontsource/poppins";

import "./ViewAllAbsensi.css";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import AbsensiCard from "../../Components/AbsensiCard/AbsensiCard";

import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ModalDatePicker from "../../Components/Modal/ModalDatePicker";

import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const ViewAllAbsensi = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const handleChangePage = (event, value) => {
    setPage(value);
    setRefresh(!refresh);
  };

  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [absensi, setAbsensi] = useState([]);

  const [buttonPM, setButtonPM] = useState(true);
  const [refresh, setRefresh] = useState(true);

  const [startDate, setStartDate] = useState(
    dayjs(new Date().setHours(0, 0, 0, 0))
  );
  const [endDate, setEndDate] = useState(
    dayjs(new Date().setHours(23, 59, 59, 999))
  );

  const [openModalDatePicker, setOpenModalDatePicker] = useState(false);

  openModalDatePicker
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  const buttonStyle = {
    on: {
      backgroundColor: "#D0A360",
      borderRadius: 5,
      color: "white",
      fontWeight: "bold",
      border: "2px solid white",
    },
    off: {
      backgroundColor: "white",
      color: "#D0A360",
      borderRadius: 5,
      fontWeight: "bold",
      border: "2px solid #D0A360",
    },
  };

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    if(startDate < endDate){
      getAllAbsensi();
    }
  }, [user, page, refresh, startDate, endDate]);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
          startDate,
          endDate
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllAbsensi = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: user.fID,
          page: page,
          limit: 9,
          type: buttonPM ? "PM" : "SPV",
          startDate: startDate,
          endDate: endDate
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAbsensi(response.data.data);
      setTotalPage(response.data.meta.totalPages);
    } catch (error) {}
  };

  const onClickButtonPM = () => {
    if (buttonPM === false) {
      setButtonPM(true);
      setPage(1);
      setRefresh(!refresh);
    }
  };

  const onClickButtonSPV = () => {
    if (buttonPM === true) {
      setButtonPM(false);
      setPage(1);
      setRefresh(!refresh);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "150px",
        paddingBottom: "25px",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      <Navbar profileData={user} />
      {/* Navbar Extension Manual */}
      <div
        style={{
          backgroundColor: "#F9FAFC",
          width: "100vw",
          position: "fixed",
          top: "55px",
          zIndex: 9991,
        }}
      >
        <div className="NavExtensionContainer">
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src={kasbonlogo} alt="" className="NavExtensionIcon" />
            <text className="NavExtensionText">Semua Absensi</text>
          </div>
        </div>
      </div>

      {user.fRole === "PM" && (
        <div style={{ marginTop: "20px" }} className="AllNotulenContainer">
          <div
            onClick={onClickButtonPM}
            className="FilterButton"
            style={buttonPM ? buttonStyle.on : buttonStyle.off}
          >
            PM
          </div>

          <div
            onClick={onClickButtonSPV}
            className="FilterButton"
            style={buttonPM ? buttonStyle.off : buttonStyle.on}
          >
            SPV
          </div>
        </div>
      )}

      {(user.fRole === "BOSS" ||
        user.fRole === "PM" ||
        user.fRole === "HRD") && (
        <div style={{ marginTop: "20px" }} className="AllNotulenContainer">
          <div
            onClick={() => {
              setOpenModalDatePicker(!openModalDatePicker);
            }}
            className="FilterButton"
            style={{
              width: "100%",
              backgroundColor: "#00AA86",
              color: "white",
              borderRadius: 5,
            }}
          >
            Rekap
          </div>
        </div>
      )}

      <div className="AllNotulenContainer">
        <div style={{ backgroundColor: "white" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ width: "300px" }}>
              <DatePicker
              slotProps={{ textField: { fullWidth: true } }}
                format="DD/MM/YYYY"
                value={startDate}
                defaultValue={startDate}
                disableFuture={true}
                onChange={(v) => {
                  setStartDate(v);
                }}
                DialogProps={{
                  style: { zIndex: 1350 },
                }}
              />
            </div>
          </LocalizationProvider>
        </div>

        <text className="textAbsensiSampai">Sampai</text>
        <div style={{ backgroundColor: "white" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ width: "300px" }}>
              <DatePicker
                slotProps={{ textField: { fullWidth: true } }}
                format="DD/MM/YYYY"
                value={endDate}
                minDate={startDate}
                defaultValue={endDate}
                disableFuture={true}
                onChange={(v) => {
                  setEndDate(v);
                }}
                DialogProps={{
                  style: { zIndex: 1350 },
                }}
              />
            </div>
          </LocalizationProvider>
        </div>
      </div>

      <div className="AllNotulenContainer">
        {absensi.map((d) => {
          return <AbsensiCard data={d} />;
        })}
      </div>

      <div style={{ height: "120px", width: "50px" }} />

      <Stack
        style={{
          width: "100%",
          maxWidth: "1024px",
          position: "absolute",
          bottom: "50px",
          left: 0,
          right: 0,
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "16px",
        }}
      >
        <Pagination count={totalPage} page={page} onChange={handleChangePage} />
      </Stack>

      {openModalDatePicker && (
        <ModalDatePicker closeModalDatePicker={setOpenModalDatePicker} />
      )}
    </div>
  );
};

export default ViewAllAbsensi;
