import file from "../../assets/file.png";
import bin from "../../assets/bin.png";
import DownloadCard from "../../Components/DownloadCard/DownloadCard";
import { useState } from "react";

const SingleFileUploadComponent = ({ data, setData, compRef, typeFile }) => {
    const [warning, setWarning] = useState(false);

  const handleFileClick = () => {
    compRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    let stat = false;
    if(file.size > 26214400){
        setWarning(true);
        stat = true;
    }else{
        setWarning(false);
    }
    if (file && !stat) {
      setData(file);
    }
  };

  const handleFileDelete = () => {
    setData("");
  };

  return (
    <div style={{ width: '100%', maxWidth: '1024px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
      <div className="AddNotulenInput">
        <text>{typeFile}
        {
            warning && <span style={{ color: 'red' }}> Maks size 25MB</span>
        }</text>
        {data === "" && (
          <div>
            <div
              onClick={handleFileClick}
              className="AddNotulenFile"
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "20px 10px",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <img
                style={{ width: "27px", height: "27px" }}
                src={file}
                alt=""
              />
              <text style={{ textAlign: "center" }}>
                <span style={{ color: "#818CF8" }}>click to browse</span>
              </text>
            </div>
            <input
              type="file"
              ref={compRef}
              accept="image/*, .pdf, .xlsx, .docx"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
        )}
      </div>
      {data !== "" && (
        <div
          style={{
            width: "95%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            marginBottom: "25px",
          }}
        >
          <DownloadCard file={data} typeFile={typeFile} />
          <img
            onClick={() => {
              handleFileDelete();
            }}
            style={{ width: "20px", height: "20px", marginLeft: "20px" }}
            src={bin}
            alt=""
          />
        </div>
      )}
    </div>
  );
};

export default SingleFileUploadComponent;
