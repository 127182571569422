import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import TaskCard from "../../Components/TaskCard/TaskCard";
import tasklogo from "../../assets/task.png";

import "./ViewAllTask.css";
import "@fontsource/poppins";

import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Dropdown from "../../Components/Dropdown/Dropdown";

const ViewAllTask = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [task, setTask] = useState([]);

  const [buttonTask, setButtonTask] = useState(true);

  const optionType = [
    { name: "NEW" },
    { name: "ON-PROGRESS" },
    { name: "COMPLETED" },
  ];
  const [selectedTypeData, setSelectedTypeData] = useState({ name: "NEW" });
  const [type, setType] = useState("NEW");

  const buttonStyle = {
    on: {
      backgroundColor: "#D0A360",
      borderRadius: 5,
      color: "white",
      fontWeight: "bold",
      border: "2px solid white",
    },
    off: {
      backgroundColor: "white",
      color: "#D0A360",
      borderRadius: 5,
      fontWeight: "bold",
      border: "2px solid #D0A360",
    },
  };

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    getAllTask();
  }, [user, type, page, buttonTask]);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllTask = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getalltask`,
        {
          fID: user.fID,
          page: page,
          limit: 9,
          type: buttonTask ? "Task" : "Assigned",
          status: type
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTask(response.data.data);
      setTotalPage(response.data.meta.totalPages);
    } catch (error) {}
  };

  const onClickButtonTask = () => {
    if (buttonTask === false) {
      setButtonTask(true);
      setPage(1);
    }
  };

  const onClickButtonAssigned = () => {
    if (buttonTask === true) {
      setButtonTask(false);
      setPage(1);
    }
  };

  const handleAddClick = () => {
    navigate("/addtask");
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "195px",
        paddingBottom: "25px",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      <Navbar profileData={user} />
      {/* Navbar Extension Manual */}
      <div
        style={{
          backgroundColor: "#F9FAFC",
          width: "100vw",
          position: "fixed",
          top: "55px",
          zIndex: 9991,
        }}
      >
        <div className="NavExtensionContainer">
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src={tasklogo} alt="" className="NavExtensionIcon" />
            <text className="NavExtensionText">Semua Task</text>
          </div>

          {(user.fRole === "BOSS" || user.fRole === "PM") && (
            <div
              onClick={() => {
                handleAddClick();
              }}
              style={{
                width: "100%",
                marginTop: "15px",
                backgroundColor: "#D0A360",
                color: "white",
                padding: "10px 0",
                display: "flex",
                flexDirection: "column",
                fontWeight: "bold",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 7,
              }}
            >
              Add Task
            </div>
          )}
        </div>
      </div>

      {user.fRole === "PM" && (
        <div style={{ marginTop: "20px" }} className="AllNotulenContainer">
          <div
            onClick={onClickButtonTask}
            className="FilterButton"
            style={buttonTask ? buttonStyle.on : buttonStyle.off}
          >
            Task
          </div>

          <div
            onClick={onClickButtonAssigned}
            className="FilterButton"
            style={buttonTask ? buttonStyle.off : buttonStyle.on}
          >
            Assigned
          </div>
        </div>
      )}

      <div style={{ marginTop: '20px' }}  className="AllNotulenContainer">
        <Dropdown
          selected={type}
          setSelected={setType}
          setSelectedData={setSelectedTypeData}
          options={optionType}
          type={"name"}
        />
      </div>

      <div className="AllTaskContainer">
        {task.map((d) => {
          return <TaskCard data={d} />;
        })}
      </div>

      <div style={{ height: "120px", width: "50px" }} />

      <Stack
        style={{
          width: "100%",
          maxWidth: "1024px",
          position: "absolute",
          bottom: "50px",
          left: 0,
          right: 0,
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "16px",
        }}
      >
        <Pagination count={totalPage} page={page} onChange={handleChangePage} />
      </Stack>
    </div>
  );
};

export default ViewAllTask;
