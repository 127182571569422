import "./LaporanProject.css";
import { useSpring, animated } from "react-spring";
import profile from '../../assets/profile.png';

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const LaporanProject = ({ data, user, token }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const AnimatedDiv = animated.div;

  const progress = data.fProgress.length === 0 ? 0 : data.fProgress[0].fProgressPercentage

  const gaugeProgress = useSpring({
    value: progress,
    from: { value: 0 },
    config: {
      duration: 500,
    },
  });

  const handleClickProject = (v) => {
    navigate('/detailproject', { state: { fID: data.fID }});
  }

  return (
    <div onClick={() => handleClickProject(data)} className="Project_Container">
      <text style={{ fontSize: "14px" }}>{dayjs(data.fCreatedDate).format('DD MMM YYYY').toString()}</text>
      <div
        className="Progress-Container"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
        }}
      >
        <div style={{ width: "42px" }}>
          <AnimatedDiv
            style={{ fontSize: "16px", lineHeight: 1, fontWeight: "bold", color: "#D0A360", padding: 0 }}
          >
            {gaugeProgress.value.to(value => Math.floor(value)+'%')}
          </AnimatedDiv>
        </div>
        <div
          className="Progress_Gauge_Number"
          style={{
            backgroundColor: "#EAEAED",
            height: "7px",
            borderRadius: 100,
          }}
        >
          <AnimatedDiv
            className="Progress_Gauge"
            style={{ width: gaugeProgress.value.to(value=>value + "%"), borderRadius: 100 }}
          >
            </AnimatedDiv>
        </div>
      </div>

      <img className="LaporanImage" src={`${API_URL}/files/${data.fImages[0].filename}`} alt="" style={{ marginTop: '10px', marginBottom: '10px' }} />
      <div style={{ height: '54px', overflow: 'hidden' }}>
        <text style={{ fontSize: '18px', color: '#D0A360', fontWeight: 'bold' }}>{data.fProjectName}</text>
      </div>      
      <div style={{ height: '10px' }} />
      <text style={{ fontWeight: 'bold', fontSize: '14px' }}>Notes:</text>
      <></>
      <div className="LaporanNotesWrapper">
        <text className="LaporanNotes" style={{ fontSize: '14px' }}>{data.fKet}</text>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginTop: '15px' }}>
        <img src={`${API_URL}/files/${data.fClientImage}`} alt="" style={{ width: '40px', height: '40px', marginRight: '10px' }} />
        <text style={{ fontSize: '14px', fontWeight: 600 }}>{data.fClientName}</text>
      </div>
    </div>
  );
};

export default LaporanProject;
