import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";

import planninglogo from "../../assets/planning.png";

import "@fontsource/poppins";

import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";

import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";

const djs = dayjs().locale("de").format();

const DetailPlanning = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const { state } = useLocation();
  const { fID } = state;

  const [data, setData] = useState({
    fPlanningName: "",
    fProfile: {
      fName: "",
    },
    fPlanningFile: [],
  });

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    getDetailPlanning();
  }, [user]);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getDetailPlanning = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getdetailplanning`,
        { fID: fID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data);
    } catch (error) {}
  };

  const renderTextWithLineBreaks = (text) => {
    return (text || "").split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  }

  const getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "135px",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: planninglogo,
          title: "Detail Planning",
          name: user.fName,
          job: user.fRoleAlias,
        }}
      />

      <div className="AddNotulenContainer">
      <div style={{ justifyContent: 'center' }} className="DetailProjectFileDownloadWrapper">
          {data.fPlanningFile.map((d, i) => {
            const idx = i + 1;
            return (
              <div
                onClick={() => {
                  handleDownload(
                    `${API_URL}/files/${d.filename}`,
                    `Planning_${data.fProfile.fName}_${idx}.${getFileExtension(
                      d.filename
                    )}`
                  );
                }}
                style={{ borderRadius: 3 }}
                className="DetailProjectFileDownload"
              >
                <div className="DetailProjectIconWrapper">
                  <img
                    className="DetailProjectIcon"
                    src={planninglogo}
                    alt=""
                  />
                  <div>
                    <text className="DetailDownloadTextTitle">{`Planning_${idx}`}</text>
                    <div />
                    <text style={{ fontSize: "14px" }}>
                      {humanFileSize(d.size)}
                    </text>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="AddNotulenInput">
          <text style={{ fontWeight: "bold" }}>Nama Planning</text>
          <text>{data.fPlanningName}</text>
        </div>

        <div className="AddNotulenInput">
          <text style={{ fontWeight: "bold" }}>Tanggal</text>
          <text>
            {dayjs(data.fPlanningDate).format("DD MMM YYYY").toString()}
          </text>
        </div>

        <div className="AddNotulenInput">
          <text style={{ fontWeight: "bold" }}>Dibuat Oleh</text>
          <text>{data.fProfile.fName}</text>
        </div>

        <div className="AddNotulenInput">
          <text style={{ fontWeight: "bold" }}>Keterangan</text>
          <text>{renderTextWithLineBreaks(data.fPlanningKet)}</text>
        </div>

        <div style={{ height: "30px", width: "1px" }} />
      </div>
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default DetailPlanning;
