import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";

import file from "../../assets/file.png";
import bin from "../../assets/bin.png";
import Dropdown from "../../Components/Dropdown/Dropdown";
import DownloadCard from "../../Components/DownloadCard/DownloadCard";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import pembelianlogo from "../../assets/pembelian.png";

import "@fontsource/poppins";

import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";

import "./DetailPembelian.css";

import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import ListPembelian from "../../Components/ListPembelian/ListPembelian";

const djs = dayjs().locale("de").format();

const DetailPembelian = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const { state } = useLocation();
  const { fID } = state;

  const [data, setData] = useState({
    fPrice: 0,
    fFile: {
      filename: "",
    },
    fPengajuan: [],
    fUser: {
      fName: '',
      fImages: ''
    }
  });

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [loading, setLoading] = useState(false);

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    getDetailPembelian();
  }, [user]);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getDetailPembelian = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getdetailpembelian`,
        { fID: fID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data);
    } catch (error) {}
  };

  const handleEditButton = () => {
    navigate("/editpembelian", {
      state: { data: data },
    });
  };

  const handlePaidButton = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/paidpembelian`,
        { fID },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const renderTextWithLineBreaks = (text) => {
    return (text || "").split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "135px",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: pembelianlogo,
          title: "Detail Pembelian",
          name: user.fName,
          job: user.fRoleAlias,
        }}
      />

      <div className="DetailPembelianContainer">

        {
          data.fStatus === 'NEW' && 
        <button
          onClick={handleEditButton}
          style={{
            width: "95%",
            backgroundColor: "#D1A361",
            color: "white",
            padding: "10px 0",
            borderRadius: 5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "10px",
            borderWidth: 0,
          }}
        >
          Update Pembelian
        </button>
        }

        {
          data.fStatus === 'NEW' &&
        <button
          onClick={handlePaidButton}
          style={{
            width: "95%",
            backgroundColor: "green",
            color: "white",
            padding: "10px 0",
            borderRadius: 5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "25px",
            borderWidth: 0,
          }}
        >
          Tandai Dibayar
        </button>
        }
        <text
          style={{ fontSize: "24px", fontWeight: "bold", color: "#D1A361" }}
        >
          {data.fOrderName}
        </text>
        <text
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            marginTop: "15px",
            color: "#282828",
          }}
        >
          Jatuh Tempo
        </text>
        <text
          style={{ fontSize: "16px", fontWeight: "bold", color: "#888888" }}
        >
          {dayjs(data.fDateTime).format("DD MMM YYYY").toString()}
        </text>

        <div
          style={{
            width: '95%',
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            marginTop: "15px",
            marginBottom: '0',
          }}
        >
          <img
            src={`${API_URL}/files/${data.fUser.fImages}`}
            alt=""
            style={{
              width: "40px",
              height: "40px",
              marginRight: "10px",
              borderRadius: 100,
            }}
          />
          <text style={{ fontSize: "14px", fontWeight: 600 }}>
            {data.fUser.fName}
          </text>
        </div>

        <img
          src={`${API_URL}/files/${data.fFile.filename}`}
          alt=""
          className="DetailPembelianImage"
          style={{ borderRadius: 3, marginTop: '10px' }}
        />
        <div className="DetailPembelianDataContainer">
          <text className="DetailPembelianDataTitle">Total Harga</text>
          <text
            style={{ fontWeight: "bold" }}
            className="DetailPembelianDataPrice"
          >
            Rp {numberWithCommas(data.fPrice)}
          </text>
        </div>
      </div>

      <div className="DetailPembelianContainer">
        <div className="DetailPembelianDataContainer">
          <text className="DetailPembelianDataTitle">Nomor Nota/PO</text>
          <text className="DetailPembelianData">{data.fPONumber}</text>
        </div>

        <div className="DetailPembelianDataContainer">
          <text className="DetailPembelianDataTitle">Nama Vendor</text>
          <text className="DetailPembelianData">{data.fVendorName}</text>
        </div>

        <div className="DetailPembelianDataContainer">
          <text className="DetailPembelianDataTitle">Keterangan</text>
          <text className="DetailPembelianData">
            {renderTextWithLineBreaks(data.fKet)}
          </text>
        </div>
      </div>

      <div className="DetailPembelianContainer">
        <div className="DetailPembelianDataContainer">
          <text className="DetailPembelianDataTitle">Item</text>
          <ListPembelian listDisplayedItem={data.fPengajuan} />
        </div>
      </div>
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default DetailPembelian;
