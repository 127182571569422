import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";

import file from "../../assets/file.png";
import bin from "../../assets/bin.png";
import Dropdown from "../../Components/Dropdown/Dropdown";
import DownloadCard from "../../Components/DownloadCard/DownloadCard";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import pembelianlogo from "../../assets/pembelian.png";
import plusicon from "../../assets/add.png";

import "@fontsource/poppins";

import ReactSignatureCanvas from "react-signature-canvas";

import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";
import ModalListPengajuan from "../../Components/Modal/ModalListPengajuan";
import ListPembelian from "../../Components/ListPembelian/ListPembelian";
require("dayjs/locale/de");

const djs = dayjs().locale("de").format();

const UpdatePembelian = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const { state } = useLocation();
  const { data } = state;
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [judulOrder, setJudulOrder] = useState(data.fOrderName);
  const [nomorNota, setNomorNota] = useState(data.fPONumber);
  const [namaVendor, setNamaVendor] = useState(data.fVendorName);
  const [harga, setHarga] = useState(data.fPrice);
  const [jatuhTempo, setJatuhTempo] = useState(dayjs(data.fDateTime));
  const [image, setImage] = useState(data.fFile);
  const [ket, setKet] = useState(data.fKet);
  const [listDisplayedItem, setListDisplayedItem] = useState(data.fPengajuan);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      if (response.data.user[0].fRole !== "FINANCE") {
        navigate("/");
      }
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const validationForm = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!judulOrder.trim()) {
      validationErrors.title = "harus diisi";
    }
    if (!nomorNota.trim()) {
      validationErrors.nomor = "harus diisi";
    }
    if (!namaVendor.trim()) {
      validationErrors.nama = "harus diisi";
    }
    if (!ket.trim()) {
      validationErrors.ket = "harus diisi";
    }
    if (!image) {
      validationErrors.image = "pilih gambar";
    }
    if (!harga) {
      validationErrors.price = "harus diisi";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0 && !loading) {
      handleSaveButton();
    }
  };

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  const handleSaveButton = async () => {
    setLoading(true);
    try {
      let tempFile = {}
      if (typeof image.filename !== 'string'){
        const base64File = await blobToBase64(image);
        tempFile = {
          file: base64File,
          ext: getFileExtension(image.name),
          size: image.size,
          type: image.type
        }
      }else{
        tempFile = image;
      }

      const bodyData = {
        fID: data.fID,
        fOrderName: judulOrder,
        fPONumber: nomorNota,
        fVendorName: namaVendor,
        fFile: tempFile,
        fPrice: harga,
        fDateTime: jatuhTempo,
        fKet: ket
      }

      await axiosJWT.post(
        `${API_URL}/updatepembelian`,
        bodyData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        minHeight: "100vh",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: pembelianlogo,
          title: "Tambah Pembelian",
          name: user.fName,
          job: user.fRoleAlias,
          type: "Add",
          update: false,
          delete: false,
        }}
      />

      <div className="AddNotulenContainer" style={{ paddingTop: "140px" }}>
        <div className="AddNotulenInput">
          <text>
            Judul Order
            {errors.title && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.title}
              </span>
            )}
          </text>
          <input
            value={judulOrder}
            onChange={(v) => {
              setJudulOrder(v.target.value);
            }}
            className="AddNotulenInputText"
            type="text"
            placeholder="Judul"
          />
        </div>

        <div className="AddNotulenInput">
          <text>
            Nomor Nota/PO
            {errors.nomor && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.nomor}
              </span>
            )}
          </text>
          <input
            value={nomorNota}
            onChange={(v) => {
              setNomorNota(v.target.value);
            }}
            className="AddNotulenInputText"
            type="text"
            placeholder="Nomor"
          />
        </div>

        <div className="AddNotulenInput">
          <text>
            Nama Vendor
            {errors.nama && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.nama}
              </span>
            )}
          </text>
          <input
            value={namaVendor}
            onChange={(v) => {
              setNamaVendor(v.target.value);
            }}
            className="AddNotulenInputText"
            type="text"
            placeholder="Nama Vendor"
          />
        </div>

        <div className="AddNotulenInput">
          <text>
            Jumlah Harga
            {errors.price && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.price}
              </span>
            )}
          </text>
          <input
            value={harga}
            onChange={(v) => {
              setHarga(v.target.value);
            }}
            className="AddNotulenInputText"
            type="number"
            placeholder="Harga"
          />
        </div>

        <div className="AddNotulenInput">
          <text>Jatuh Tempo</text>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ width: "50%" }}>
              <DatePicker
                format="DD/MM/YYYY"
                disablePast={true}
                value={jatuhTempo}
                defaultValue={jatuhTempo}
                onChange={(v) => {
                  setJatuhTempo(v);
                }}
              />
            </div>
          </LocalizationProvider>
        </div>

        <div className="AddNotulenInput">
          <text>
            Foto Dokumen
            {errors.image && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.image}
              </span>
            )}
          </text>
          <div>
            {typeof image.filename !== "string" ? (
              <img
                onClick={() => handleImageClick()}
                style={{
                  width: "300px",
                  height: "150px",
                  objectFit: "cover",
                  backgroundColor: "white",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  boxSizing: "border-box",
                  borderRadius: 7,
                }}
                src={URL.createObjectURL(image)}
                alt=""
              />
            ) : (
              <img
                onClick={() => handleImageClick()}
                style={{
                  width: "300px",
                  height: "150px",
                  objectFit: "cover",
                  backgroundColor: "white",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  boxSizing: "border-box",
                  borderRadius: 7,
                }}
                src={`${API_URL}/files/${image.filename}`}
                alt=""
              />
            )}
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
        </div>

        <div className="AddNotulenInput">
          <text>
            Keterangan
            {errors.ket && (
              <span
                style={{ color: "red", fontSize: "14px", fontWeight: "normal" }}
              >
                {" "}
                *{errors.ket}
              </span>
            )}
          </text>
          <div className="AddNotulenInputNotes">
            <textarea
              style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
              minLength={150}
              value={ket}
              onChange={(v) => {
                setKet(v.target.value);
              }}
              className="AddNotulenInputNote"
              type="text"
              placeholder="Keterangan"
            ></textarea>
          </div>
        </div>

        <div style={{ width: "90%" }}>
          <ListPembelian listDisplayedItem={data.fPengajuan} />
        </div>

        <div style={{ display: "flex", width: "95%" }}>
          <div
            onClick={validationForm}
            className="AddProgressButton"
            style={{ opacity: loading ? "0.7" : "1" }}
          >
            {loading && <span>Loading...</span>}
            {!loading && <span>Simpan</span>}
          </div>
        </div>
      </div>
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default UpdatePembelian;
