import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";

import imageicon from "../../assets/image.png";
import closeicon from "../../assets/closered.png";
import bin from "../../assets/bin.png";
import addicon from "../../assets/add.png";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import pengajuan from "../../assets/pengajuan.png";

import "./AddPengajuan.css";
import "@fontsource/poppins";

import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";
require("dayjs/locale/de");

const djs = dayjs().locale("de").format();

const AddPengajuan = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [dateTime, setDateTime] = useState(dayjs(new Date()));
  const [ket, setKet] = useState("");
  const [pengajuanName, setPengajuanName] = useState("");

  const [project, setProject] = useState([]);
  const [selectedProject, setSelectedProject] = useState("Pilih Project");
  const [selectedProjectData, setSelectedProjectData] = useState();

  const [files, setFiles] = useState([]);
  const [modalItem, setModalItem] = useState(false);
  const [listPengajuan, setListPengajuan] = useState([]);
  const [items, setItems] = useState([]);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    getAllProject();
    getAllItems();
  }, [user]);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      if (
        response.data.user[0].fRole !== "SPV" &&
        response.data.user[0].fRole !== "PM" &&
        response.data.user[0].fRole !== "BOSS"
      ) {
        navigate("/");
      }
      setUser(response.data.user[0]);
      setSelectedProject(
        response.data.project.fProjectName
          ? response.data.project.fProjectName
          : "Pilih Project"
      );
      setSelectedProjectData(response.data.project);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllProject = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getprojectdropdown`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProject(response.data);
    } catch (error) {}
  };

  const getAllItems = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getallitems`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setItems(response.data.data);
    } catch (error) {}
  };

  modalItem
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  const validationForm = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (selectedProject === "Pilih Project") {
      validationErrors.project = "harus dipilih";
    }
    if (!pengajuanName.trim()) {
      validationErrors.name = "harus diisi";
    }
    if (!ket.trim()) {
      validationErrors.ket = "harus diisi";
    }

    if (listPengajuan.length === 0) {
      validationErrors.item = "harus dipilih minimal 1";
    } else {
      for (let i = 0; i < listPengajuan.length; i++) {
        if (!listPengajuan[i].fQuantity) {
          validationErrors.itemField = "Semua form barang harus diisi";
          break;
        }

        if (!listPengajuan[i].fKet.trim()) {
          validationErrors.itemField = "Semua form barang harus diisi";
          break;
        }

        if (!listPengajuan[i].fDenom) {
          validationErrors.itemField = "Semua form barang harus diisi";
          break;
        }
      }
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0 && !loading) {
      handleSaveButton();
    }
  };

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  const handleSaveButton = async () => {
    setLoading(true);

    const bodyData = {
      fPengajuanName: pengajuanName,
      fProjectID: selectedProjectData.fID,
      fDateTime: dateTime,
      fKet: ket,
      fItems: listPengajuan
    }

    try {
      for (let i = 0; i < bodyData.fItems.length; i++) {
        if (typeof bodyData.fItems[i].fItemImage.filename !== "string") {
          const base64File = await blobToBase64(bodyData.fItems[i].fItemImage);
          const name = URL.createObjectURL(bodyData.fItems[i].fItemImage);

          bodyData.fItems[i].fItemImage = {
            file: base64File,
            filename: name,
            ext: getFileExtension(bodyData.fItems[i].fItemImage.name),
            size: bodyData.fItems[i].fItemImage.size,
            type: bodyData.fItems[i].fItemImage.type
          }
        }
      }

      await axiosJWT.post(
        `${API_URL}/addpengajuan`,
        bodyData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleItemClick = (data, i) => {
    setListPengajuan([...listPengajuan, data]);
    setItems((prevList) => prevList.filter((d, index) => index !== i));
    setModalItem(false);
  };

  const handleOpenListItem = () => {
    setModalItem(true);
  };

  const ModalItemList = () => {
    const inputRef = useRef(null);
    const [searchItem, setSearchItem] = useState("");
    const [first, setFirst] = useState(true);
    const [image, setImage] = useState("");
    const [newItemName, setNewItemName] = useState("");
    const [data, setData] = useState({
      fItemName: "",
      fItemImage: "",
      fPrice: 0,
      fKet: "",
      fCategory: "INVALID",
    });

    const [itemErrors, setItemErrors] = useState({});

    const handleAddMissingItem = () => {
      setFirst(false);
    };

    const handleImageChange = (event) => {
      const image = event.target.files[0];
      if (image) {
        setImage(image);
      }
    };

    const handleImageClick = () => {
      inputRef.current.click();
    };

    const handleAddNewItem = () => {
      const temp = { ...data };
      temp.fItemImage = image;
      temp.fItemName = newItemName;
      setImage("");
      setNewItemName("");
      items.push(temp);
      setFirst(true);
    };

    const handleCancelAddItemButton = () => {
      setImage("");
      setNewItemName("");
      setFirst(true);
    };

    const validationAddItem = (e) => {
      e.preventDefault();
      const validationErrors = {};
      if (!image) {
        validationErrors.itemImage = "Pilih gambar";
      }

      if (!newItemName.trim()) {
        validationErrors.itemName = "Harus diisi";
      }

      setItemErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0) {
        handleAddNewItem();
      }
    };

    return (
      <div
        className="AddPengajuanCenterModal"
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          zIndex: "10000000",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "1024px",
        }}
      >
        <div
          style={{
            width: "95%",
            height: "85%",
            backgroundColor: "white",
            borderRadius: "12px",
            boxSizing: "border-box",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            fontSize: "16px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              boxSizing: "border-box",
              borderRadius: "12px 12px 0 0",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              paddingTop: "15px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#D0A360",
              color: "white",
              position: "relative",
            }}
          >
            <text
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              {first ? `List Barang` : `Tambah Barang`}
            </text>
            {first && (
              <input
                className="AddPengajuanSearchItem"
                style={{ borderRadius: 5 }}
                type="text"
                value={searchItem}
                placeholder="Search"
                onChange={(e) => {
                  setSearchItem(e.target.value);
                }}
                key="searchInput"
              />
            )}
            {first && (
              <div
                onClick={() => {
                  handleAddMissingItem();
                }}
                style={{
                  width: "90%",
                  fontSize: "16px",
                  backgroundColor: "#088F8F",
                  boxSizing: "border-box",
                  padding: "10px 0",
                  borderRadius: 7,
                  color: "white",
                  fontWeight: "bold",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <text>Tambah Barang Baru</text>
              </div>
            )}

            <div
              style={{
                width: "50%",
                height: "3px",
                borderRadius: "100",
                backgroundColor: "white",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            />

            <img
              onClick={() => {
                setModalItem(false);
              }}
              style={{
                width: "30px",
                height: "30px",
                position: "absolute",
                top: "15px",
                right: "15px",
              }}
              src={closeicon}
              alt=""
            />
          </div>

          {first ? (
            <div className="scrollVertical">
              {items
                .sort((a, b) =>
                  a.fItemName.toUpperCase() < b.fItemName.toUpperCase() ? -1 : 1
                )
                .filter((item) =>
                  item.fItemName
                    .toLowerCase()
                    .includes(searchItem.toLowerCase())
                )
                .map((d, i) => {
                  return (
                    <div
                      onClick={() => {
                        handleItemClick(d, i);
                      }}
                      className="AddItemCardContainer"
                      style={{
                        borderRadius: "7px",
                      }}
                    >
                      <img
                        className="AddItemImageCard"
                        src={
                          typeof d.fItemImage.filename === "string"
                            ? `${API_URL}/files/${d.fItemImage.filename}`
                            : URL.createObjectURL(d.fItemImage)
                        }
                        alt=""
                      />
                      <text
                        style={{
                          padding: "10px 10px",
                          color: "#282828",
                          fontSize: "16px",
                        }}
                      >
                        {d.fItemName}
                      </text>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div
              className="scrollVertical"
              style={{
                width: "90%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                overflowY: "auto",
                paddingTop: "15px",
                paddingBottom: "15px",
                fontSize: "16px",
                gap: "20px",
              }}
            >
              <div className="AddPengajuanAddItemWrapper">
                <text style={{ fontSize: "16px" }}>
                  Gambar
                  {itemErrors.itemImage && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {" "}
                      *{itemErrors.itemImage}
                    </span>
                  )}
                </text>
                {image ? (
                  <img
                    onClick={handleImageClick}
                    style={{ objectFit: "cover", borderRadius: 7 }}
                    className="AddPengajuanUploadNewItemImage"
                    src={
                      typeof image.filename === "string"
                        ? image
                        : URL.createObjectURL(image)
                    }
                  />
                ) : (
                  <div
                    onClick={handleImageClick}
                    className="AddPengajuanUploadNewItemImage"
                    style={{
                      borderRadius: 7,
                      display: "flex",
                      flexDirection: "column",
                      padding: "20px 10px",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      style={{ width: "27px", height: "27px" }}
                      src={imageicon}
                      alt=""
                    />
                    <text style={{ textAlign: "center" }}>
                      <span style={{ color: "#818CF8" }}>click to browse</span>
                    </text>
                  </div>
                )}

                <input
                  type="file"
                  ref={inputRef}
                  onChange={handleImageChange}
                  accept="image/*"
                  style={{ display: "none" }}
                />
              </div>

              <div className="AddPengajuanAddItemWrapper">
                <text style={{ fontSize: "16px" }}>
                  Nama Barang
                  {itemErrors.itemName && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {" "}
                      *{itemErrors.itemName}
                    </span>
                  )}
                </text>
                <input
                  className="AddPengajuanNewItemInput"
                  style={{ borderRadius: 5 }}
                  type="text"
                  value={newItemName}
                  placeholder="Search"
                  onChange={(e) => {
                    setNewItemName(e.target.value);
                  }}
                  key="searchInput"
                />
              </div>

              <div
                onClick={validationAddItem}
                style={{ borderRadius: 5, marginTop: "25px" }}
                className="AddPengajuanAddItemButton"
              >
                Tambah
              </div>
              <div
                onClick={handleCancelAddItemButton}
                style={{ borderRadius: 5, backgroundColor: "#7C7B7A" }}
                className="AddPengajuanAddItemButton"
              >
                Batal
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleItemDelete = (data, i) => {
    data.fQuantity = undefined;
    data.fDenom = "";
    data.fKet = "";
    items.push(data);
    setListPengajuan((prevItems) =>
      prevItems.filter((item, index) => index !== i)
    );
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "135px",
        position: "relative",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: pengajuan,
          title: "Tambah Pengajuan",
          name: "John Doe",
          job: "SPV",
        }}
      />

      <div className="AddPengajuanContainer">
        <div className="AddPengajuanInput">
          <text>
            Judul Pengajuan
            {errors.name && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.name}
              </span>
            )}
          </text>
          <input
            value={pengajuanName}
            onChange={(e) => {
              setPengajuanName(e.target.value);
            }}
            className="AddNotulenInputText"
            type="text"
            placeholder="Pengajuan1"
          />
        </div>

        <div className="AddPengajuanInput">
          <text>
            Project
            {errors.project && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.project}
              </span>
            )}
          </text>
          <Dropdown
            selected={selectedProject}
            setSelected={setSelectedProject}
            setSelectedData={setSelectedProjectData}
            options={project}
            type={"fProjectName"}
          />
        </div>

        <div className="AddPengajuanInput">
          <text>Tanggal</text>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ width: "50%" }}>
              <DatePicker
                format="DD/MM/YYYY"
                value={dateTime}
                onChange={(v) => {
                  setDateTime(v);
                }}
              />
            </div>
          </LocalizationProvider>
        </div>

        <div className="AddPengajuanInput">
          <text>
            Keterangan
            {errors.ket && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.ket}
              </span>
            )}
          </text>
          <div className="AddPengajuanInputNotes">
            <textarea
              style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
              minLength={150}
              onChange={(v) => {
                setKet(v.target.value);
              }}
              className="AddPengajuanInputNote"
              type="text"
              placeholder="Keterangan"
            >
              {ket}
            </textarea>
          </div>
        </div>

        <div className="AddPengajuanInput">
          <text>
            Barang
            {errors.item && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.item}
              </span>
            )}
          </text>
          <div className="PengajuanCardContainer">
            {listPengajuan.map((d, i) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "95%",
                    marginBottom: "10px",
                  }}
                >
                  {i === 0 ? (
                    <div style={{ height: "25px" }} />
                  ) : (
                    <div
                      style={{
                        height: "0.5px",
                        width: "100%",
                        backgroundColor: "rgba(133,133,133,0.5)",
                        marginBottom: "10px",
                        marginTop: "10px",
                      }}
                    />
                  )}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        style={{
                          width: "135px",
                          height: "135px",
                          objectFit: "cover",
                          border: "1px solid rgba(0,0,0, 0.2)",
                          borderRadius: 7,
                        }}
                        src={
                          typeof d.fItemImage.filename === "string"
                            ? `${API_URL}/files/${d.fItemImage.filename}`
                            : URL.createObjectURL(d.fItemImage)
                        }
                        alt=""
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          marginLeft: "10px",
                        }}
                      >
                        <text style={{ fontSize: "16px", fontWeight: "bold" }}>
                          Nama Barang
                        </text>
                        <div>
                          <text style={{ fontSize: "16px" }}>
                            {d.fItemName}
                          </text>
                        </div>

                        <div style={{ height: "10px" }} />

                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <text
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Jumlah
                            </text>
                            <input
                              value={d.fQuantity}
                              onChange={(v) => {
                                const temp = [...listPengajuan];
                                temp[i].fQuantity = v.target.value;
                                setListPengajuan(temp);
                              }}
                              style={{
                                fontSize: "16px",
                                width: "100%",
                                padding: "10px 5px",
                                boxSizing: "border-box",
                                borderRadius: 7,
                                border: "1px solid rgba(0, 0, 0, 0.2)",
                              }}
                              type="number"
                              placeholder="0"
                            />
                          </div>
                          <div
                            style={{
                              width: "90%",
                              display: "flex",
                              marginLeft: "10px",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <text
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Satuan
                            </text>
                            <input
                              value={d.fDenom}
                              onChange={(v) => {
                                const temp = [...listPengajuan];
                                temp[i].fDenom = v.target.value;
                                setListPengajuan(temp);
                              }}
                              style={{
                                fontSize: "16px",
                                width: "70%",
                                padding: "10px 5px",
                                boxSizing: "border-box",
                                borderRadius: 7,
                                border: "1px solid rgba(0, 0, 0, 0.2)",
                              }}
                              type="text"
                              placeholder="m2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="AddPengajuanInputItem">
                      <text style={{ fontWeight: "bold" }}>Keterangan</text>
                      <div className="AddPengajuanInputNotesItem">
                        <textarea
                          style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
                          minLength={150}
                          value={d.fKet}
                          onChange={(v) => {
                            const temp = [...listPengajuan];
                            temp[i].fKet = v.target.value;
                            setListPengajuan(temp);
                          }}
                          className="AddPengajuanInputNote"
                          type="text"
                          placeholder="Keterangan"
                        ></textarea>
                      </div>
                    </div>

                    <img
                      onClick={() => {
                        handleItemDelete(d, i);
                      }}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "20px",
                        position: "absolute",
                        top: "0px",
                        right: "5px",
                      }}
                      src={bin}
                      alt=""
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div
          onClick={() => {
            handleOpenListItem();
          }}
          style={{
            width: "95%",
            fontSize: "16px",
            color: "#D1A361",
            fontWeight: "bold",
            marginBottom: "25px",
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img style={{ width: "16px", height: "16px" }} src={addicon} alt="" />
          <text>Tambah Barang</text>
        </div>

        <div style={{ marginBottom: "10px" }} className="AddPengajuanInput">
          {errors.itemField && (
            <span style={{ color: "red", fontSize: "16px" }}>
              {" "}
              *{errors.itemField}
            </span>
          )}
        </div>
        <div style={{ display: "flex", width: "95%" }}>
          <div
            onClick={validationForm}
            className="AddPengajuanButton"
            style={{ opacity: loading ? "0.7" : "1" }}
          >
            {loading && <span>Loading...</span>}
            {!loading && <span>Simpan</span>}
          </div>
        </div>
      </div>
      <div style={{ height: "30px" }} />

      {modalItem && <ModalItemList />}
    </div>
  );
};

export default AddPengajuan;
