import "./NavbarExtension.css";

const NavbarExtension = ({ data }) => {

  return (
    <div
      style={{
        backgroundColor: "#F9FAFC",
        width: "100vw",
        position: "fixed",
        top: "55px",
        zIndex: 9991,
      }}
    >
      <div className="NavExtensionContainer">
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img src={data.icon} alt="" className="NavExtensionIcon" />
          <text className="NavExtensionText">{data.title}</text>
        </div>
      </div>
    </div>
  );
};

export default NavbarExtension;
