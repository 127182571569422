import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";

import file from "../../assets/file.png";
import bin from "../../assets/bin.png";
import Dropdown from "../../Components/Dropdown/Dropdown";
import DownloadCard from "../../Components/DownloadCard/DownloadCard";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import notulen from "../../assets/notulen.png";

import "./AddNotulen.css";
import "@fontsource/poppins";

import { Link } from "react-router-dom";
import { useRef, useState } from "react";
import dayjs from "dayjs";
require("dayjs/locale/de");

const djs = dayjs().locale("de").format();

const UpdateNotulen = () => {
  const inputRef = useRef(null);
  const dataRefs = useRef(null);

  const profile = {
    name: "John Doe",
    job: "PM",
  };

  const [data, setData] = useState({
    project: "COMMERCIAL GUDANG TAMAN SARI",
    waktu: dayjs(new Date()).set("second", 0),
    qty: '25 m2',
    job: 'Perubahan',
    files: [],
    keterangan: "lorem ipsum",
  });

  const projects = [
    { name: "RESIDENTIAL CLUSTER VERDAN VILLE", code: "1" },
    { name: "COMMERCIAL GUDANG TAMAN SARI", code: "2" },
    { name: "COMMERCIAL RUKO PANCORAN", code: "3" },
    { name: "COMMERCIAL MUARA KARANG", code: "4" },
    { name: "RESIDENTIAL EMPANG BAHAGIA", code: "5" },
  ];

  const works = [
    { name: "Tambah Kurang" },
    { name: "Persetujuan Material" },
    { name: "Perubahan" },
  ];

  const [files, setFiles] = useState([]);
  const [selectedWorkType, setSelectedWorkType] = useState(works[0].project);
  const [selectedProject, setSelectedProject] = useState(data.project);
  const navigate = useNavigate();

  const handleFileClick = () => {
    inputRef.current.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const temp = {
        file: file,
        ref: dataRefs,
        ket: "",
        active: true,
        type: file.type
      };

      setFiles([file, ...files]);
    }
  };

  const handleSaveButton = () => {
    data.project = selectedProject;
    data.files = files;
  };

  const removeFile = (indexToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((files, index) => index !== indexToRemove)
    );
  };

  const handleFileDelete = (i) => {
    removeFile(i);
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "130px",
        minHeight: "100vh",
      }}
    >
      <Navbar profileData={{ name: profile.name, job: profile.job }} />
      <NavbarExtension
        data={{
          icon: notulen,
          title: "Tambah Notulen",
          name: "John Doe",
          job: "Supervisor",
          type: "Add",
          update: false,
          delete: false,
        }}
      />

      <div className="AddNotulenContainer">
        <div className="AddNotulenInput">
          <text>Project</text>
          <Dropdown
            selected={selectedProject}
            setSelected={setSelectedProject}
            options={projects}
          />
        </div>

        <div className="AddNotulenInput">
          <text>Tanggal</text>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ width: "50%" }}>
              <DatePicker
              format="DD/MM/YYYY"
                value={data.waktu}
                defaultValue={data.waktu}
                onChange={(v) => (data.waktu = v)}
              />
            </div>
          </LocalizationProvider>
        </div>

        <div className="AddNotulenInput">
          <text>{"File (Maks 5)"}</text>
          <div>
            <div
              onClick={handleFileClick}
              className="AddNotulenFile"
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "20px 10px",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <img
                style={{ width: "27px", height: "27px" }}
                src={file}
                alt=""
              />
              <text style={{ textAlign: "center" }}>
                <span style={{ color: "#818CF8" }}>click to browse</span>
              </text>
            </div>
            <input
              type="file"
              ref={inputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
        </div>

        {/* download card */}
        {files.map((d, i) => {
          return (
            <div style={{ width: '95%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
              <DownloadCard file={d} index={i} delete={setFiles} />
              <img onClick={() => {handleFileDelete(i)}} style={{ width: '20px', height: '20px', marginLeft: '20px' }} src={bin} alt=""/>
            </div>
          );
        })}

        {profile.job === "Owner" || profile.job === "Product Manager" ? (
          <div style={{ marginTop: '10px' }} className="AddNotulenInput">
            <text>Jenis Barang/pekerjaan</text>
            <Dropdown
              selected={selectedWorkType}
              setSelected={setSelectedWorkType}
              options={works}
            />
          </div>
        ) : (
          <div style={{ gap: "5px", marginTop: '10px' }} className="AddNotulenInput">
            <text>Jenis Barang/pekerjaan</text>
            <div />
            <text>Perubahan</text>
          </div>
        )}

        <div  className="AddNotulenInput">
            <text>Quantity</text>
            <input
            className="AddNotulenInputText"
                type="text"
                placeholder="1 m2"
              />
        </div>

        <div className="AddNotulenInput">
          <text>Keterangan</text>
          <div className="AddNotulenInputNotes">
            <textarea
              style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
              minLength={150}
              onChange={(v) => {
                data.keterangan = v;
              }}
              className="AddNotulenInputNote"
              type="text"
              placeholder="Keterangan"
            >
              {data.keterangan}
            </textarea>
          </div>
        </div>

        <div style={{ display: "flex", width: "95%" }}>
          <div
            onClick={() => {
              handleSaveButton();
            }}
            className="AddProgressButton"
          >
            <text>Simpan</text>
          </div>
        </div>

      </div>
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default UpdateNotulen;
