import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";

import file from "../../assets/file.png";
import bin from "../../assets/bin.png";
import Dropdown from "../../Components/Dropdown/Dropdown";
import DownloadCard from "../../Components/DownloadCard/DownloadCard";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import pembelianlogo from "../../assets/pembelian.png";
import plusicon from "../../assets/add.png";

import "@fontsource/poppins";

import ReactSignatureCanvas from "react-signature-canvas";

import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";
import ModalListPengajuan from "../../Components/Modal/ModalListPengajuan";
require("dayjs/locale/de");

const djs = dayjs().locale("de").format();

const AddPembelian = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const { state } = useLocation();
  const { fPengajuan, fListSelected, fListByItem, fIndexing } = state;

  const inputRef = useRef(null);

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [judulOrder, setJudulOrder] = useState("");
  const [nomorNota, setNomorNota] = useState("");
  const [namaVendor, setNamaVendor] = useState("");
  const [harga, setHarga] = useState();
  const [jatuhTempo, setJatuhTempo] = useState(dayjs(new Date()));
  const [image, setImage] = useState("");
  const [ket, setKet] = useState("");
  const [openPengajuan, setOpenPengajuan] = useState(false);

  const [pengajuan, setPengajuan] = useState(fPengajuan);
  const [listSelected, setListSelected] = useState(fListSelected);
  const [listbyItem, setListbyItem] = useState(fListByItem);
  const [indexing, setIndexing] = useState(fIndexing);
  const [listDisplayedItem, setListDisplayedItem] = useState([]);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
    if (pengajuan.length === 0) {
      getListPengajuan();
    }
  }, []);

  useEffect(() => {
    listItem();
  }, [listSelected]);

  useEffect(() => {
    if (listSelected.size === 0) {
      defaultSelected();
    }
  }, [pengajuan]);

  const defaultSelected = () => {
    pengajuan.map((v) => {
      v.fPengajuanItem.map((item) => {
        let tempData = { ...item };
        listSelected.set(tempData.fID, { check: false });
      });
    });
  };

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      if (response.data.user[0].fRole !== "FINANCE") {
        navigate("/");
      }
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getListPengajuan = async () => {
    try {
      const response = await axiosJWT.post(`${API_URL}/listpengajuan`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPengajuan(response.data);
    } catch (error) {}
  };

  const listItem = () => {
    let res = [];
    let i = 0;
    const map = new Map();

    listSelected.forEach((value, key) => {
      const item = value.fItem;
      const project = value.fProject;
      const pengajuan = value.fPengajuan;

      if (value.check) {
        if (map.has(item.fID)) {
          const tempPengajuan = {
            fPengajuanItemID: key,
            fPengajuanID: pengajuan.fID,
            fPengajuanName: pengajuan.fPengajuanName,
            fProjectID: project.fID,
            fProjectName: project.fProjectName,
            fTotal: item.fTotal,
          };
          res[map.get(item.fID)].fPengajuanItem.push(tempPengajuan);
          res[map.get(item.fID)].fTotal =
            res[map.get(item.fID)].fTotal + item.fTotal;
        } else {
          const temp = {
            fID: item.fID,
            fItemName: item.fItemName,
            fItemImage: item.fItemImage,
            fTotal: item.fTotal,
            fDenom: item.fDenom,
            fPengajuanItem: [],
          };
          const tempPengajuan = {
            fPengajuanItemID: key,
            fPengajuanID: pengajuan.fID,
            fPengajuanName: pengajuan.fPengajuanName,
            fProjectID: project.fID,
            fProjectName: project.fProjectName,
            fTotal: item.fTotal,
          };
          temp.fPengajuanItem.push(tempPengajuan);

          res.push(temp);
          map.set(item.fID, i);
          i++;
        }
      }
    });
    setListDisplayedItem(res);
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleClickAddPengajuan = () => {
    setOpenPengajuan(true);
  };

  const validationForm = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!judulOrder.trim()) {
      validationErrors.title = "harus diisi";
    }
    if (!nomorNota.trim()) {
      validationErrors.nomor = "harus diisi";
    }
    if (!namaVendor.trim()) {
      validationErrors.nama = "harus diisi";
    }
    if (!ket.trim()) {
      validationErrors.ket = "harus diisi";
    }
    if (!image) {
      validationErrors.image = "pilih gambar";
    }
    if (!harga) {
      validationErrors.price = "harus diisi";
    }
    if (listDisplayedItem.length === 0) {
      validationErrors.item = "Pengajuan harus dipilih minimal 1";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0 && !loading) {
      handleSaveButton();
    }
  };

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  const handleSaveButton = async () => {
    setLoading(true);
    try {
      let cleanSelected = []
      listSelected.forEach((value, key) => {
        if (value.check) {
          cleanSelected.push(value)
        }
      });

      const base64File = await blobToBase64(image);
      const tempFile = {
        file: base64File,
        ext: getFileExtension(image.name),
        size: image.size,
        type: image.type
      }

      const bodyData = {
        fOrderName: judulOrder,
        fPONumber: nomorNota,
        fVendorName: namaVendor,
        fPrice: harga,
        fDateTime: jatuhTempo,
        fKet: ket,
        fPengajuan: JSON.stringify(listDisplayedItem),
        fPengajuanData: cleanSelected,
        fFile: tempFile
      }

      await axiosJWT.post(
        `${API_URL}/addpembelian`,
        bodyData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      navigate(-1);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        minHeight: "100vh",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: pembelianlogo,
          title: "Tambah Pembelian",
          name: user.fName,
          job: user.fRoleAlias,
          type: "Add",
          update: false,
          delete: false,
        }}
      />

      <div className="AddNotulenContainer" style={{ paddingTop: "140px" }}>
        <div className="AddNotulenInput">
          <text>
            Judul Order
            {errors.title && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.title}
              </span>
            )}
          </text>
          <input
            value={judulOrder}
            onChange={(v) => {
              setJudulOrder(v.target.value);
            }}
            className="AddNotulenInputText"
            type="text"
            placeholder="Judul"
          />
        </div>

        <div className="AddNotulenInput">
          <text>
            Nomor Nota/PO
            {errors.nomor && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.nomor}
              </span>
            )}
          </text>
          <input
            value={nomorNota}
            onChange={(v) => {
              setNomorNota(v.target.value);
            }}
            className="AddNotulenInputText"
            type="text"
            placeholder="Nomor"
          />
        </div>

        <div className="AddNotulenInput">
          <text>
            Nama Vendor
            {errors.nama && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.nama}
              </span>
            )}
          </text>
          <input
            value={namaVendor}
            onChange={(v) => {
              setNamaVendor(v.target.value);
            }}
            className="AddNotulenInputText"
            type="text"
            placeholder="Nama Vendor"
          />
        </div>

        <div className="AddNotulenInput">
          <text>
            Jumlah Harga
            {errors.price && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.price}
              </span>
            )}
          </text>
          <input
            value={harga}
            onChange={(v) => {
              setHarga(v.target.value);
            }}
            className="AddNotulenInputText"
            type="number"
            placeholder="Harga"
          />
        </div>

        <div className="AddNotulenInput">
          <text>Jatuh Tempo</text>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ width: "50%" }}>
              <DatePicker
                format="DD/MM/YYYY"
                disablePast={true}
                value={jatuhTempo}
                defaultValue={jatuhTempo}
                onChange={(v) => {
                  setJatuhTempo(v);
                }}
              />
            </div>
          </LocalizationProvider>
        </div>

        <div className="AddNotulenInput">
          <text>
            Foto Dokumen
            {errors.image && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.image}
              </span>
            )}
          </text>
          <div>
            {image ? (
              <img
                onClick={() => handleImageClick()}
                style={{
                  width: "300px",
                  height: "150px",
                  objectFit: "cover",
                  backgroundColor: "white",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  boxSizing: "border-box",
                  borderRadius: 7,
                }}
                src={URL.createObjectURL(image)}
                alt=""
              />
            ) : (
              <div
                onClick={() => handleImageClick()}
                style={{
                  width: "300px",
                  height: "150px",
                  objectFit: "cover",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  boxSizing: "border-box",
                  backgroundColor: "white",
                  borderRadius: 7,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <img
                  style={{ width: "27px", height: "27px" }}
                  src={file}
                  alt=""
                />
                <text style={{ textAlign: "center" }}>
                  <span style={{ color: "#818CF8" }}>click to browse</span>
                </text>
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
        </div>

        <div className="AddNotulenInput">
          <text>
            Keterangan
            {errors.ket && (
              <span
                style={{ color: "red", fontSize: "14px", fontWeight: "normal" }}
              >
                {" "}
                *{errors.ket}
              </span>
            )}
          </text>
          <div className="AddNotulenInputNotes">
            <textarea
              style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
              minLength={150}
              value={ket}
              onChange={(v) => {
                setKet(v.target.value);
              }}
              className="AddNotulenInputNote"
              type="text"
              placeholder="Keterangan"
            ></textarea>
          </div>
        </div>

        <button
          onClick={handleClickAddPengajuan}
          style={{
            backgroundColor: "transparent",
            borderWidth: 0,
            fontSize: "16px",
            margin: "20px 0",
            marginBottom: "40px",
          }}
        >
          <img
            style={{ width: "25px", marginRight: "10px" }}
            src={plusicon}
            alt=""
          />
          <text style={{ color: "#D0A360", fontWeight: "bold" }}>
            Pengajuan Terkait
          </text>
        </button>

        <div style={{ width: "90%" }}>
          {listDisplayedItem.map((data) => {
            return (
              <div className="ModalListPengajuanFilterPengajuanCard">
                <div className="ModalListPengajuanFilterPengajuanCardHeader">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <img
                      style={{
                        width: "85px",
                        height: "85px",
                        borderRadius: 7,
                      }}
                      src={`${API_URL}/files/${data.fItemImage.filename}`}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        gap: "3px",
                        flex: 1,
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        paddingTop: "5px",
                      }}
                    >
                      <text style={{ fontWeight: "bold" }}>
                        {data.fItemName}
                      </text>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "end",
                        }}
                      >
                        <text>Total</text>
                        <text style={{ fontWeight: "bold", fontSize: "18px" }}>
                          {numberWithCommas(data.fTotal)} {data.fDenom}
                        </text>
                      </div>
                    </div>
                  </div>
                </div>

                {data.fPengajuanItem.map((v) => {
                  return (
                    <div className="ModalListPengajuanFilterPengajuanCardList">
                      <div
                        style={{
                          height: "1px",
                          width: "100%",
                          backgroundColor: "#dddddd",
                          marginBottom: "10px",
                        }}
                      />
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingRight: "15px",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <text style={{ fontWeight: "bold" }}>
                              {v.fPengajuanName}
                            </text>
                            <text>{v.fProjectName}</text>
                          </div>
                          <text
                            style={{ fontWeight: "bold", fontSize: "18px" }}
                          >
                            {numberWithCommas(v.fTotal)} {data.fDenom}
                          </text>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}

          <div className="AddNotulenInput">
            {errors.item && (
              <span
                style={{
                  color: "red",
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
              >
                {" "}
                *{errors.item}
              </span>
            )}
          </div>
        </div>

        <div style={{ display: "flex", width: "95%" }}>
          <div
            onClick={validationForm}
            className="AddProgressButton"
            style={{ opacity: loading ? "0.7" : "1" }}
          >
            {loading && <span>Loading...</span>}
            {!loading && <span>Simpan</span>}
          </div>
        </div>
      </div>
      <div style={{ height: "30px" }} />
      {openPengajuan && (
        <ModalListPengajuan
          setOpenPengajuan={setOpenPengajuan}
          pengajuan={pengajuan}
          setPengajuan={setPengajuan}
          listSelected={listSelected}
          setListSelected={setListSelected}
          listbyItem={listbyItem}
          setListbyItem={setListbyItem}
          indexing={indexing}
          setIndexing={setIndexing}
        />
      )}
    </div>
  );
};

export default AddPembelian;
