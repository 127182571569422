import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";

import file from "../../assets/file.png";
import addicon from "../../assets/add.png";
import bin from "../../assets/bin.png";

import notulen from "../../assets/notulen.png";

import "@fontsource/poppins";

import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";
require("dayjs/locale/de");

const djs = dayjs().locale("de").format();

const UpdatePengajuanItems = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const { state } = useLocation();
  const { data } = state;

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(data.fItemImage);
  const [itemName, setItemName] = useState(data.fItemName);

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      if (
        response.data.user[0].fRole !== "BOSS" &&
        response.data.user[0].fRole !== "PM" &&
        response.data.user[0].fRole !== "FINANCE"
      ) {
        navigate("/");
      }
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const validationForm = (e) => {
    e.preventDefault();
    let validationError = "";

    if (!itemName.trim()) {
      validationError = "Harus diisi";
    }

    setError(validationError);

    if (!validationError.trim()) {
      handleSaveButton();
    }
  };

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  const handleSaveButton = async () => {
    setLoading(true);

    try {
      let bodyData = {}
      if (typeof image.filename !== "string") {
        const base64File = await blobToBase64(image);

        bodyData = {
          fID: data.fID,
          file: base64File,
          ext: getFileExtension(image.name),
          size: image.size,
          type: image.type,
          fItemName: itemName
        };
      }else{
        bodyData = {
          fID: data.fID,
          filename: image.filename,
          ext: getFileExtension(image.filename),
          size: image.size,
          type: image.type,
          fItemName: itemName
        }
      }

      await axiosJWT.post(
        `${API_URL}/updatepengajuanitem`,
        {
          data: bodyData
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1);
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  };

  const handleDeleteButton = async () => {
    setLoading(true);
    try {
      const response = await axiosJWT.post(
        `${API_URL}/deletepengajuanitem`,
        { fID: data.fID },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "130px",
        minHeight: "100vh",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: notulen,
          title: "Tambah Item",
          name: user.fName,
          job: user.fRoleAlias,
          type: "Add",
          update: false,
          delete: false,
        }}
      />

      <div className="AddNotulenContainer">
        <text
          style={{
            width: "100%",
            textAlign: "center",
            fontWeight: "bold",
            color: "#D0A360",
            fontSize: "20px",
            marginBottom: "20px",
          }}
        >
          Tambah Item
        </text>

        <div
          style={{
            backgroundColor: "#D7D7D7",
            borderRadius: 7,
            paddingLeft: "10px",
            position: "relative",
          }}
          className="AddNotulenContainer"
        >
          <div
            style={{ marginTop: "25px", width: "100%" }}
            className="AddNotulenInput"
          >
            <text style={{ fontWeight: "bold" }}>Gambar</text>
          </div>

          <div style={{ width: "100%" }}>
            {/* image input */}
            <div>
              <img
                onClick={() => handleImageClick()}
                style={{
                  width: "300px",
                  height: "150px",
                  objectFit: "cover",
                  backgroundColor: "white",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  boxSizing: "border-box",
                  borderRadius: 7,
                }}
                src={
                  typeof image.filename !== "string"
                    ? URL.createObjectURL(image)
                    : `${API_URL}/files/${image.filename}`
                }
                alt=""
              />
              <input
                type="file"
                accept="image/*"
                ref={inputRef}
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </div>

            <div style={{ marginTop: "25px" }} className="AddNotulenInput">
              <text style={{ fontWeight: "bold" }}>Nama Item{error && <span style={{ color: 'red', fontWeight: 'normal', fontSize: '14px' }}> *{error}</span>}</text>
              <input
                value={itemName}
                onChange={(v) => {
                  setItemName(v.target.value);
                }}
                className="AddNotulenInputText"
                type="text"
                placeholder="Item"
              />
            </div>
          </div>
        </div>

        <div style={{ display: "flex", width: "95%", marginTop: "30px" }}>
          <div
            onClick={() => {
              if (!loading) {
                handleDeleteButton();
              }
            }}
            className="AddProgressButton"
            style={{
              opacity: loading ? "0.7" : "1",
              marginBottom: "15px",
              backgroundColor: "red",
            }}
          >
            {loading && <span>Loading...</span>}
            {!loading && <span>Delete</span>}
          </div>
        </div>

        <div style={{ display: "flex", width: "95%" }}>
          <div
            onClick={validationForm}
            className="AddProgressButton"
            style={{ opacity: loading ? "0.7" : "1" }}
          >
            {loading && <span>Loading...</span>}
            {!loading && <span>Simpan</span>}
          </div>
        </div>
      </div>
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default UpdatePengajuanItems;
