import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const PembelianCard = ({ data }) => {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const handleClickTask = (v) => {
    navigate('/detailpembelian', { state: {fID: data.fID}});
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  return (
    <div onClick={() => handleClickTask(data)} className="Task_Container">
      <div style={{ height: '54px', marginTop: '10px' }}>
        <text style={{ fontSize: '18px', color: '#D0A360', fontWeight: 'bold' }}>{data.fOrderName}</text>
      </div>   
      <text style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '10px' }}>Nomor PO</text>
      <text style={{ fontSize: '16px', marginTop: '10px' }}>{data.fPONumber}</text>

      <text style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '10px' }}>Nama Vendor</text>
      <text style={{ fontSize: '16px', marginTop: '10px' }}>{data.fVendorName}</text>
      
      <text style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '10px' }}>Total</text>
      <text style={{ fontWeight: 'bold', fontSize: '25px', color: '#27A533' }}>Rp {numberWithCommas(data.fPrice)}</text>

      <text style={{ width: '100%', fontWeight: 'bold', fontSize: '16px', marginTop: '15px', textAlign: 'center' }}>Jatuh Tempo</text>

      <div style={{ color: '#D0A360', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '10px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '15px' }} >
        <text style={{ fontSize: '16px', fontWeight: 'bold'}}>{dayjs(data.fDateTime).format('DD MMM YYYY').toString()}</text>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginTop: '15px' }}>
      <img src={`${API_URL}/files/${data.fUser.fImages}`} alt="" style={{ width: '40px', height: '40px', marginRight: '10px', borderRadius: 100 }} />
        <text style={{ fontSize: '14px', fontWeight: 600 }}>{data.fUser.fName}</text>
      </div>
    </div>
  );
};

export default PembelianCard;
