import "./ProgressSPV.css";
import profile from '../../assets/profile.png'
import check from '../../assets/check.png'

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const ProgressSPV = ({ data }) => {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const handleOnClickProgressSPV = (v) => {
    navigate('/detailprogress', { state: {fID: v.fID}});
  }

  return (
    <div onClick={() => {handleOnClickProgressSPV(data)}} className="Progress_Container">
      <text style={{ fontSize: "14px" }}>{dayjs(data.fDateTime).format('DD MMM YYYY, HH:mm').toString()}</text>

      <img className="ProgressImage" src={`${API_URL}/files/${data.fProject.fImages[0].filename}`} alt="" style={{ marginTop: '10px', marginBottom: '10px' }} />
      <div style={{ height: '54px', overflow: 'hidden' }}>
        <text style={{ fontSize: '18px', color: '#D0A360', fontWeight: 'bold' }}>{data.fProject.fProjectName}</text>
      </div>      <div style={{ height: '10px' }} />
      <text style={{ fontWeight: 'bold', fontSize: '14px' }}>Notes:</text>
      <></>
      <div  className="ProgressNotesWrapper">
      <text className="ProgressNotes" style={{ overflow: "hidden", fontWeight: '', fontSize: '14px' }}>{data.fKet}</text>
        </div>

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginTop: '15px' }}>
      <img src={`${API_URL}/files/${data.fUser.fImages}`} alt="" style={{ width: '40px', height: '40px', marginRight: '10px', borderRadius: 100 }} />
        <text style={{ fontSize: '14px', fontWeight: 600 }}>{data.fUser.fName}</text>
      </div>

      <img style={{ width: data.fCheck === 'Y' ? '27px' : '0', position: 'absolute', top: '3px', right: '10px' }} src={check} alt=""/>
    </div>
  );
};

export default ProgressSPV;
