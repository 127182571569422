import { useNavigate } from "react-router-dom";
import "./ModalDatePicker.css";

import React, { useState } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const ModalDatePicker = ({ closeModalDatePicker }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [data, setData] = useState([])
  const [startDate, setStartDate] = useState(dayjs(new Date().setHours(0, 0, 0, 0)));
  const [endDate, setEndDate] = useState(dayjs(new Date().setHours(23, 59, 59, 999)));

  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  axios.defaults.withCredentials = true;

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleClickRecap = async () => {
    setIsLoading(true)
    try {
        const response = await axiosJWT.post(
            `${API_URL}/getrekapabsensi`,
            { startDate, endDate },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        navigate('/rekapabsensi', { state: {data: response.data, dateStart: dayjs(startDate).format("DD MMM YYYY").toString(), dateFinish: dayjs(endDate).format("DD MMM YYYY").toString()} })
    } catch (error) {
      setIsLoading(false)
        console.log(error)
    }
  }

  return (
    <div className="modalDatePickerBackground">
      <div className="modalDatePickerContainerBackground">
        <text className="modalDatePickerTitle">PILIH TANGGAL</text>
        <text className="modalDatePickerSegmen">Mulai</text>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div>
            <DatePicker
              format="DD/MM/YYYY"
              value={startDate}
              defaultValue={startDate}
              onChange={(v) => {
                setStartDate(v);
              }}
              DialogProps={{
                style: { zIndex: 1350 },
              }}
            />
          </div>
        </LocalizationProvider>

        <text style={{ marginTop: "25px" }} className="modalDatePickerSegmen">
          Sampai
        </text>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div>
            <DatePicker
              format="DD/MM/YYYY"
              value={endDate}
              minDate={startDate}
              defaultValue={endDate}
              disableFuture={true}
              onChange={(v) => {
                setEndDate(v);
              }}
              DialogProps={{
                style: { zIndex: 1350 },
              }}
            />
          </div>
        </LocalizationProvider>

        <div className="modalDatePickerButtonContainer">
          <button disabled={isLoading} onClick={handleClickRecap} style={{ backgroundColor: isLoading ? '#515151' : '#00AA86', borderRadius: 5 }} className="modalDatePickerButton">{isLoading ? 'Loading...' : 'Rekap'}</button>
          <button disabled={isLoading} onClick={() => { closeModalDatePicker(false) }} style={{ backgroundColor: 'red', borderRadius: 5 }} className="modalDatePickerButton">Batal</button>
        </div>
      </div>
    </div>
  );
};

export default ModalDatePicker;
