import { useEffect, useState } from "react";
import down from "../../assets/down.png";
import "./Dropdown.css";

const Dropdown = ({selected, setSelected, setSelectedData, options, type}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="dropdown">
      <div className="dropdown-btn" onClick={(e) => setIsActive(!isActive)}>
        {selected}
        <img className="dropdown-icon" src={down} alt=""></img>
      </div>

      {isActive && (
        <div className="dropdown-content">
          {options.map((option) => (
            <div
              onClick={(e) => {
                setSelected(option[type]);
                setSelectedData(option);
                setIsActive(false);
              }}
              className="dropdown-item"
            >
              {option[type]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
