import "./PembayaranCard.css";
import profile from '../../assets/profile.png'

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const PembayaranCard = ({ data }) => {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const handleClickNotulen = (v) => {
    navigate('/detailpembayaran', { state: {fID: v.fID}});
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  return (
    <div onClick={() => handleClickNotulen(data)} className="Pembayaran_Container">
        <div style={{ borderRadius: 100 }} className="PembayaranCardContent PembayaranCategory bold">
            <text style={{ textAlign: 'center', fontSize: '16px', width: '90%', height: '24px', overflow: 'hidden' }}>{data.fCategory} AUSHDASASDASODIJI</text>
        </div>
        <text className="PembayaranCardTitle">{data.fPaymentName} AAAAAAAAA</text>
        <text className="PembayaranCardDate">{dayjs(data.fCreatedDate).format('DD MMM YYYY').toString()}</text>
        <text className="PembayaranCardContent bold">Keterangan:</text>
        <div style={{ height: '90px' }}>
        <text style={{ marginTop: 0 }} className="PembayaranCardContent PembayaranKeterangan">{data.fKet}</text>
        </div>
        <text className="PembayaranCardContent PembayaranPrice">Rp {numberWithCommas(data.fPrice)}</text>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginTop: '15px' }}>
        <img src={`${API_URL}/files/${data.fUser.fImages}`} alt="" style={{ width: '40px', height: '40px', marginRight: '10px', borderRadius: 100 }} />
        <text style={{ fontSize: '14px', fontWeight: 600 }}>{data.fUser.fName}</text>
      </div>
    </div>
  );
};

export default PembayaranCard;
