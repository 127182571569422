import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import profile from "../../assets/profile.png";

import "./DetailProfile.css";
import "@fontsource/poppins";

import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Dropdown from "../../Components/Dropdown/Dropdown";

const DetailProfile = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const { state } = useLocation();
  const { prev } = state;

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });

  const [profile, setProfile] = useState({
    fID: '',
    fRole: ''
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    if (profile.fRole !== "BOSS" && profile.fRole !== "CLIENT" && profile.fRole) {
      getAbsensi();
    }
  }, [profile]);

  useEffect(() => {
    if(profile.fID !== ""){
      getUser();
    }
  }, [profile]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 1,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      setProfile(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getUser = async () => {
    const id = prev === "modal" ? profile.fID : state.fID;

    try {
      const response = await axiosJWT.post(
        `${API_URL}/getuser`,
        { fID: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUser(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 426px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const DetailProfileSmall = () => {
    const [username, SetUsername] = useState(user.fUserName);
    const [email, setEmail] = useState(user.fEmail);
    const [image, setImage] = useState(user.fImages);
    const [name, setName] = useState(user.fName);
    const [roleAlias, setRoleAlias] = useState(user.fRoleAlias);
    const [address, setAddress] = useState(user.fAddress);
    const [phonenumber, setPhonenumber] = useState(user.fPhoneNumber);

    const [errors, setErrors] = useState({});
    const [msg, setMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const handleImageClick = () => {
      inputRef.current.click();
    };
    const handleImageChange = (event) => {
      const file = event.target.files[0];
      setImage(file);
    };

    const validationForm = (e) => {
      e.preventDefault();
      const validationErrors = {};
      if (!username.trim()) {
        validationErrors.username = "harus diisi";
      }

      if (!name.trim()) {
        validationErrors.name = "harus diisi";
      }

      if (!email.trim()) {
        validationErrors.email = "harus diisi";
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        validationErrors.email = "tidak valid";
      }

      if (!phonenumber.trim()) {
        validationErrors.phone = "harus diisi";
      }

      if (!address.trim()) {
        validationErrors.address = "harus diisi";
      }

      setErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0) {
        handleSaveButton();
      }
    };

    function blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    }

    const getFileExtension = (filename) => {
      return filename.split(".").pop();
    };

    const handleSaveButton = async () => {
      setLoading(true);

      try {
        let tempImage = "";

        if (typeof image !== "string") {
          const base64File = await blobToBase64(image);
          tempImage = base64File;
          tempImage = {
            file: base64File,
            ext: getFileExtension(image.name),
          };
        } else {
          tempImage = image;
        }

        const bodyData = {
          fID: user.fID,
          fUserName: username,
          fEmail: email,
          fImages: tempImage,
          fName: name,
          fRoleAlias: roleAlias,
          fAddress: address,
          fPhoneNumber: phonenumber,
        };

        await axiosJWT.post(`${API_URL}/updateuser`, bodyData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        navigate(-1);
      } catch (error) {
        setLoading(false);
        setMsg(error.response.data.msg);
        console.log(error);
      }
    };

    const onHandleClickChangePassword = () => {
      navigate("/changepassword", {state: { fID: prev === 'modal' ? profile.fID : state.fID }});
    };

    return (
      <div
        style={{
          backgroundColor: "#F3F4F8",
          fontFamily: "Poppins",
          color: "#282828",
          minHeight: "100vh",
          paddingTop: "152px",
          paddingBottom: "25px",
        }}
      >
        <Navbar profileData={profile} />

        <div className="DetailProfileContainer">
          {/* image input */}
          <div onClick={handleImageClick}>
            <img
              style={{
                width: "150px",
                height: "150px",
                objectFit: "cover",
                borderRadius: 100,
              }}
              src={
                typeof image !== "string"
                  ? URL.createObjectURL(image)
                  : `${API_URL}/files/${image}`
              }
              alt=""
            />
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>

          <div className="DetailProfileUneditableContainer">
            {/* Username */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">
                UserName
                {errors.username && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    *{errors.username}
                  </span>
                )}
              </text>
              <div />
              <input
                value={username}
                onChange={(v) => {
                  SetUsername(v.target.value);
                }}
                className="DetailProfileInputSmall"
                type="text"
                placeholder="username"
              />
            </div>

            {/* Email */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">
                Email
                {errors.email && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    *{errors.email}
                  </span>
                )}
              </text>
              <div />
              <input
                value={email}
                onChange={(v) => {
                  setEmail(v.target.value);
                }}
                className="DetailProfileInputSmall"
                type="text"
                placeholder="john@gmail.com"
              />
            </div>

            {/* Name */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">
                Nama
                {errors.name && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    *{errors.name}
                  </span>
                )}
              </text>
              <div />
              <input
                value={name}
                onChange={(v) => {
                  setName(v.target.value);
                }}
                className="DetailProfileInputSmall"
                type="text"
                placeholder="John"
              />
            </div>

            {/* Phone Number */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">
                No Telp
                {errors.phone && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    *{errors.phone}
                  </span>
                )}
              </text>
              <div />
              <input
                value={phonenumber}
                onChange={(v) => {
                  setPhonenumber(v.target.value);
                }}
                className="DetailProfileInputSmall"
                type="number"
                placeholder="08123123123"
              />
            </div>

            {/* Role Alias */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">Role Alias</text>
              <div />
              <text>{roleAlias}</text>
            </div>

            {/* Alamat */}
            <div className="DetailProfileUneditable">
              <text className="DetailProfileLabel">
                Alamat
                {errors.address && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    *{errors.address}
                  </span>
                )}
              </text>
              <div />
              <textarea
                value={address}
                onChange={(v) => {
                  setAddress(v.target.value);
                }}
                className="DetailProfileInputBig"
                type="text"
                style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
                placeholder="Alamat"
              />
            </div>

            <div className="DetailProfileUneditable">
              {msg && (
                <span
                  style={{
                    color: "red",
                    fontSize: "14px",
                    fontWeight: "normal",
                  }}
                >
                  {" "}
                  *{msg}
                </span>
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "80%",
              gap: "15px",
              marginTop: "25px",
            }}
          >
            <div
              onClick={validationForm}
              className="DetaiProfileButtonSmall1"
              style={{
                borderRadius: "2px",
                fontSize: "14px",
                height: "50px",
                color: "white",
                backgroundColor: "#D1A361",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                opacity: loading ? "0.7" : "1",
              }}
            >
              {loading && <span>Loading...</span>}
              {!loading && <span>Simpan</span>}
            </div>
            {(user.fRole === "BOSS" || user.fRole === "PM") && (
              <div
                onClick={onHandleClickChangePassword}
                className="DetaiProfileButtonSmall2"
                style={{
                  borderRadius: "2px",
                  fontSize: "14px",
                  height: "50px",
                  color: "white",
                  backgroundColor: "#FF5F5F",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <text style={{ textAlign: "center" }}>Ubah Password</text>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const DetailProfileBig = () => {
    const [username, SetUsername] = useState(user.fUserName);
    const [email, setEmail] = useState(user.fEmail);
    const [image, setImage] = useState(user.fImages);
    const [name, setName] = useState(user.fName);
    const [roleAlias, setRoleAlias] = useState(user.fRoleAlias);
    const [address, setAddress] = useState(user.fAddress);
    const [phonenumber, setPhonenumber] = useState(user.fPhoneNumber);

    const [msg, setMsg] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleImageClick = () => {
      inputRef.current.click();
    };
    const handleImageChange = (event) => {
      const file = event.target.files[0];
      setImage(file);
    };

    const validationForm = (e) => {
      e.preventDefault();
      const validationErrors = {};
      if (!username.trim()) {
        validationErrors.username = "harus diisi";
      }

      if (!name.trim()) {
        validationErrors.name = "harus diisi";
      }

      if (!email.trim()) {
        validationErrors.email = "harus diisi";
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        validationErrors.email = "tidak valid";
      }

      if (!phonenumber.trim()) {
        validationErrors.phone = "harus diisi";
      }

      if (!address.trim()) {
        validationErrors.address = "harus diisi";
      }

      setErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0) {
        handleSaveButton();
      }
    };

    function blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    }

    const getFileExtension = (filename) => {
      return filename.split(".").pop();
    };

    const handleSaveButton = async () => {
      setLoading(true);

      try {
        let tempImage = "";

        if (typeof image !== "string") {
          const base64File = await blobToBase64(image);
          tempImage = base64File;
          tempImage = {
            file: base64File,
            ext: getFileExtension(image.name),
          };
        } else {
          tempImage = image;
        }

        const bodyData = {
          fID: user.fID,
          fUserName: username,
          fEmail: email,
          fImages: tempImage,
          fName: name,
          fRoleAlias: roleAlias,
          fAddress: address,
          fPhoneNumber: phonenumber,
        };

        await axiosJWT.post(`${API_URL}/updateuser`, bodyData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        navigate(-1);
      } catch (error) {
        setLoading(false);
        setMsg(error.response.data.msg);
        console.log(error);
      }
    };

    const onHandleClickChangePassword = () => {
      navigate("/changepassword", {state: { fID: prev === 'modal' ? profile.fID : state.fID }});
    };

    return (
      <div
        style={{
          backgroundColor: "#F3F4F8",
          fontFamily: "Poppins",
          color: "#282828",
          minHeight: "100vh",
          paddingTop: "152px",
          paddingBottom: "25px",
        }}
      >
        <Navbar profileData={profile} />

        <div className="DetailProfileContainer">
          {/* image input */}
          <div onClick={handleImageClick}>
            <img
              style={{
                width: "150px",
                height: "150px",
                objectFit: "cover",
                borderRadius: 100,
              }}
              src={
                typeof image !== "string"
                  ? URL.createObjectURL(image)
                  : `${API_URL}/files/${image}`
              }
              alt=""
            />
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>

          <div className="DetailProfileUneditableContainer">
            {/* Username */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">UserName</text>
                {errors.username && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    *{errors.username}
                  </span>
                )}
              </div>
              <div className=" DetailProfileInputSizeRight2">
                <input
                  value={username}
                  onChange={(v) => {
                    SetUsername(v.target.value);
                  }}
                  style={{ marginTop: 0 }}
                  className="DetailProfileInputSmall"
                  type="text"
                  placeholder="username"
                />
              </div>
            </div>

            {/* Email */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">Email</text>
                {errors.email && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    *{errors.email}
                  </span>
                )}
              </div>
              <div className=" DetailProfileInputSizeRight2">
                <input
                  value={email}
                  onChange={(v) => {
                    setEmail(v.target.value);
                  }}
                  style={{ marginTop: 0 }}
                  className="DetailProfileInputSmall"
                  type="text"
                  placeholder="john@gmail.com"
                />
              </div>
            </div>

            {/* Nama */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">Nama</text>
                {errors.name && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    *{errors.name}
                  </span>
                )}
              </div>
              <div className=" DetailProfileInputSizeRight2">
                <input
                  value={name}
                  onChange={(v) => {
                    setName(v.target.value);
                  }}
                  style={{ marginTop: 0 }}
                  className="DetailProfileInputSmall"
                  type="text"
                  placeholder="John"
                />
              </div>
            </div>

            {/* No Telp */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">No Telp</text>
                {errors.phone && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    *{errors.phone}
                  </span>
                )}
              </div>
              <div className=" DetailProfileInputSizeRight2">
                <input
                  value={phonenumber}
                  onChange={(v) => {
                    setPhonenumber(v.target.value);
                  }}
                  style={{ marginTop: 0 }}
                  className="DetailProfileInputSmall"
                  type="number"
                  placeholder="08123123123"
                />
              </div>
            </div>

            {/* Role Alias */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">Role Alias</text>
              </div>
              <div className=" DetailProfileInputSizeRight2">
                <text>{roleAlias}</text>
              </div>
            </div>

            {/* Alamat */}
            <div className="DetailProfileUneditable2">
              <div className=" DetailProfileInputSizeLeft">
                <text className="DetailProfileLabel">Alamat</text>
                {errors.address && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    *{errors.address}
                  </span>
                )}
              </div>
              <div className=" DetailProfileInputSizeRight2">
                <textarea
                  value={address}
                  onChange={(v) => {
                    setAddress(v.target.value);
                  }}
                  className="DetailProfileInputBig"
                  type="text"
                  style={{ fontFamily: "Poppins", marginTop: 0 }}
                  placeholder="Alamat"
                />
              </div>
            </div>
          </div>

          {msg && (
            <span style={{ color: "red", width: "80%", marginTop: "25px" }}>
              *{msg}
            </span>
          )}

          <div
            style={{
              width: "80%",
              display: "flex",
              flexDirection: "row",
              marginTop: "10px",
            }}
          >
            <div
              onClick={validationForm}
              className="DetailProfileCalcLeft"
              style={{
                width: "180px",
                borderRadius: "2px",
                fontSize: "16px",
                height: "50px",
                marginRight: "15px",
                color: "white",
                backgroundColor: "#D1A361",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                opacity: loading ? "0.7" : "1",
              }}
            >
              {loading && <span>Loading...</span>}
              {!loading && <span>Simpan</span>}
            </div>

            {(user.fRole === "BOSS" || user.fRole === "PM") && (
              <div
                onClick={onHandleClickChangePassword}
                style={{
                  width: "180px",
                  borderRadius: "2px",
                  fontSize: "16 px",
                  height: "50px",
                  color: "white",
                  backgroundColor: "#FF5F5F",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <text style={{ textAlign: "center" }}>Ubah Password</text>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {!matches && <DetailProfileSmall />}
      {matches && <DetailProfileBig />}
    </div>
  );
};

export default DetailProfile;
