import { useSpring, animated } from "react-spring";
import profile from "../../assets/profile.png";

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import "./PotentialClientCard.css";

const PotentialClientCard = ({ data, user }) => {
  const navigate = useNavigate();
  const AnimatedDiv = animated.div;
  const API_URL = process.env.REACT_APP_API_URL;

  const gaugeProgress = useSpring({
    value: data.fProgressPercentage,
    from: { value: 0 },
    config: {
      duration: 500,
    },
  });

  const handleClickClient = (v) => {
    if (user.fRole === "PM" || user.fRole === "BOSS" || user.fRole === 'MARKETING') {
      navigate("/detailclient", { state: { fID: v.fID } });
    }
  };

  return (
    <div
      onClick={() => handleClickClient(data)}
      className="PotentialClientCard_Container"
    >
      <div
        style={{
          width: "100%",
          aspectRatio: "202/125",
          borderRadius: "7px 7px 0 0",
          backgroundColor: "#D0A360",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{ width: "50%", aspectRatio: "1/1", borderRadius: 1000, backgroundColor: 'white' }}
          src={data.fImages ? `${API_URL}/files/${data.fImages}` : profile}
          alt={''}
        />
      </div>
      <div
        style={{
          width: "90%",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
          marginTop: "15px",
        }}
      >
        <text
          className="PotentialClientCard_NotulenNotes"
          style={{
            fontWeight: "bold",
            textAlign: "center",
            fontSize: "16px",
            width: "100%",
            color: '#D0A360'
          }}
        >
          {data.fName}
        </text>
        <text
          className="PotentialClientCardTitle"
          style={{ fontSize: "14px", textAlign: "center", width: "100%", height: '50px', color: '#8C8C8C' }}
        >
          {data.fProjectName}
        </text>
      </div>

      <div
        style={{
          width: "90%",
          margin: "20px auto",
          display: "flex",
          flexDirection: "column",
          gap: "7px",
        }}
      >
        <text style={{ fontSize: "14px", fontWeight: "bold" }}>Marketing:</text>
        <div>
          <img
            style={{ width: "35px", height: "35px", borderRadius: 1000 }}
            src={data.fMarketing.fImages ? `${API_URL}/files/${data.fMarketing.fImages}` : profile}
            alt=""
          />
          <text style={{ fontSize: '14px', color: '#8C8C8C', marginLeft: '7px' }}>{data.fMarketing.fName}</text>
        </div>
      </div>
    </div>
  );
};

export default PotentialClientCard;
