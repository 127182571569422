import { useNavigate } from "react-router-dom";
import "./ModalProfile.css";

import React from "react";
import axios from "axios";

const ModalProfile = ({ role, closeModalProfile }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  axios.defaults.withCredentials = true

  const handleLogOutButton = async () => {
    try {
      await axios.delete(`${API_URL}/logout`);
      navigate('/login');
    } catch (error){
      console.log(error);
    }
  }

  return (
    <div onClick={() => {closeModalProfile(false)}} className="modalProfileBackground">
      <div className="modalProfileContainerPositioner">
        <div className="modalProfileContainer">
          <div onClick={() => navigate('/profile', {state: { prev: 'modal' }})} className="ModalProfileButton">
            <text style={{ fontSize: '12px' }}>My Account</text>
          </div>

          {
            (role === 'BOSS' || role === 'PM') &&
          <div onClick={() => navigate('/alluser')} className="ModalProfileButton">
            <text style={{ fontSize: '12px' }}>View User</text>
          </div>
          }
          <div className="ModalProfileDividerLogOut" />
          <div onClick={() => { handleLogOutButton() }} className="ModalProfileButton">
          <text style={{ fontSize: '12px', color: '#FF5F5F' }}>LogOut</text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalProfile;
