import profile from "../../assets/profile.png";
import check from "../../assets/check.png";

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import "./ModalListPengajuan.css";
import { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";

const ModalListPengajuan = ({
  setOpenPengajuan,
  pengajuan,
  setPengajuan,
  listSelected,
  setListSelected,
  listbyItem,
  setListbyItem,
  indexing,
  setIndexing
}) => {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const [filter, setFilter] = useState("Pengajuan");
  const [listPengajuan, setListPengajuan] = useState(pengajuan.map(item => ({ ...item })));
  const [selected, setSelected] = useState(listSelected)
  const [listItemFiltered, setListItemFiltered] = useState(listbyItem.map(item => ({ ...item })));

  useEffect(() => {
    if(listItemFiltered.length === 0){
      itemFilter();
    }    
  }, []);

  const itemFilter = () => {
    let res = [];
    let map = new Map();
    let i = 0;

    listPengajuan.map((v, vIndex) => {
      v.fPengajuanItem.map((item, itemIndex) => {
        let tempData = { ...item };
        if (map.has(item.fItem.fID)) {
          tempData.fPengajuanName = v.fPengajuanName;
          tempData.fProjectName = v.fProject.fProjectName;

          res[map.get(item.fItem.fID)].fPengajuan.push(tempData);
          res[map.get(item.fItem.fID)].fTotal =
            res[map.get(item.fItem.fID)].fTotal + tempData.fQuantity;
        } else {
          map.set(item.fItem.fID, i);
          let temp = {
            fID: tempData.fItem.fID,
            fItemName: tempData.fItem.fItemName,
            fTotal: tempData.fQuantity,
            fSelected: 0,
            fDenom: tempData.fDenom,
            fPengajuan: [],
          };

          tempData.fPengajuanName = v.fPengajuanName;
          tempData.fProjectName = v.fProject.fProjectName;
          temp.fPengajuan.push(tempData);

          res[map.get(item.fItem.fID)] = temp;
          i++;
        }

        indexing[item.fID] = {
          pengajuan: { i: vIndex, j: itemIndex },
          item: {
            i: map.get(item.fItem.fID),
            j: res[map.get(item.fItem.fID)].fPengajuan.length,
          },
        };
      });
    });
    setListItemFiltered(res);
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const onHandleClickFilterPengajuan = () => {
    setFilter("Pengajuan");
  };

  const onHandleClickFilterItem = () => {
    setFilter("Item");
  };

  const onHandleClickCheckAllPengajuan = (check, data) => {
    const newMap = new Map(selected);
    const copyListItem = [...listItemFiltered];
    const copyListPengajuan = [...listPengajuan];

    for (let i = 0; i < copyListPengajuan.length; i++) {
      if (copyListPengajuan[i].fID === data.fID) {
        for (let j = 0; j < copyListPengajuan[i].fPengajuanItem.length; j++) {
          newMap.set(copyListPengajuan[i].fPengajuanItem[j].fID, {
            check: check,
            fID: copyListPengajuan[i].fPengajuanItem[j].fID,
            fItem: {
              fID: copyListPengajuan[i].fPengajuanItem[j].fItem.fID,
              fItemName: copyListPengajuan[i].fPengajuanItem[j].fItem.fItemName,
              fItemImage:
                copyListPengajuan[i].fPengajuanItem[j].fItem.fItemImage,
              fTotal: copyListPengajuan[i].fPengajuanItem[j].fQuantity,
              fDenom: copyListPengajuan[i].fPengajuanItem[j].fDenom
            },
            fProject: {
              fID: copyListPengajuan[i].fProject.fID,
              fProjectName: copyListPengajuan[i].fProject.fProjectName,
            },
            fPengajuan: {
              fID: copyListPengajuan[i].fPengajuanItem[j].fPengajuanID,
              fPengajuanName: copyListPengajuan[i].fPengajuanName,
            },
          });
          
          const indexItem = indexing[copyListPengajuan[i].fPengajuanItem[j].fID].item;

          if(check) {
            if(selected.get(copyListPengajuan[i].fPengajuanItem[j].fID).check === false){
              copyListItem[indexItem.i].fSelected =
              copyListItem[indexItem.i].fSelected + copyListPengajuan[i].fPengajuanItem[j].fQuantity;
            }
          }else {
            if(selected.get(copyListPengajuan[i].fPengajuanItem[j].fID).check === true){
              copyListItem[indexItem.i].fSelected =
              copyListItem[indexItem.i].fSelected - copyListPengajuan[i].fPengajuanItem[j].fQuantity;
            }
          }
        }

        if(check) {
          copyListPengajuan[i].fSelected = copyListPengajuan[i].fPengajuanItem.length;
        }else{
          copyListPengajuan[i].fSelected = 0;
        }
        break;
      }
    }

    setSelected(newMap);
    setListPengajuan(copyListPengajuan);
  };

  const onHandleClickCheckAllItem = (check, data) => {
    const newMap = new Map(selected);
    const temp = [...listItemFiltered];
    const copyListPengajuan = [...listPengajuan];

    if (check) {
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].fID === data.fID) {
          temp[i].fCheck = check;
          temp[i].fSelected = 0;
          for (let j = 0; j < temp[i].fPengajuan.length; j++) {
            newMap.set(temp[i].fPengajuan[j].fID, {
              check: check,
              fID: temp[i].fPengajuan[j].fID,
              fItem: {
                fID: temp[i].fPengajuan[j].fItem.fID,
                fItemName: temp[i].fPengajuan[j].fItem.fItemName,
                fItemImage: temp[i].fPengajuan[j].fItem.fItemImage,
                fTotal: temp[i].fPengajuan[j].fQuantity,
                fDenom: temp[i].fPengajuan[j].fDenom
              },
              fProject: {
                fID: temp[i].fPengajuan[j].fProjectID,
                fProjectName: temp[i].fPengajuan[j].fProjectName,
              },
              fPengajuan: {
                fID: temp[i].fPengajuan[j].fPengajuanID,
                fPengajuanName: temp[i].fPengajuan[j].fPengajuanName,
              },
            });
            temp[i].fSelected =
              temp[i].fSelected + temp[i].fPengajuan[j].fQuantity;

              const indexPengajuan = indexing[temp[i].fPengajuan[j].fID].pengajuan;

              if(selected.get(copyListPengajuan[indexPengajuan.i].fPengajuanItem[indexPengajuan.j].fID).check === false){
                copyListPengajuan[indexPengajuan.i].fSelected++;
              }
          }
          break;
        }
      }
    } else {
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].fID === data.fID) {
          temp[i].fCheck = check;
          temp[i].fSelected = 0;
          for (let j = 0; j < temp[i].fPengajuan.length; j++) {
            newMap.set(temp[i].fPengajuan[j].fID, {
              check: check,
              fID: temp[i].fPengajuan[j].fID,
              fItem: {
                fID: temp[i].fPengajuan[j].fItem.fID,
                fItemName: temp[i].fPengajuan[j].fItem.fItemName,
                fItemImage: temp[i].fPengajuan[j].fItem.fItemImage,
                fTotal: temp[i].fPengajuan[j].fQuantity,
                fDenom: temp[i].fPengajuan[j].fDenom
              },
              fProject: {
                fID: temp[i].fPengajuan[j].fProjectID,
                fProjectName: temp[i].fPengajuan[j].fProjectName,
              },
              fPengajuan: {
                fID: temp[i].fPengajuan[j].fPengajuanID,
                fPengajuanName: temp[i].fPengajuan[j].fPengajuanName,
              },
            });
            temp[i].fSelected = 0;

            const indexPengajuan = indexing[temp[i].fPengajuan[j].fID].pengajuan;

            if(selected.get(copyListPengajuan[indexPengajuan.i].fPengajuanItem[indexPengajuan.j].fID).check === true){
              copyListPengajuan[indexPengajuan.i].fSelected--;
            }
          }
          break;
        }
      }
    }

    setSelected(newMap);
    setListItemFiltered(temp);
  };

  const onHandleclickCheckItemPengajuan = (check, value, projectname) => {
    const newMap = new Map(selected);
    const copyListItem = [...listItemFiltered];
    const copyListPengajuan = [...listPengajuan];
    newMap.set(value.fID, {
      check: check,
      fID: value.fID,
      fItem: {
        fID: value.fItem.fID,
        fItemName: value.fItem.fItemName,
        fItemImage: value.fItem.fItemImage,
        fTotal: value.fQuantity,
        fDenom: value.fDenom
      },
      fProject: { fID: value.fProjectID, fProjectName: projectname },
      fPengajuan: {
        fID: value.fPengajuanID,
        fPengajuanName: value.fPengajuanName,
      },
    });

    const indexItem = indexing[value.fID].item;
    if (check) {
      // itemCheck
      copyListItem[indexItem.i].fSelected =
        copyListItem[indexItem.i].fSelected + value.fQuantity;

      // pengajuanCheck
      for (let z = 0; z < copyListPengajuan.length; z++) {
        if (copyListPengajuan[z].fID === value.fPengajuanID) {
          copyListPengajuan[z].fSelected++;
          break;
        }
      }
    } else {
      //itemCheck
      copyListItem[indexItem.i].fSelected =
        copyListItem[indexItem.i].fSelected - value.fQuantity;

      // pengajuanCheck
      for (let z = 0; z < copyListPengajuan.length; z++) {
        if (copyListPengajuan[z].fID === value.fPengajuanID) {
          copyListPengajuan[z].fSelected--;
          break;
        }
      }
    }

    setSelected(newMap);
    setListPengajuan(copyListPengajuan);
    setListItemFiltered(copyListItem);
  };

  const onHandleclickCheckItem = (check, value) => {
    const newMap = new Map(selected);
    const copyListItem = [...listItemFiltered];
    const copyListPengajuan = [...listPengajuan];
    newMap.set(value.fID, {
      check: check,
      fItem: {
        fID: value.fItem.fID,
        fItemName: value.fItem.fItemName,
        fItemImage: value.fItem.fItemImage,
        fTotal: value.fQuantity,
        fDenom: value.fDenom
      },
      fProject: { fID: value.fProjectID, fProjectName: value.fProjectName },
      fPengajuan: {
        fID: value.fPengajuanID,
        fPengajuanName: value.fPengajuanName,
      },
    });

    const indexItem = indexing[value.fID].item;
    if (check) {
      // itemCheck
      copyListItem[indexItem.i].fSelected =
        copyListItem[indexItem.i].fSelected + value.fQuantity;

      // pengajuanCheck
      for (let z = 0; z < copyListPengajuan.length; z++) {
        if (copyListPengajuan[z].fID === value.fPengajuanID) {
          copyListPengajuan[z].fSelected++;
          break;
        }
      }
    } else {
      //itemCheck
      copyListItem[indexItem.i].fSelected =
        copyListItem[indexItem.i].fSelected - value.fQuantity;

      // pengajuanCheck
      for (let z = 0; z < copyListPengajuan.length; z++) {
        if (copyListPengajuan[z].fID === value.fPengajuanID) {
          copyListPengajuan[z].fSelected--;
          break;
        }
      }
    }

    setSelected(newMap);
    setListPengajuan(copyListPengajuan);
    setListItemFiltered(copyListItem);
  };

  const onHandleClickTambah = () => {
    setPengajuan(listPengajuan.map(item => ({ ...item })));
    setListSelected(selected);
    setListbyItem(listItemFiltered.map(item => ({ ...item })))

    setOpenPengajuan(false);
  };

  const onHandleClickCancel = () => {
    setOpenPengajuan(false);
  };

  const Header = () => {
    return (
      <div className="ModalListPengajuanHeader">
        <text className="ModalListPengajuanTitle">LIST PENGAJUAN</text>
        <div className="ModalListPengajuanFilterButtonContainer">
          <button
            onClick={onHandleClickFilterPengajuan}
            className={
              filter === "Pengajuan"
                ? "ModalListPengajuanFilterActiveButton"
                : "ModalListPengajuanFilterinActiveButton"
            }
          >
            Pengajuan
          </button>
          <button
            onClick={onHandleClickFilterItem}
            className={
              filter === "Item"
                ? "ModalListPengajuanFilterActiveButton"
                : "ModalListPengajuanFilterinActiveButton"
            }
          >
            Item
          </button>
        </div>
      </div>
    );
  };

  const Footer = () => {
    return (
      <div className="ModalListPengajuanFilterButtonSubmitContainer">
        <button
          onClick={onHandleClickTambah}
          className="ModalListPengajuanFilterButtonSubmit"
          style={{ backgroundColor: "green" }}
        >
          Tambah
        </button>
        <button
          onClick={onHandleClickCancel}
          style={{ backgroundColor: "red" }}
          className="ModalListPengajuanFilterButtonSubmit"
        >
          Batal
        </button>
      </div>
    );
  };

  const PengajuanCard = ({ data, index }) => {
    return (
      <div className="ModalListPengajuanFilterPengajuanCard">
        <div className="ModalListPengajuanFilterPengajuanCardHeader">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Checkbox
              checked={data.fPengajuanItem.length === data.fSelected}
              onChange={(v) => {
                onHandleClickCheckAllPengajuan(v.target.checked, data );
              }}
              style={{ color: "#D0A360", marginTop: 0 }}
            />
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                textAlign: "start",
                paddingRight: "15px",
              }}
            >
              <text style={{ fontWeight: "bold" }}>{data.fPengajuanName}</text>
              <text>{data.fProject.fProjectName}</text>
            </div>
          </div>
        </div>

        {data.fPengajuanItem.map((v) => {
          return (
            <div className="ModalListPengajuanFilterPengajuanCardList">
              <div
                style={{
                  height: "1px",
                  width: "100%",
                  backgroundColor: "#dddddd",
                  marginBottom: "10px",
                }}
              />
              <div
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <Checkbox
                  checked={selected.get(v.fID).check}
                  onChange={(d) => onHandleclickCheckItemPengajuan(d.target.checked, v, data.fPengajuanName)}
                  style={{ color: "#D0A360", marginTop: 0 }}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <img
                    style={{
                      width: "85px",
                      height: "85px",
                      borderRadius: 7,
                    }}
                    src={`${API_URL}/files/${v.fItem.fItemImage.filename}`}
                  />
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "space-between",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                      paddingTop: "5px",
                    }}
                  >
                    <text style={{ fontWeight: "bold" }}>
                      {v.fItem.fItemName}
                    </text>
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <text style={{ fontWeight: "bold", fontSize: "18px" }}>
                        {numberWithCommas(v.fQuantity)} {v.fDenom}
                      </text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const ItemCard = ({ data }) => {
    return (
      <div className="ModalListPengajuanFilterPengajuanCard">
        <div className="ModalListPengajuanFilterPengajuanCardHeader">
          <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <Checkbox
              checked={data.fTotal === data.fSelected}
              onChange={(v) => {
                onHandleClickCheckAllItem(v.target.checked, data);
              }}
              style={{ color: "#D0A360", marginTop: 0 }}
            />
            <img
              style={{
                width: "85px",
                height: "85px",
                borderRadius: 7,
              }}
              src={`${API_URL}/files/${data.fPengajuan[0].fItem.fItemImage.filename}`}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                gap: "3px",
                flex: 1,
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingTop: "5px",
              }}
            >
              <text style={{ fontWeight: "bold" }}>
                {data.fPengajuan[0].fItem.fItemName}
              </text>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "end",
                }}
              >
                <text>Total</text>
                <text style={{ fontWeight: "bold", fontSize: "18px" }}>
                  {numberWithCommas(data.fSelected)} {data.fDenom}
                </text>
              </div>
            </div>
          </div>
        </div>

        {data.fPengajuan.map((v) => {
          return (
            <div className="ModalListPengajuanFilterPengajuanCardList">
              <div
                style={{
                  height: "1px",
                  width: "100%",
                  backgroundColor: "#dddddd",
                  marginBottom: "10px",
                }}
              />
              <div
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <Checkbox
                  checked={selected.get(v.fID).check}
                  onChange={(d) => onHandleclickCheckItem(d.target.checked, v)}
                  style={{ color: "#D0A360", marginTop: 0 }}
                />
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingRight: "15px",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <text style={{ fontWeight: "bold" }}>
                      {v.fPengajuanName}
                    </text>
                    <text>{v.fProjectName}</text>
                  </div>
                  <text style={{ fontWeight: "bold", fontSize: "18px" }}>
                    {numberWithCommas(v.fQuantity)} {v.fDenom}
                  </text>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="ModalListPengajuanCanvas">
      <div style={{ borderRadius: 7 }} className="ModalListPengajuanContainer">
        <Header />

        <div className="ModalListPengajuanContent">
          {filter === "Pengajuan" &&
            listPengajuan.map((d, i) => {
              return <PengajuanCard data={d} index={i} />;
            })}

          {filter === "Item" &&
            listItemFiltered.map((d) => {
              return <ItemCard data={d} />;
            })}
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ModalListPengajuan;
