import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";

import file from "../../assets/file.png";
import addicon from "../../assets/add.png";
import bin from "../../assets/bin.png";

import notulen from "../../assets/notulen.png";

import "@fontsource/poppins";

import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";
require("dayjs/locale/de");

const djs = dayjs().locale("de").format();

const AddPengajuanItems = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const inputRefs = useRef({});

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [file, setFile] = useState([
    {
      fImage: "",
      fItemName: "",
    },
  ]);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(false);
  const [msg, setMsg] = useState(false);

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      if (
        response.data.user[0].fRole !== "BOSS" &&
        response.data.user[0].fRole !== "PM" &&
        response.data.user[0].fRole !== "FINANCE"
      ) {
        navigate("/");
      }
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const validationForm = (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (file.length === 0) {
      validationErrors.file = "masukkan minimal 1 item";
    } else {
      for (let i = 0; i < file.length; i++) {
        if (!file[i].fImage || !file[i].fItemName.trim()) {
          validationErrors.item = "Harap isi semua data";
          break;
        }
      }
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      handleSaveButton();
    }
  };

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  const handleSaveButton = async () => {
    setLoading(true);

    let bodyData = [];

    try {
      for (let i = 0; i < file.length; i++) {
        if (typeof file[i].fImage.filename !== "string") {
          const base64File = await blobToBase64(file[i].fImage);
          const name = URL.createObjectURL(file[i].fImage);

          bodyData.push({
            file: base64File,
            ext: getFileExtension(file[i].fImage.name),
            size: file[i].fImage.size,
            type: file[i].fImage.type,
            fItemName: file[i].fItemName
          });
        }
      }

      await axiosJWT.post(
        `${API_URL}/addpengajuanitems`,
        {
          data: bodyData
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleImageClick = (index) => {
    inputRefs.current[index].click();
  };

  const handleImageChange = (event, index) => {
    const newFile = [...file];
    newFile[index].fImage = event.target.files[0];
    setFile(newFile);
  };

  const handleItemDelete = (data, i) => {
    setFile((prevItems) => prevItems.filter((item, index) => index !== i));
  };

  const onClickAddMore = () => {
    const item = {
      fImage: "",
      fItemName: "",
    };

    setFile([...file, item]);
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "130px",
        minHeight: "100vh",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: notulen,
          title: "Tambah Item",
          name: user.fName,
          job: user.fRoleAlias,
          type: "Add",
          update: false,
          delete: false,
        }}
      />

      <div className="AddNotulenContainer">
        <text
          style={{
            width: "100%",
            textAlign: "center",
            fontWeight: "bold",
            color: "#D0A360",
            fontSize: "20px",
            marginBottom: "20px",
          }}
        >
          Tambah Item
        </text>

        {file.map((d, i) => {
          return (
            <div
              style={{
                backgroundColor: "#D7D7D7",
                borderRadius: 7,
                paddingLeft: "10px",
                position: "relative",
              }}
              className="AddNotulenContainer"
            >
              <div
                style={{ marginTop: "25px", width: "100%" }}
                className="AddNotulenInput"
              >
                <text style={{ fontWeight: "bold" }}>Gambar</text>
              </div>

              <div style={{ width: "100%" }}>
                {/* image input */}
                <div>
                  {d.fImage ? (
                    <img
                      onClick={() => handleImageClick(i)}
                      style={{
                        width: "300px",
                        height: "150px",
                        objectFit: "cover",
                        backgroundColor: "white",
                        border: "1px solid rgba(0, 0, 0, 0.2)",
                        boxSizing: "border-box",
                        borderRadius: 7,
                      }}
                      src={URL.createObjectURL(d.fImage)}
                      alt=""
                    />
                  ) : (
                    <div
                      onClick={() => handleImageClick(i)}
                      style={{
                        width: "300px",
                        height: "150px",
                        objectFit: "cover",
                        border: "1px solid rgba(0, 0, 0, 0.2)",
                        boxSizing: "border-box",
                        backgroundColor: "white",
                        borderRadius: 7,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <img
                        style={{ width: "27px", height: "27px" }}
                        src={file}
                        alt=""
                      />
                      <text style={{ textAlign: "center" }}>
                        <span style={{ color: "#818CF8" }}>
                          click to browse
                        </span>
                      </text>
                    </div>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    ref={(el) => (inputRefs.current[i] = el)}
                    onChange={(event) => {
                      handleImageChange(event, i);
                    }}
                    style={{ display: "none" }}
                  />
                </div>

                <div style={{ marginTop: "25px" }} className="AddNotulenInput">
                  <text style={{ fontWeight: "bold" }}>Nama Item</text>
                  <input
                    // value={d.fItemName}
                    onChange={(v) => {
                      d.fItemName = v.target.value;
                    }}
                    className="AddNotulenInputText"
                    type="text"
                    placeholder="Item"
                  />
                </div>
              </div>

              <img
                onClick={() => {
                  handleItemDelete(d, i);
                }}
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "20px",
                  position: "absolute",
                  top: "15px",
                  right: "15px",
                }}
                src={bin}
                alt=""
              />
            </div>
          );
        })}

        <div
          onClick={() => {
            onClickAddMore();
          }}
          style={{
            width: "95%",
            fontSize: "16px",
            color: "#D1A361",
            fontWeight: "bold",
            marginBottom: "25px",
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          <img style={{ width: "16px", height: "16px" }} src={addicon} alt="" />
          <text>Tambah Item</text>
        </div>

        {errors.file && (
          <text
            style={{
              color: "red",
              fontSize: "16px",
              width: "95%",
              marginBottom: "10px",
            }}
          >
            *{errors.file}
          </text>
        )}

        {errors.item && (
          <text
            style={{
              color: "red",
              fontSize: "16px",
              width: "95%",
              marginBottom: "10px",
            }}
          >
            *{errors.item}
          </text>
        )}

        <div style={{ display: "flex", width: "95%" }}>
          <div
            onClick={validationForm}
            className="AddProgressButton"
            style={{ opacity: loading ? "0.7" : "1" }}
          >
            {loading && <span>Loading...</span>}
            {!loading && <span>Simpan</span>}
          </div>
        </div>
      </div>
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default AddPengajuanItems;
