import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";

import file from "../../assets/file.png";
import bin from "../../assets/bin.png";
import Dropdown from "../../Components/Dropdown/Dropdown";
import DownloadCard from "../../Components/DownloadCard/DownloadCard";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import tasklogo from "../../assets/task.png";

import "@fontsource/poppins";

import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";

import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";

const djs = dayjs().locale("de").format();

const DetailAbsensiRequest = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const { state } = useLocation();
  const { fID } = state;

  const [data, setData] = useState({
    fUser: {
      fName: "",
      fImage: "",
      fRoleAlias: "",
    },
  });

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [ketInput, setKetInput] = useState("");
  const [error, setError] = useState('');

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    getDetailAbsensiRequest();
  }, [user]);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID, page: 1, limit: 3, type: 'PM'
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(new Date(response.data.data[0].fCheckInDateTime));
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate('/')
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate('/')
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getDetailAbsensiRequest = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getdetailabsensirequest`,
        { fID: fID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data);
    } catch (error) {}
  };

  const validateForm = (type) => {
    let temp = '';
    if(!ketInput.trim()){
      temp = 'harus diisi'
    }

    setError(temp);

    if(temp.trim().length === 0){
      if(type === 'approve'){
        handleApproveButton()
      }else{
        handleRejectButton()
      }
    }
  }

  const handleApproveButton = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/updateabsensirequest`,
        { fID: fID, fStatus: "APPROVE", fKetApproval: ketInput },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1);
    } catch (error) {}
  };

  const handleRejectButton = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/updateabsensirequest`,
        { fID: fID, fStatus: "REJECT", fKetApproval: ketInput },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1);
    } catch (error) {}
  };

  const handleCancelButton = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/cancelabsensirequest`,
        { fID: fID, fStatus: "CANCELED" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1);
    } catch (error) {}
  };

  const renderTextWithLineBreaks = (text) => {
    return (text || '').split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "135px",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: tasklogo,
          title: "Detail Cuti/Sakit",
          name: user.fName,
          job: user.fRoleAlias,
        }}
      />

      <div className="AddNotulenContainer">
        <div
          style={{
            padding: "5px 25px",
            color: "#D0A360",
            borderRadius: 100,
            border: "3px solid #D0A360",
            fontWeight: "bold",
            boxSizing: "border-box",
            width: "95%",
            marginBottom: "25px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {data.fStatus}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "40px",
            fontSize: "20px",
          }}
        >
          <img
            style={{ width: "150px", height: "150px", borderRadius: 100 }}
            src={`${API_URL}/files/${data.fUser.fImages}`}
            alt=""
          />
          <text style={{ fontWeight: "bold", marginTop: "10px" }}>
            {data.fUser.fName}
          </text>
          <text style={{}}>{data.fUser.fRoleAlias}</text>
        </div>

        <div className="AddNotulenInput">
          <text style={{ fontWeight: "bold" }}>Jenis</text>
          <text>{data.fRequestType}</text>
        </div>

        <div className="AddNotulenInput">
          <text style={{ fontWeight: "bold" }}>Waktu Mulai</text>
          {data.fRequestType === "Cuti" && (
            <text>
              {dayjs(data.fStartDateTime).format("DD MMM YYYY").toString()}
            </text>
          )}
          {data.fRequestType === "Sakit" && (
            <text>
              {dayjs(data.fStartDateTime).format("DD MMM YYYY").toString()}
            </text>
          )}
          {data.fRequestType === "Izin" && (
            <text>
              {dayjs(data.fStartDateTime)
                .format("DD MMM YYYY, HH:mm")
                .toString()}
            </text>
          )}
        </div>

        <div className="AddNotulenInput">
          <text style={{ fontWeight: "bold" }}>Waktu Selesai</text>
          {data.fRequestType === "Cuti" && (
            <text>
              {dayjs(data.fFinishDateTime).format("DD MMM YYYY").toString()}
            </text>
          )}
          {data.fRequestType === "Sakit" && (
            <text>
              {dayjs(data.fFinishDateTime).format("DD MMM YYYY").toString()}
            </text>
          )}
          {data.fRequestType === "Izin" && (
            <text>
              {dayjs(data.fFinishDateTime)
                .format("DD MMM YYYY, HH:mm")
                .toString()}
            </text>
          )}
        </div>

        <div className="AddNotulenInput">
          <text style={{ fontWeight: "bold" }}>Keterangan</text>
          <text>{renderTextWithLineBreaks(data.fKet)}</text>
        </div>

        {(data.fStatus === "APPROVE" ||
          data.fStatus === "REJECT") && (
            <div className="AddNotulenInput">
              <text style={{ fontWeight: "bold" }}>Keterangan Approval</text>
              <text>{renderTextWithLineBreaks(data.fKetApproval)}</text>
            </div>
          )}

        {user.fRole === "SPV" && data.fStatus === "NEW" && (
          <div style={{ display: "flex", width: "95%" }}>
            <div
              style={{ backgroundColor: "#A30F0F" }}
              onClick={() => {
                handleCancelButton();
              }}
              className="AddProgressButton"
            >
              <text>Cancel</text>
            </div>
          </div>
        )}

        {(user.fRole === "PM" || user.fRole === "BOSS" || user.fRole === 'HRD') && user.fID !== data.fCreatedByID &&
          data.fStatus === "NEW" && (
            <div style={{ width: "95%", marginBottom: "40px" }}>
              <div style={{ width: "100%", fontWeight: "bold" }}>
                Keterangan {error && <span style={{ color: 'red', fontSize: '14px', fontWeight: 'normal' }}>*{error}</span>}
              </div>
              <div className="DetailNotulenInputNotes">
                <textarea
                  style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
                  minLength={150}
                  value={ketInput}
                  onChange={(v) => {
                    setKetInput(v.target.value);
                  }}
                  className="AddNotulenInputNote"
                  type="text"
                  placeholder="Keterangan"
                ></textarea>
              </div>
            </div>
          )}

        {(user.fRole === "PM" || user.fRole === "BOSS" || user.fRole === 'HRD') && user.fID !== data.fCreatedByID &&
          data.fStatus === "NEW" && (
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                margin: "0 auto",
                alignItems: "center",
                gap: "15px",
                color: "white",
              }}
            >
              <div
                onClick={() => {
                  validateForm('approve')
                }}
                style={{
                  width: "95%",
                  backgroundColor: "#D0A360",
                  padding: "5px 0",
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: 5,
                }}
              >
                <text>Approve</text>
              </div>

              <div
                onClick={() => {
                  validateForm('reject')
                }}
                style={{
                  width: "95%",
                  backgroundColor: "red",
                  padding: "5px 0",
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: 5,
                }}
              >
                <text>Reject</text>
              </div>
            </div>
          )}

        <div style={{ height: "30px", width: "1px" }} />
      </div>
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default DetailAbsensiRequest;
