import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";

import file from "../../assets/file.png";
import bin from "../../assets/bin.png";
import Dropdown from "../../Components/Dropdown/Dropdown";
import DownloadCard from "../../Components/DownloadCard/DownloadCard";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import kasbonlogo from "../../assets/kasbon.png";

import "@fontsource/poppins";

import ReactSignatureCanvas from "react-signature-canvas";

import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";
require("dayjs/locale/de");

const djs = dayjs().locale("de").format();

const AddKasbon = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const fileRefs = useRef({});

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [project, setProject] = useState([]);
  const [selectedProject, setSelectedProject] = useState("Pilih Project");
  const [selectedProjectData, setSelectedProjectData] = useState();

  const [kasbonName, setKasbonName] = useState('');
  const [dateTime, setDateTime] = useState(dayjs(new Date()));
  const [type, setType] = useState('');
  const [cost, setCost] = useState();
  const [ket, setKet] = useState("");

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    getAllProject();
  }, [user]);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID, page: 1, limit: 3, type: 'PM'
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(new Date(response.data.data[0].fCheckInDateTime));
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate('/')
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate('/')
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      if(response.data.user[0].fRole !== 'SPV'){
        navigate('/')
      }
      setUser(response.data.user[0]);
      setSelectedProject(response.data.project.fProjectName ? response.data.project.fProjectName : 'Pilih Project');
      setSelectedProjectData(response.data.project);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllProject = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getprojectdropdown`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProject(response.data);
    } catch (error) {}
  };

  const validationForm = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (selectedProject === "Pilih Project") {
      validationErrors.project = "harus dipilih";
    }
    if (!kasbonName.trim()) {
      validationErrors.kasbon = "harus diisi";
    }
    if (!ket.trim()) {
      validationErrors.ket = "harus diisi";
    }
    if (!type.trim()) {
      validationErrors.type = "harus diisi";
    }
    if (!cost) {
      validationErrors.cost = "harus diisi";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0 && !loading) {
      handleSaveButton();
    }
  }

  const handleSaveButton = async () => {
    setLoading(true)
    
    var bodyFormData = new FormData();
    bodyFormData.append("fProjectID", selectedProjectData.fID);
    bodyFormData.append("fKasbonName", kasbonName);
    bodyFormData.append("fKasbonKet", ket);
    bodyFormData.append("fKasbonDate", dateTime);
    bodyFormData.append("fKasbonType", type);
    bodyFormData.append("fKasbonAmount", cost);

    try {
      const response = await axiosJWT.post(
        `${API_URL}/addkasbon`,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1);
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "130px",
        minHeight: "100vh",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: kasbonlogo,
          title: "Tambah Kasbon",
          name: user.fName,
          job: user.fRoleAlias,
          type: "Add",
          update: false,
          delete: false,
        }}
      />

      <div className="AddNotulenContainer">
        <div className="AddNotulenInput">
          <text>Project{errors.project && <span style={{ color: 'red', fontSize: '14px' }}> *{errors.project}</span>}</text>
          <Dropdown
            selected={selectedProject}
            setSelected={setSelectedProject}
            setSelectedData={setSelectedProjectData}
            options={project}
            type={"fProjectName"}
          />
        </div>

        <div className="AddNotulenInput">
            <text>Judul Kasbon{errors.kasbon && <span style={{ color: 'red', fontSize: '14px' }}> *{errors.kasbon}</span>}</text>
            <input
              value={kasbonName}
              onChange={(v) => {
                setKasbonName(v.target.value);
              }}
              className="AddNotulenInputText"
              type="text"
              placeholder="kasbon"
            />
          </div>

        <div className="AddNotulenInput">
          <text>Tanggal</text>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ width: "50%" }}>
              <DatePicker
              format="DD/MM/YYYY"
                disablePast={true}
                value={dateTime}
                defaultValue={dateTime}
                onChange={(v) => {
                  setDateTime(v);
                }}
              />
            </div>
          </LocalizationProvider>
        </div>

        <div className="AddNotulenInput">
            <text>Tipe{errors.type && <span style={{ color: 'red', fontSize: '14px' }}> *{errors.type}</span>}</text>
            <input
              value={type}
              onChange={(v) => {
                setType(v.target.value);
              }}
              className="AddNotulenInputText"
              type="text"
              placeholder="Tipe"
            />
          </div>

          <div className="AddNotulenInput">
            <text>Jumlah{errors.cost && <span style={{ color: 'red', fontSize: '14px' }}> *{errors.cost}</span>}</text>
            <input
              value={cost}
              onChange={(v) => {
                setCost(v.target.value);
              }}
              className="AddNotulenInputText"
              type="number"
              placeholder="1000000"
            />
          </div>

        <div className="AddNotulenInput">
          <text>Keterangan{errors.ket && <span style={{ color: 'red', fontSize: '14px' }}> *{errors.ket}</span>}</text>
          <div className="AddNotulenInputNotes">
            <textarea
              style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
              minLength={150}
              value={ket}
              onChange={(v) => {
                setKet(v.target.value);
              }}
              className="AddNotulenInputNote"
              type="text"
              placeholder="Keterangan"
            ></textarea>
          </div>
        </div>

        <div style={{ display: "flex", width: "95%" }}>
          <div
            onClick={validationForm}
            className="AddProgressButton"
            style={{ opacity: loading ? '0.7' : '1' }}
          >
            {loading && <span>Loading...</span>}
            {!loading && <span>Simpan</span>}
          </div>
        </div>
      </div>
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default AddKasbon;
