import "./TotalCard.css";

const TotalCard = ({ data }) => {
  return (
    <div style={{ backgroundColor: data.bgColor, color: '#333333' }} className="Total_Container">
      <img className="LogoCard" alt="" src={data.logo} />
      <div>
        {
          data.total === '' ?
          <text style={{ color: data.bgColor }} className="TotalText">0</text>
          :
          <text className="TotalText">{data.total}</text>
        }
        <div />
        <div className="NotesText">
          <text>{data.keterangan}</text>
        </div>
      </div>
    </div>
  );
};

export default TotalCard;
