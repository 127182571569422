import "./ModalPicture.css";
import leftarrow from '../../assets/left-arrow.png';
import rightarrow from '../../assets/right-arrow.png';

import React, { useState } from "react";

const ModalPicture = ({ closeModalPicture, picture, index, setIndex }) => {
  const API_URL = process.env.REACT_APP_API_URL;

  const length = picture.length;

  const handleClickLeft = () => {
    if(index !== 0){
        setIndex(index-1)
    }
  }

  const handleClickRight = () => {
    if(index !== length-1){
        setIndex(index+1)
    }
  }

  return (
    <div className="modalPictureBackground">
      <div className="modalPictureContainer">
        <img
          src={`${API_URL}/files/${picture[index].filename}`}
          alt=""
          className="modalPicture"
        />
        <button
        onClick={handleClickLeft}
          className="modalPictureButtonLeft"
        >
          <img className="modalPictureIcon" src={leftarrow} alt="" />
          </button>
        <button
        onClick={handleClickRight}
          className="modalPictureButtonRight"
        >
          <img className="modalPictureIcon" src={rightarrow} alt="" />
          </button>
      </div>
      <button onClick={() => {closeModalPicture(false)}} className="modalPictureCloseButton">CLOSE</button>
    </div>
  );
};

export default ModalPicture;
