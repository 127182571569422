import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";

import file from "../../assets/file.png";
import bin from "../../assets/bin.png";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import tambahprogresslogo from "../../assets/tambahprogress.png";

import "./EditProgressProject.css";
import "@fontsource/poppins";

import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";
require("dayjs/locale/de");

const djs = dayjs().locale("de").format();

const AddProgressProject = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const { state } = useLocation();
  const { data } = state;
  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [files, setFiles] = useState([]);
  const [date, setDate] = useState(dayjs(new Date()));
  const [ket, setKet] = useState("");
  const [progress, setProgress] = useState();

  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT") {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      if (
        response.data.user[0].fRole !== "BOSS" &&
        response.data.user[0].fRole !== "PM"
      ) {
        navigate("/");
      }
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleImageChange = (event) => {
    const file = event.target.files;
    if (file) {
      setFiles(Array.from(file));
    }
  };

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const validationForm = (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (!progress) {
      validationErrors.progress = "harus diisi";
    }

    if (files.length === 0) {
      validationErrors.file = "minimal 1 gambar";
    }

    if (!ket.trim()) {
      validationErrors.ket = "harus diisi";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0 && !loading) {
      handleSaveButton();
    }
  };

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  const handleSaveButton = async () => {
    setLoading(true);

    try {
      let tempImages = [];
      for (let i = 0; i < files.length; i++) {
        if (typeof files[i].filename !== "string") {
          const base64File = await blobToBase64(files[i]);
          tempImages.push({
            file: base64File,
            ext: getFileExtension(files[i].name),
            size: files[i].size,
            type: files[i].type
          });
        }else{
          tempImages.push(files[i]);
        }
      }

      const bodyFormData = {
        fProjectID: data.fID,
        fProgressDate: date,
        fProgressImages: tempImages,
        fProgressPercentage: progress,
        fKet: ket,
        fCheckKet: ket,
      };

      const response = await axios.post(
        `${API_URL}/addprojectprogress`,
        bodyFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const removeImage = (indexToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((files, index) => index !== indexToRemove)
    );
  };

  const handleImageDelete = (i) => {
    removeImage(i);
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        paddingTop: "130px",
        minHeight: "100vh",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: tambahprogresslogo,
          title: "Add Progress",
          name: user.fName,
          job: user.fRoleAlias,
        }}
      />

      <div className="AddNotulenContainer">
        <div className="AddNotulenInput">
          <text>Tanggal</text>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ width: "50%" }}>
              <DatePicker
                format="DD/MM/YYYY"
                disableFuture={true}
                value={date}
                onChange={(v) => setDate(v)}
              />
            </div>
          </LocalizationProvider>
        </div>

        <div className="AddNotulenInput">
          <text>
            {`Progress (%)`}
            {errors.progress && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.progress}
              </span>
            )}
          </text>
          <input
            className="AddNotulenInputText"
            value={progress}
            onChange={(e) => setProgress(e.target.value)}
            type="number"
            placeholder="0"
          />
        </div>

        <div className="AddNotulenInput">
          <text>
            {"Gambar"}
            {files.length > 10 ? (
              <span style={{ color: "red", fontWeight: "bold" }}>
                {" "}
                (Maks 10)
              </span>
            ) : (
              " (Maks 10)"
            )}
            {errors.file && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.file}
              </span>
            )}
          </text>
          <div>
            <div
              onClick={handleImageClick}
              className="AddNotulenFile"
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "20px 10px",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <img
                style={{ width: "27px", height: "27px" }}
                src={file}
                alt=""
              />
              <text style={{ textAlign: "center" }}>
                <span style={{ color: "#818CF8" }}>click to browse</span>
              </text>
            </div>
            <input
              type="file"
              ref={inputRef}
              onChange={handleImageChange}
              accept="image/*"
              multiple
              style={{ display: "none" }}
            />
          </div>
        </div>

        {/* download card */}
        <div className="AddProjectCenterer">
          <div className="AddProjectDownloadCardContainer">
            {files.length > 0 &&
              files.map((d, i) => {
                return (
                  <div>
                    <div className="AddProjectDownloadCardWrapper">
                      <img
                        className="AddProjectDownloadImage"
                        src={URL.createObjectURL(d)}
                        alt=""
                      />
                      <div
                        style={{
                          backgroundColor: "#378D23",
                          marginTop: "10px",
                        }}
                        className="AddProjectDownloadButton"
                        onClick={() => {
                          handleDownload(URL.createObjectURL(d), d.name);
                        }}
                      >
                        Download
                      </div>
                      <div
                        onClick={() => {
                          handleImageDelete(i);
                        }}
                        style={{
                          backgroundColor: "#BC1C12",
                          marginBottom: "20px",
                        }}
                        className="AddProjectDownloadButton"
                      >
                        Delete
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="AddNotulenInput">
          <text>
            Keterangan
            {errors.ket && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {" "}
                *{errors.ket}
              </span>
            )}
          </text>
          <div className="AddNotulenInputNotes">
            <textarea
              style={{ fontFamily: "Poppins", lineHeight: 1.6 }}
              minLength={150}
              value={ket}
              onChange={(e) => {
                setKet(e.target.value);
              }}
              className="AddNotulenInputNote"
              type="text"
              placeholder="Keterangan"
            ></textarea>
          </div>
        </div>

        <div style={{ display: "flex", width: "95%" }}>
          <div
            onClick={validationForm}
            style={{
              marginBottom: "15px",
              fontWeight: "bold",
              opacity: loading ? "0.7" : "1",
            }}
            className="AddProgressButton"
          >
            {loading && <span>Loading...</span>}
            {!loading && <span>Simpan</span>}
          </div>
        </div>
      </div>
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default AddProgressProject;
