import "./ModalCheckIn.css";
import { useEffect, useState } from "react";

import React from "react";
import Webcam from "react-webcam";

import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Dropdown from "../Dropdown/Dropdown";

import dayjs from "dayjs";

const WebcamComponent = () => <Webcam />;

const videoConstraints = {
  width: 220,
  height: 200,
  facingMode: "user",
};

const ModalCheckIn = ({
  fID,
  refresh,
  setRefresh,
  closeModal,
  user,
  token,
  project,
  selectedProject,
  setSelectedProject,
  selectedProjectData,
  setSelectedProjectData,
  type,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [firstPage, setFirstPage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const [time, setTime] = useState(dayjs(new Date()).set("second", 0));
  const [data, setData] = useState({
    keterangan: "",
  });

  const [msg, setMsg] = useState(false);

  const OnClickNext = () => {
    setFirstPage(false);
  };

  const OnClickBack = () => {
    setFirstPage(true);
  };

  const OnClickSubmit = async () => {
    try {
      setLoading(true);
      if (
        (user.fRole === "SPV" || user.fRole === "SUPIR") &&
        selectedProjectData === undefined
      ) {
        setMsg(true);
        setLoading(false);
      } else if (type === "CheckIn") {
        const response = await axios.post(
          `${API_URL}/checkin`,
          {
            fProjectID:
              user.fRole === "SPV" || user.fRole === "SUPIR"
                ? selectedProjectData.fID
                : "-",
            fUserID: user.fID,
            fName: user.fName,
            fRole: user.fRole,
            fCheckInDateTime: time,
            fCheckInImage: image,
            fCheckInKet: data.keterangan,
            fCreatedDate: time,
            fCreatedByID: user.fID,
            fLastModifiedDate: time,
            fLastModifiedById: user.fID,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setRefresh(!refresh);
        closeModal(false);
      } else {
        const response = await axios.post(
          `${API_URL}/checkout`,
          {
            fID,
            fName: user.fName,
            fProjectID:
              user.fRole === "SPV" || user.fRole === "SUPIR"
                ? selectedProjectData.fID
                : "-",
            fCheckOutDateTime: time,
            fCheckOutImage: image,
            fCheckOutKet: data.keterangan,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setRefresh(!refresh);
        closeModal(false);
      }
    } catch (error) {
      console.log(error);
      setRefresh(!refresh);
      closeModal(true);
    }
  };

  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const showdate = new Date();
    setTime(showdate);

    setImage(imageSrc);
  }, [webcamRef]);

  const FirstPage = () => {
    return (
      <div className="modalCheckInContainer">
        <div className="modalCheckInHeader">
          <div className="modalCheckInText2">Foto Absen</div>
          <div className="webcam-img">
            {image === "" ? (
              <Webcam
                audio={false}
                height={720}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={720}
                videoConstraints={videoConstraints}
                style={{ margin: "20px 0", width: "270px", height: "270px" }}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={image} />
                <text
                  style={{ margin: "15px 0", fontSize: "16px" }}
                >{`${time.toLocaleDateString("id-ID")}, ${String(
                  time.getHours()
                ).padStart(2, "0")}:${String(time.getMinutes()).padStart(
                  2,
                  "0"
                )}`}</text>
              </div>
            )}
          </div>
        </div>

        <div>
          {image !== "" ? (
            <div className="ModalCheckInButtonContainer">
              <div
                onClick={(e) => {
                  e.preventDefault();
                  setImage("");
                }}
                className="RetakeButton"
              >
                Retake Image
              </div>

              <div
                onClick={(e) => {
                  OnClickNext();
                }}
                className="CheckInButton"
              >
                Next
              </div>
            </div>
          ) : (
            <div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  capture();
                }}
                className="NextButton"
              >
                Ambil Foto
              </div>

              {type === "CheckOut" && (
                <div
                  onClick={() => {
                    closeModal(false);
                  }}
                  style={{ backgroundColor: "red" }}
                  className="NextButton"
                >
                  Batal
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const SecondPage = () => {
    return (
      <div style={{ fontSize: "16px" }} className="modalCheckInContainer">
        <div className="modalCheckInHeader">
          <div className="modalCheckInText">
            {type === "CheckIn" ? "Check-In" : "Check-Out"}
          </div>
          <div className="modalCheckInUnderline"></div>
        </div>

        {msg && (
          <text
            style={{ color: "red", fontSize: "14px" }}
            className="modalCheckInFormTitle"
          >
            Pilih Project
          </text>
        )}

        {(user.fRole === "SPV" || user.fRole === "SUPIR") && (
          <text className="modalCheckInFormTitle">Project</text>
        )}

        {(user.fRole === "SPV" || user.fRole === "SUPIR") && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "16px",
              lineHeight: "1.2",
            }}
          >
            <Dropdown
              selected={selectedProject}
              setSelected={setSelectedProject}
              setSelectedData={setSelectedProjectData}
              options={project}
              type={"fProjectName"}
            />
          </div>
        )}

        <text className="modalCheckInFormTitle">Keterangan</text>
        <div className="modalCheckInInputNotes">
          <textarea
            style={{ fontFamily: "Poppins" }}
            minLength={150}
            onChange={(v) => {
              data.keterangan = v.target.value;
            }}
            className="AddNotulenInputNote"
            type="text"
            placeholder="Keterangan"
          >
            {data.keterangan}
          </textarea>
        </div>

        <div className="ModalCheckInButtonContainer">
          <div
            onClick={() => {
              if (!loading) {
                OnClickBack();
              }
            }}
            className="RetakeButton"
          >
            Kembali
          </div>

          <div
            onClick={() => {
              if (!loading) {
                OnClickSubmit();
              }
            }}
            className="CheckInButton"
            style={{ opacity: loading ? "0.7" : "1" }}
          >
            {loading && <span>Loading...</span>}
            {!loading && (
              <span>{type === "CheckIn" ? "Check-In" : "Check-Out"}</span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="modalCheckInBackground">
      {firstPage && <FirstPage />}
      {!firstPage && <SecondPage />};
    </div>
  );
};

export default ModalCheckIn;
