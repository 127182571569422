import { useLocation, useNavigate, Link } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarExtension from "../../Components/Navbar/NavbarExtension";
import project_berjalan from "../../assets/projectberjalan.png";
import profile from "../../assets/profile.png";

import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";

import Notulencard from "../../Components/NotulenCard/NotulenCard";
import PengajuanCard from "../../Components/PengajuanCard/PengajuanCard";
import ProgressCard from "../../Components/ProgressCard/ProgressCard";

import kontrak from "../../assets/kontrak.png";
import rab from "../../assets/rab.png";
import timeline from "../../assets/timeline.png";
import download from "../../assets/download.png";

import { useSpring, animated } from "react-spring";

import "./DetailProject.css";
import "@fontsource/poppins";

import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import fileDownload from "js-file-download";
import CarouselImage from "../../Components/CarouselImage/CarouselImage";
import ModalPicture from "../../Components/Modal/ModalPicture";

const DetailProject = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const AnimatedDiv = animated.div;

  const { state } = useLocation();
  const { fID } = state;
  const [data, setData] = useState({
    fID: "",
    fProjectName: "",
    fProjectClientID: "",
    fClientName: "",
    fImages: [],
    fKet: "",
    fActive: "",
    fKontrak: {
      filename: "",
      size: 0,
    },
    fRAB: {
      filename: "",
      size: 0,
    },
    fBOQ: {
      filename: "",
      size: 0,
    },
    fTimeline: {
      filename: "",
      size: 0,
    },
    fCreatedDate: "",
    fCreatedById: "",
    fLastModifiedDate: "",
    fLastModifiedByID: "",
    fProgress: [],
    fPercentage: 0,
  });
  const [index, setIndex] = useState(0);

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "BOSS",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [notulen, setNotulen] = useState([]);
  const [pengajuan, setPengajuan] = useState([]);
  const [kasbon, setKasbon] = useState([]);

  const [openModalPicture, setOpenModalPicture] = useState(false);

  openModalPicture
  ? (document.body.style.overflow = "hidden")
  : (document.body.style.overflow = "auto");

  const [image, setImage] = useState([]);
  const [imageIndex, setImageIndex] = useState(0)

  const [loading, setLoading] = useState(false);

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    getProject();
    getAllNotulen();
    getAllPengajuan();
    getAllKasbon();
  }, [user]);

  useEffect(() => {
    if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
      getAbsensi();
    }
  }, [user]);

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
      } else {
        navigate("/");
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
        navigate("/");
      }
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      setUser(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getProject = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getsingleproject`,
        { fID: fID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data);
    } catch (error) {}
  };

  const getAllNotulen = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getallprojectnotulen`,
        { page: 1, limit: 3, fID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setNotulen(response.data.data);
    } catch (error) {}
  };

  const getAllPengajuan = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getallprojectpengajuan`,
        { page: 1, limit: 3, fID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPengajuan(response.data.data);
    } catch (error) {}
  };

  const getAllKasbon = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getallkasbon`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setKasbon(response.data.data);
    } catch (error) {}
  };

  const [images, setImages] = useState([]);

  const handleImageClick = () => {
    inputRef.current.click();
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImages([file, ...images]);
    }
  };

  const gaugeProgress = useSpring({
    value: data.fPercentage,
    from: { value: 0 },
    config: {
      duration: 500,
    },
  });


  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleClickEditProject = () => {
    navigate("/editproject", {
      state: { data: data, user: user, token: token },
    });
  };

  const handleClickAddProgress = () => {
    navigate("/addprogressproject", {
      state: { data: data, user: user, token: token },
    });
  };

  const handleClickAllUsers = () => {
    navigate("/alluserproject", { state: { data: data } });
  };

  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  }

  const renderTextWithLineBreaks = (text) => {
    return (text || "").split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  const handleClickProject = (v) => {
    if(user.fRole === 'PM' || user.fRole === 'BOSS'){
      navigate("/editprogressproject", { state: { data: v } });
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        fontSize: "16px",
      }}
    >
      <Navbar profileData={user} />
      <NavbarExtension
        data={{
          icon: project_berjalan,
          title: "Detail Project",
          name: "Vincent",
          job: "SPV",
          type: "detail",
        }}
      />

      <div style={{ height: "155px", width: "1px" }} />
      {(user.fRole === "BOSS" || user.fRole === "PM") && (
        <div>
          <div
            onClick={() => {
              handleClickEditProject();
            }}
            style={{ borderRadius: 5 }}
            className="DetailProjectEditButton"
          >
            Edit Project
          </div>
          <div
            onClick={() => {
              handleClickAddProgress();
            }}
            style={{ borderRadius: 5, backgroundColor: "#B1A4FB" }}
            className="DetailProjectEditButton"
          >
            Add Progress
          </div>

          <div
            onClick={() => {
              handleClickAllUsers();
            }}
            style={{ borderRadius: 5, backgroundColor: "#22B449" }}
            className="DetailProjectEditButton"
          >
            Related User
          </div>
        </div>
      )}

      <div className="DetailProjectFileDownloadContainer">
        <div className="DetailProjectFileDownloadWrapper">
          <div
            onClick={() => {
              handleDownload(
                `${API_URL}/files/${data.fKontrak.filename}`,
                `Kontrak_${data.fProjectName}.${getFileExtension(
                  data.fKontrak.filename
                )}`
              );
            }}
            style={{ borderRadius: 3 }}
            className="DetailProjectFileDownload"
          >
            <div className="DetailProjectIconWrapper">
              <img className="DetailProjectIcon" src={kontrak} alt="" />
              <div>
                <text className="DetailDownloadTextTitle">Kontrak</text>
                <div />
                <text style={{ fontSize: "14px" }}>
                  {humanFileSize(data.fKontrak.size)}
                </text>
              </div>
            </div>
          </div>

          {(user.fRole === "BOSS" ||
            user.fRole === "PM" ||
            user.fRole === "CLIENT") && (
            <div
              onClick={() => {
                handleDownload(
                  `${API_URL}/files/${data.fRAB.filename}`,
                  `RAB_${data.fProjectName}.${getFileExtension(
                    data.fRAB.filename
                  )}`
                );
              }}
              style={{ borderRadius: 3 }}
              className="DetailProjectFileDownload"
            >
              <div className="DetailProjectIconWrapper">
                <img className="DetailProjectIcon" src={rab} alt="" />
                <div>
                  <text className="DetailDownloadTextTitle">RAB</text>
                  <div />
                  <text style={{ fontSize: "14px" }}>
                    {humanFileSize(data.fRAB.size)}
                  </text>
                </div>
              </div>
            </div>
          )}

          <div
            onClick={() => {
              handleDownload(
                `${API_URL}/files/${data.fBOQ.filename}`,
                `BOQ_${data.fProjectName}.${getFileExtension(
                  data.fBOQ.filename
                )}`
              );
            }}
            style={{ borderRadius: 3 }}
            className="DetailProjectFileDownload"
          >
            <div className="DetailProjectIconWrapper">
              <img className="DetailProjectIcon" src={rab} alt="" />
              <div>
                <text className="DetailDownloadTextTitle">BOQ</text>
                <div />
                <text style={{ fontSize: "14px" }}>
                  {humanFileSize(data.fBOQ.size)}
                </text>
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              handleDownload(
                `${API_URL}/files/${data.fTimeline.filename}`,
                `Timeline_${data.fProjectName}.${getFileExtension(
                  data.fTimeline.filename
                )}`
              );
            }}
            style={{ borderRadius: 3 }}
            className="DetailProjectFileDownload"
          >
            <div className="DetailProjectIconWrapper">
              <img className="DetailProjectIcon" src={timeline} alt="" />
              <div>
                <text className="DetailDownloadTextTitle">timeline</text>
                <div />
                <text style={{ fontSize: "14px" }}>
                  {humanFileSize(data.fTimeline.size)}
                </text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="DetailProjectContainer">
        <text
          style={{
            color: "#D0A360",
            fontWeight: "bold",
            fontSize: "20px",
            textAlign: "center",
            width: "100%",
          }}
        >
          {data.fProjectName}
        </text>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "90%",
            fontSize: "16px",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <text>
              Owner:
              <span style={{ fontWeight: "bold" }}> {data.fClientName}</span>
            </text>
          </div>
          <text>{dayjs(data.fDateTime).format("DD MMM YYYY").toString()}</text>
        </div>

        <div
          className="DetailProgress-Container"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          <div style={{ width: "50px" }}>
            <AnimatedDiv
              style={{
                fontSize: "20px",
                lineHeight: 1,
                fontWeight: "bold",
                color: "#D0A360",
                padding: 0,
              }}
            >
              {gaugeProgress.value.to((value) => Math.floor(value) + "%")}
            </AnimatedDiv>
          </div>
          <div
            className="DetailProgress_Gauge_Number"
            style={{
              backgroundColor: "#EAEAED",
              height: "7px",
              borderRadius: 100,
            }}
          >
            <AnimatedDiv
              className="DetailProgress_Gauge"
              style={{
                width: gaugeProgress.value.to((value) => value + "%"),
                borderRadius: 100,
              }}
            ></AnimatedDiv>
          </div>
        </div>

        <CarouselImage data={data.fImages} />

        <div style={{ width: "100%", height: "5px" }} />
      </div>

      {/* Keterangan */}
      <div style={{ marginTop: "10px" }} className="DetailProjectContainer">
        <div style={{ display: "flex", flexDirection: "column", width: "90%" }}>
          <text style={{ fontSize: "16px", fontWeight: "bold" }}>
            Keterangan
          </text>
          <div style={{ height: "10px" }} />
          <text style={{ fontSize: "16px" }}>
            {renderTextWithLineBreaks(data.fKet)}
          </text>
        </div>

        <div style={{ width: "100%", height: "5px" }} />
      </div>

      {/* Progress */}
      <div
        style={{ marginTop: "10px", backgroundColor: "#B1A4FB" }}
        className="DetailProjectContainer"
      >
        <div style={{ display: "flex", flexDirection: "column", width: "95%" }}>
          <text
            style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "2.5%" }}
          >
            Progress
          </text>
          <div className="LaporanCardContainer">
            {data.fProgress.map((d) => {
              return <ProgressCard data={d} user={user} closeModalPicture={setOpenModalPicture} setImage={setImage} setImageIndex={setImageIndex} />;
            })}
          </div>

          <div
            style={{
              marginTop: "15px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              onClick={() => {
                navigate("/allprojectprogress", { state: { fID } });
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "#D0A360",
                fontSize: "14px",
                fontWeight: "bold",
                width: "90%",
                padding: "7px 0",
                backgroundColor: "white",
                borderRadius: 100,
                textDecoration: "none",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              }}
            >
              Lihat Semua
            </div>
          </div>
        </div>

        <div style={{ width: "100%", height: "5px" }} />
      </div>

      {/* MoM/Notulen */}
      <div
        style={{ marginTop: "10px", backgroundColor: "#FBA4C3" }}
        className="DetailProjectContainer"
      >
        <div style={{ display: "flex", flexDirection: "column", width: "95%" }}>
          <text
            style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "2.5%" }}
          >
            MoM/Notulen
          </text>
          <div className="LaporanCardContainer">
            {notulen.map((d) => {
              const check = d.fProjectName === data.fProjectName ? true : false;
              return check ? <Notulencard data={d} /> : <div />;
            })}
          </div>

          <div
            style={{
              marginTop: "15px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              onClick={() => {
                navigate("/allprojectnotulen", { state: { fID } });
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "#D0A360",
                fontSize: "14px",
                fontWeight: "bold",
                width: "90%",
                padding: "7px 0",
                backgroundColor: "white",
                borderRadius: 100,
                textDecoration: "none",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              }}
            >
              Lihat Semua
            </div>
          </div>
        </div>

        <div style={{ width: "100%", height: "5px" }} />
      </div>

      {/* Pengajuan */}
      <div
        style={{ marginTop: "10px", backgroundColor: "#B8F3C1" }}
        className="DetailProjectContainer"
      >
        <div style={{ display: "flex", flexDirection: "column", width: "95%" }}>
          <text
            style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "2.5%" }}
          >
            Pengajuan
          </text>
          <div className="LaporanCardContainer">
            {pengajuan.map((d) => {
              const check = d.fProjectName === data.fProjectName ? true : true;
              return check ? <PengajuanCard data={d} /> : <div />;
            })}
          </div>

          <div
            style={{
              marginTop: "15px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              onClick={() => {
                navigate("/allprojectpengajuan", { state: { fID } });
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "#D0A360",
                fontSize: "14px",
                fontWeight: "bold",
                width: "90%",
                padding: "7px 0",
                backgroundColor: "white",
                borderRadius: 100,
                textDecoration: "none",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              }}
            >
              Lihat Semua
            </div>
          </div>
        </div>

        <div style={{ width: "100%", height: "5px" }} />
      </div>
      {openModalPicture && (
        <ModalPicture closeModalPicture={setOpenModalPicture} picture={image} index={imageIndex} setIndex={setImageIndex} />
      )}
      <div style={{ height: "30px" }} />
    </div>
  );
};

export default DetailProject;
