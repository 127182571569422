import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import TotalCard from "../../Components/TotalCard/TotalCard";
import LaporanProject from "../../Components/LaporanProject/LaporanProject";
import ProgressSPV from "../../Components/ProgressSPV/ProgressSPV";
import Notulencard from "../../Components/NotulenCard/NotulenCard";
import PengajuanCard from "../../Components/PengajuanCard/PengajuanCard";
import TaskCard from "../../Components/TaskCard/TaskCard";
import ModalCheckIn from "../../Components/Modal/ModalCheckIn";
import "./HomePage.css";
import "@fontsource/poppins";
import banner from "../../assets/banner.png";

import checkinlogo from "../../assets/checkin.png";
import checkoutlogo from "../../assets/checkout.png";
import cutisakitlogo from "../../assets/cutisakit.png";
import historyabsensilogo from "../../assets/historyabsensi.png";
import tambahprogresslogo from "../../assets/tambahprogress.png";
import kasbonlogo from "../../assets/kasbon.png";
import activitylogo from "../../assets/activity.png";
import itemlogo from "../../assets/itemlogo.png";
import pembelianlogo from "../../assets/pembelian.png";
import pembayaranlogo from "../../assets/pembayaran.png";
import listpengajuanlogo from "../../assets/listpengajuan.png";

import pengajuanlogo from "../../assets/pengajuan.png";
import notulenlogo from "../../assets/notulen.png";
import projectberjalanlogo from "../../assets/projectberjalan.png";
import tasklogo from "../../assets/task.png";
import progressspvlogo from "../../assets/progressspv.png";
import projectselesailogo from "../../assets/projectselesai.png";
import calonclientlogo from "../../assets/calonclient.png";
import absensispvlogo from "../../assets/absensispv.png";
import izincutilogo from "../../assets/izincuti.png";
import adduserlogo from "../../assets/adduser.png";
import planninglogo from "../../assets/planning.png";

import { Link } from "react-router-dom";

import dayjs from "dayjs";

import axios from "axios";
import { jwtDecode } from "jwt-decode";
import PlanningCard from "../../Components/PlanningCard/PlanningCard";
import KasbonCard from "../../Components/KasbonCard/KasbonCard";
import ActivityCard from "../../Components/ActivityCard/ActivityCard";
import PotentialClientCard from "../../Components/PotentialClientCard/PotentialClientCard";
import PembelianCard from "../../Components/PembelianCard/PembelianCard";
import PembayaranCard from "../../Components/PembayaranCard/PembayaranCard";

const HomePage = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [openModal, setOpenModal] = useState(false);
  const [openModalCheckOut, setOpenModalCheckOut] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [today, setToday] = useState(false);
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [startDate, setStartDate] = useState(
    dayjs(new Date().setHours(0, 0, 0, 0))
  );
  const [endDate, setEndDate] = useState(
    dayjs(new Date().setHours(23, 59, 59, 999))
  );

  openModal
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");
  const navigate = useNavigate();

  const { state } = useLocation();

  const [user, setUser] = useState({
    fID: "",
    fUserName: "",
    fEmail: "",
    fImages: "",
    fName: "",
    fRoleAlias: "",
    fRole: "",
    fAddress: "",
    fPhoneNumber: "",
    fActive: "",
  });

  const [projectDropdown, setProjectDropdown] = useState([]);
  const [selectedProject, setSelectedProject] = useState("Pilih Project");
  const [selectedProjectData, setSelectedProjectData] = useState();

  const [project, setProject] = useState([]);
  const [absensi, setAbsensi] = useState([]);
  const [progressSPV, setProgressSPV] = useState([]);
  const [activity, setActivity] = useState([]);
  const [notulen, setNotulen] = useState([]);
  const [pengajuan, setPengajuan] = useState([]);
  const [task, setTask] = useState([]);
  const [planning, setPlanning] = useState([]);
  const [kasbon, setKasbon] = useState([]);
  const [potentialClient, setPotentialClient] = useState([]);
  const [pembelian, setPembelian] = useState([]);
  const [pembayaran, setPembayaran] = useState([]);

  const [totalPengajuan, setTotalPengajuan] = useState(0);
  const [totalNotulen, setTotalNotulen] = useState(0);
  const [totalProjectBerjalan, setTotalProjectBerjalan] = useState(0);
  const [totalProjectSelesai, setTotalProjectSelesai] = useState(0);
  const [totalTask, setTotalTask] = useState(0);
  const [totalPlan, setTotalPlan] = useState(0);
  const [totalKasbon, setTotalKasbon] = useState(0);
  const [totalProgress, setTotalProgress] = useState(0);
  const [totalActivity, setTotalActivity] = useState(0);
  const [totalPotentialClient, setTotalPotentialClient] = useState(0);
  const [totalPembelian, setTotalPembelian] = useState(0);
  const [totalPembayaran, setTotalPembayaran] = useState(0);
  const [totalPengajuanPembelian, setTotalPengajuanPembelian] = useState(0);
  const [totalValidItems, setTotalValidItems] = useState(0);
  const [absen, setAbsen] = useState(true);

  const [buttonTask, setButtonTask] = useState(false);
  const buttonStyle = {
    on: {
      width: "120px",
      backgroundColor: "#D0A360",
      borderRadius: 100,
      color: "white",
      fontWeight: "bold",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "5px 0",
    },
    off: {
      width: "120px",
      backgroundColor: "white",
      color: "#D0A360",
      borderRadius: 100,
      fontWeight: "bold",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "5px 0",
    },
  };

  const onClickButtonTask = () => {
    if (buttonTask === false) {
      setButtonTask(true);
    }
  };

  const onClickButtonAssigned = () => {
    if (buttonTask === true) {
      setButtonTask(false);
      setRefresh(!refresh);
    }
  };

  axios.defaults.withCredentials = true;

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    getAllProject();
    if (user.fRole === "BOSS" || user.fRole === "PM" || user.fRole === "SPV") {
      getAllProgressSPV();
      getAllNotulen();
      getAllPengajuan();
      getAllPlanning();
      getAllKasbon();
    }

    if (user.fRole === "SPV" || user.fRole === "SUPIR") {
      getProjectDropdown();
    }

    if (
      user.fRole === "BOSS" ||
      user.fRole === "PM" ||
      user.fRole === "MARKETING" ||
      user.fRole === "SUPIR"
    ) {
      getActivity();
    }

    if (
      user.fRole === "BOSS" ||
      user.fRole === "PM" ||
      user.fRole === "MARKETING"
    ) {
      getAllClient();
    }

    if (
      user.fRole === "BOSS" ||
      user.fRole === "PM" ||
      user.fRole === "FINANCE"
    ) {
      getTotalValidItems();
      getTotalPengajuanPembelian();
      getAllPembelian();
      getAllPembayaran();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      getAllTask();
    }
  }, [user, buttonTask]);

  useEffect(() => {
    if (
      user.fRole !== "BOSS" &&
      user.fRole !== "CLIENT" &&
      user.fRole &&
      startDate < endDate
    ) {
      getAbsensi();
    }
  }, [user, projectDropdown, absen]);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      setToken(response.data.accessToken);
      setUser(response.data.user[0]);
      console.log(response.data.user[0]);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (e) {
      if (e.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAbsensi = async () => {
    const fID = user.fID;
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getabsensi`,
        {
          fID: fID,
          page: 1,
          limit: 3,
          type: "PM",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setAbsensi(response.data.data);

      const last_absen = dayjs(
        new Date(response.data.data[0].fCheckInDateTime)
      );
      const today = dayjs(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      // last_absen === today
      if (last_absen.isAfter(today)) {
        setToday(true);
        setOpenModal(false);
      } else {
        setOpenModal(true);
      }
    } catch (error) {
      if (user.fRole !== "BOSS" && user.fRole !== "CLIENT" && user.fRole) {
        setOpenModal(true);
      }
    }
  };

  const getProjectDropdown = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getprojectdropdown`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setProjectDropdown(response.data);
      selectedProjectData(response.data[0].fProjectName);
    } catch (error) {}
  };

  const getAllProject = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getproject`,
        { page: 1, limit: 3 },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setProject(response.data.data);
      setTotalProjectBerjalan(response.data.meta.totalOngoingItems);
      setTotalProjectSelesai(response.data.meta.totalCompletedItems);
    } catch (error) {}
  };

  const getAllClient = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getallpotentialclient`,
        { page: 1, limit: 3 },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPotentialClient(response.data.data);
      setTotalPotentialClient(response.data.meta.totalItems);
    } catch (error) {}
  };

  const getAllPembelian = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getallpembelian`,
        { page: 1, limit: 3, type: "ongoing" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPembelian(response.data.data);
      setTotalPembelian(response.data.meta.totalItems);
    } catch (error) {}
  };

  const getAllPembayaran = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getallpembayaran`,
        { page: 1, limit: 3 },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPembayaran(response.data.data);
      setTotalPembayaran(response.data.meta.totalItems);
    } catch (error) {}
  };

  const getTotalValidItems = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/gettotalitems`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTotalValidItems(response.data.totalItems);
    } catch (error) {}
  };

  const getTotalPengajuanPembelian = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/totalpengajuanpembelian`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTotalPengajuanPembelian(response.data);
    } catch (error) {}
  };

  const getAllProgressSPV = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getprogressspv`,
        { page: 1, limit: 3 },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setProgressSPV(response.data.data);
      setTotalProgress(response.data.meta.totalItems);
    } catch (error) {}
  };

  const getActivity = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getactivity`,
        { page: 1, limit: 3 },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setActivity(response.data.data);
      setTotalActivity(response.data.meta.totalItems);
    } catch (error) {}
  };

  const getAllNotulen = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getallnotulen`,
        { page: 1, limit: 3, type: "home" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setNotulen(response.data.data);
      setTotalNotulen(response.data.meta.totalItems);
    } catch (error) {}
  };

  const getAllPengajuan = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getallpengajuan`,
        { page: 1, limit: 3, type: "home" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPengajuan(response.data.data);
      setTotalPengajuan(response.data.meta.totalItems);
    } catch (error) {}
  };

  const getAllTask = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getalltask`,
        {
          page: 1,
          limit: 3,
          type: buttonTask ? "Task" : "Assigned",
          status: "home",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTask(response.data.data);
      setTotalTask(response.data.meta.totalItems);
    } catch (error) {}
  };

  const getAllPlanning = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getallplanning`,
        { page: 1, limit: 3 },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPlanning(response.data.data);
      setTotalPlan(response.data.meta.totalItems);
    } catch (error) {}
  };

  const getAllKasbon = async () => {
    try {
      const response = await axiosJWT.post(
        `${API_URL}/getallkasbon`,
        { page: 1, limit: 3, type: "home" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setKasbon(response.data.data);
      setTotalKasbon(response.data.meta.totalItems);
    } catch (error) {}
  };

  const handleClickAddProject = () => {
    navigate("/addproject");
  };

  const handleClickAddProgressSPV = () => {
    navigate("/addprogress");
  };

  const handleClickAddActivity = () => {
    navigate("/addactivity");
  };

  const handleClickAddNotulen = () => {
    navigate("/addnotulen");
  };

  const handleClickAddPengajuan = () => {
    navigate("/addpengajuan");
  };

  const handleClickAddPembelian = () => {
    if (user.fRole === "FINANCE") {
      navigate("/addpembelian", {
        state: {
          fPengajuan: [],
          fListSelected: new Map(),
          fListByItem: [],
          fIndexing: [],
        },
      });
    }
  };

  const handleClickAddPembayaran = () => {
    if (user.fRole === "FINANCE") {
      navigate("/addpembayaran");
    }
  };

  const handleClickAddTask = () => {
    if (user.fRole === "BOSS" || user.fRole === "PM") {
      navigate("/addtask");
    }
  };

  const handleClickAddPlanning = () => {
    if (user.fRole === "SPV") {
      navigate("/addplanning");
    }
  };

  const handleClickAddKasbon = () => {
    if (user.fRole === "SPV") {
      navigate("/addkasbon");
    }
  };

  const handleClickAddClient = () => {
    if (user.fRole === "MARKETING") {
      navigate("/addclient");
    }
  };

  let checkin;

  return (
    <div
      style={{
        backgroundColor: "#F3F4F8",
        fontFamily: "Poppins",
        color: "#282828",
        minHeight: "100vh",
        paddingTop: "75px",
        paddingBottom: "25px",
      }}
    >
      {user && <Navbar profileData={user} />}
      <div
        style={{
          width: "95%",
          margin: "auto",
          paddingTop: "15px",
          maxWidth: "1024px",
        }}
      >
        {user.fRole !== "CLIENT" && (
          <text style={{ fontSize: "20px", fontWeight: 600 }}>
            Hello, {user && user.fName}
          </text>
        )}
        {user.fRole !== "CLIENT" && <div />}

        {user.fRole !== "CLIENT" && <div style={{ height: "15px" }} />}

        {user.fRole === "BOSS" && (
          <div className="card-Container">
            <div
              onClick={() => {
                navigate("/adduser");
              }}
            >
              <TotalCard
                data={{
                  logo: adduserlogo,
                  total: "",
                  keterangan: "Tambah User",
                  bgColor: "#FFA006",
                  textColor: "#7DB2AD",
                }}
              />
            </div>

            <div
              onClick={() => {
                navigate("/allabsensi");
              }}
            >
              <TotalCard
                data={{
                  logo: historyabsensilogo,
                  total: "",
                  keterangan: "Absensi",
                  bgColor: "#DEB8F3",
                  textColor: "#EAF591",
                }}
              />
            </div>

            <div
              onClick={() => {
                navigate("/allabsensirequest");
              }}
            >
              <TotalCard
                data={{
                  logo: cutisakitlogo,
                  total: "",
                  keterangan: "Cuti/Sakit",
                  bgColor: "#C1B8F3",
                  textColor: "#9381FF",
                }}
              />
            </div>
          </div>
        )}

        {user.fRole !== "BOSS" && user.fRole !== "CLIENT" && (
          <div className="card-Container">
            {user.fRole === "PM" && (
              <div
                onClick={() => {
                  navigate("/adduser");
                }}
              >
                <TotalCard
                  data={{
                    logo: adduserlogo,
                    total: "",
                    keterangan: "Tambah User",
                    bgColor: "#FFA006",
                    textColor: "#7DB2AD",
                  }}
                />
              </div>
            )}

            <TotalCard
              data={{
                logo: checkinlogo,
                total:
                  today && absensi[0].fCheckIn === "Y"
                    ? dayjs(absensi[0].fCheckInDateTime)
                        .format("HH:mm")
                        .toString()
                    : "-",
                keterangan: "Check-In",
                bgColor: "#FBA4EE",
                textColor: "#EAF591",
              }}
            />
            <div
              onClick={() => {
                today &&
                  absensi[0].fCheckOut === "N" &&
                  setOpenModalCheckOut(true);
              }}
            >
              <TotalCard
                data={{
                  logo: checkoutlogo,
                  total:
                    today && absensi[0].fCheckOut === "Y"
                      ? dayjs(absensi[0].fCheckOutDateTime)
                          .format("HH:mm")
                          .toString()
                      : "-",
                  keterangan: "Check-Out",
                  bgColor: "#DCA4FB",
                  textColor: "#9381FF",
                }}
              />
            </div>

            {user.fRole === "SPV" && (
              <div
                onClick={() => {
                  navigate("/addprogress");
                }}
              >
                <TotalCard
                  data={{
                    logo: tambahprogresslogo,
                    total: "",
                    keterangan: "Tambah Progress",
                    bgColor: "#B1A4FB",
                    textColor: "#EAF591",
                  }}
                />
              </div>
            )}

            <div
              onClick={() => {
                navigate("/allabsensirequest");
              }}
            >
              <TotalCard
                data={{
                  logo: cutisakitlogo,
                  total: "",
                  keterangan: "Cuti/Sakit",
                  bgColor: "#C1B8F3",
                  textColor: "#9381FF",
                }}
              />
            </div>

            <div
              onClick={() => {
                navigate("/allabsensi");
              }}
            >
              <TotalCard
                data={{
                  logo: historyabsensilogo,
                  total: "",
                  keterangan: "History Absensi",
                  bgColor: "#DEB8F3",
                  textColor: "#EAF591",
                }}
              />
            </div>
          </div>
        )}

        {user.fRole !== "CLIENT" && (
          <div
            style={{
              width: "92px",
              height: "2px",
              borderRadius: 100,
              backgroundColor: "#D9D9D9",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "25px",
              marginBottom: "25px",
            }}
          />
        )}

        {/* Total Card */}
        {user.fRole !== "CLIENT" && (
          <div className="card-Container">
            {(user.fRole === "BOSS" ||
              user.fRole === "PM" ||
              user.fRole === "SPV") && (
              <div
                onClick={() => {
                  navigate("/allpengajuan");
                }}
              >
                <TotalCard
                  data={{
                    logo: pengajuanlogo,
                    total: totalPengajuan,
                    keterangan: "Total Pengajuan",
                    bgColor: "#B8F3C1",
                    textColor: "#7DB2AD",
                  }}
                />
              </div>
            )}
            {(user.fRole === "BOSS" ||
              user.fRole === "PM" ||
              user.fRole === "SPV") && (
              <div
                onClick={() => {
                  navigate("/allnotulen");
                }}
              >
                <TotalCard
                  data={{
                    logo: notulenlogo,
                    total: totalNotulen,
                    keterangan: "MoM/Notulen",
                    bgColor: "#FBA4C3",
                    textColor: "#F797B8",
                  }}
                />
              </div>
            )}

            {(user.fRole === "BOSS" ||
              user.fRole === "PM" ||
              user.fRole === "SPV" ||
              user.fRole === "SUPIR") && (
              <div
                onClick={() => {
                  navigate("/allproject");
                }}
              >
                <TotalCard
                  data={{
                    logo: projectberjalanlogo,
                    total: totalProjectBerjalan,
                    keterangan: "Project Berjalan",
                    bgColor: "#A4C3FB",
                    textColor: "#7AABD6",
                  }}
                />
              </div>
            )}

            {(user.fRole === "BOSS" ||
              user.fRole === "PM" ||
              user.fRole === "SPV" ||
              user.fRole === "SUPIR") && (
              <div
                onClick={() => {
                  navigate("/alltask");
                }}
              >
                <TotalCard
                  data={{
                    logo: tasklogo,
                    total: totalTask,
                    keterangan: "Total Task",
                    bgColor: "#FBB1A4",
                    textColor: "#F4CFBB",
                  }}
                />
              </div>
            )}

            {(user.fRole === "BOSS" ||
              user.fRole === "PM" ||
              user.fRole === "SPV") && (
              <div
                onClick={() => {
                  navigate("/allplanning");
                }}
              >
                <TotalCard
                  data={{
                    logo: planninglogo,
                    total: totalPlan,
                    keterangan: "Total Plan",
                    bgColor: "#74F4D5",
                    textColor: "#F4CFBB",
                  }}
                />
              </div>
            )}

            {(user.fRole === "BOSS" ||
              user.fRole === "PM" ||
              user.fRole === "SPV") && (
              <div
                onClick={() => {
                  navigate("/allkasbon");
                }}
              >
                <TotalCard
                  data={{
                    logo: kasbonlogo,
                    total: totalKasbon,
                    keterangan: "Kasbon",
                    bgColor: "#A4E9EA",
                    textColor: "#F797B8",
                  }}
                />
              </div>
            )}

            {(user.fRole === "BOSS" ||
              user.fRole === "PM" ||
              user.fRole === "SPV") && (
              <div
                onClick={() => {
                  navigate("/allprogress");
                }}
              >
                <TotalCard
                  data={{
                    logo: progressspvlogo,
                    total: totalProgress,
                    keterangan: "Total Progress",
                    bgColor: "#B1A4FB",
                    textColor: "#9787FF",
                  }}
                />
              </div>
            )}

            {(user.fRole === "BOSS" ||
              user.fRole === "PM" ||
              user.fRole === "SUPIR" ||
              user.fRole === "MKT") && (
              <div
                onClick={() => {
                  navigate("/allactivity");
                }}
              >
                <TotalCard
                  data={{
                    logo: activitylogo,
                    total: totalActivity,
                    keterangan: "Total Activity",
                    bgColor: "#E89292",
                    textColor: "#9787FF",
                  }}
                />
              </div>
            )}

            {user.fRole === "BOSS" && (
              <div
                onClick={() => {
                  navigate("/allproject");
                }}
              >
                <TotalCard
                  data={{
                    logo: projectselesailogo,
                    total: totalProjectSelesai,
                    keterangan: "Project Selesai",
                    bgColor: "#B8EAF3",
                    textColor: "#9787FF",
                  }}
                />
              </div>
            )}

            {user.fRole === "BOSS" && (
              <div
                onClick={() => {
                  navigate("/allclient");
                }}
              >
                <TotalCard
                  data={{
                    logo: calonclientlogo,
                    total: totalPotentialClient,
                    keterangan: "Calon Owner",
                    bgColor: "#F0DBBB",
                    textColor: "#9787FF",
                  }}
                />
              </div>
            )}

            {(user.fRole === "BOSS" ||
              user.fRole === "PM" ||
              user.fRole === "FINANCE") && (
              <div
                onClick={() => {
                  navigate("/allitems");
                }}
              >
                <TotalCard
                  data={{
                    logo: itemlogo,
                    total: totalValidItems,
                    keterangan: "Semua Item",
                    bgColor: "#FEF8B5",
                    textColor: "#9787FF",
                  }}
                />
              </div>
            )}

            {(user.fRole === "BOSS" ||
              user.fRole === "PM" ||
              user.fRole === "FINANCE") && (
              <div
                onClick={() => {
                  navigate("/listpengajuan");
                }}
              >
                <TotalCard
                  data={{
                    logo: listpengajuanlogo,
                    total: totalPengajuanPembelian,
                    keterangan: "Pengajuan Pembelian",
                    bgColor: "#8bd0f3",
                    textColor: "#9787FF",
                  }}
                />
              </div>
            )}

            {(user.fRole === "BOSS" ||
              user.fRole === "PM" ||
              user.fRole === "FINANCE") && (
              <div
                onClick={() => {
                  navigate("/allpembelian");
                }}
              >
                <TotalCard
                  data={{
                    logo: pembelianlogo,
                    total: totalPembelian,
                    keterangan: "Pembelian",
                    bgColor: "#8AE2D3",
                    textColor: "#9787FF",
                  }}
                />
              </div>
            )}
          </div>
        )}

        {/* Laporan Project untuk Owner */}
        {(user.fRole === "BOSS" ||
          user.fRole === "PM" ||
          user.fRole === "SPV" ||
          user.fRole === "SUPIR") && (
          <div
            style={{
              width: "100%",
              marginTop: "25px",
              fontWeight: 400,
              paddingTop: "20px",
              borderRadius: "7px",
              maxWidth: "1024px",
              backgroundColor: "#A4C3FB",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
          >
            <div
              style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}
            >
              <text style={{ fontWeight: "bold" }}>Laporan Project</text>
            </div>
            {project.length !== 0 ? (
              <div className="LaporanCardContainer">
                {project.map((d) => {
                  return <LaporanProject data={d} user={user} token={token} />;
                })}
              </div>
            ) : (
              <div
                onClick={() => {
                  if (user.fRole === "BOSS" || user.fRole === "PM") {
                    handleClickAddProject();
                  }
                }}
                className="LaporanCardContainer"
              >
                <div
                  style={{
                    width: "100%",
                    height: "400px",
                    backgroundColor: "white",
                    borderRadius: 7,
                    fontSize: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "center",
                      gap: "15px",
                      color: "#A4C3FB",
                    }}
                  >
                    <img
                      style={{ width: "80px" }}
                      src={projectberjalanlogo}
                      alt=""
                    />
                    <text>Tidak Ada Project</text>
                  </div>
                  {(user.fRole === "BOSS" || user.fRole === "PM") && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "95%",
                        alignItems: "center",
                        backgroundColor: "#D0A360",
                        color: "white",
                        padding: "5px 0",
                        marginTop: "30px",
                        borderRadius: 7,
                      }}
                    >
                      <text>Tambah Project</text>
                    </div>
                  )}
                </div>
              </div>
            )}

            {project.length > 0 && (
              <div
                style={{
                  marginTop: "15px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Link
                  to="/allproject"
                  state={{ role: user.fRole }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    color: "#D0A360",
                    fontSize: "14px",
                    fontWeight: "bold",
                    width: "90%",
                    padding: "7px 0",
                    backgroundColor: "white",
                    borderRadius: 100,
                    textDecoration: "none",
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                  }}
                >
                  Lihat Semua
                </Link>
              </div>
            )}
            <div style={{ height: "15px" }} />
          </div>
        )}

        {/* Progress SPV */}
        {(user.fRole === "BOSS" ||
          user.fRole === "PM" ||
          user.fRole === "SPV") && (
          <div
            style={{
              width: "100%",
              backgroundColor: "#B1A4FB",
              marginTop: "25px",
              fontWeight: 400,
              paddingTop: "20px",
              borderRadius: "7px",
              maxWidth: "1024px",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
          >
            <div
              style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}
            >
              <text style={{ fontWeight: "bold" }}>Progress SPV</text>
            </div>
            {progressSPV.length !== 0 ? (
              <div className="LaporanCardContainer">
                {progressSPV.map((d) => {
                  return <ProgressSPV data={d} user={user} token={token} />;
                })}
              </div>
            ) : (
              <div
                onClick={() => {
                  if (user.fRole === "SPV") {
                    handleClickAddProgressSPV();
                  }
                }}
                className="LaporanCardContainer"
              >
                <div
                  style={{
                    width: "100%",
                    height: "400px",
                    backgroundColor: "white",
                    borderRadius: 7,
                    fontSize: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "center",
                      gap: "15px",
                      color: "#A4C3FB",
                    }}
                  >
                    <img
                      style={{ width: "80px" }}
                      src={progressspvlogo}
                      alt=""
                    />
                    <text>Tidak Ada Progress</text>
                  </div>

                  {user.fRole === "SPV" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "95%",
                        alignItems: "center",
                        backgroundColor: "#D0A360",
                        color: "white",
                        padding: "5px 0",
                        marginTop: "30px",
                        borderRadius: 7,
                      }}
                    >
                      <text>Tambah Progress</text>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div
              style={{
                marginTop: "15px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Link
                to="/allprogress"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#D0A360",
                  fontSize: "14px",
                  fontWeight: "bold",
                  width: "90%",
                  padding: "7px 0",
                  backgroundColor: "white",
                  borderRadius: 100,
                  textDecoration: "none",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                }}
              >
                Lihat Semua
              </Link>
            </div>
            <div style={{ height: "15px" }} />
          </div>
        )}

        {/* Activity */}
        {(user.fRole === "BOSS" ||
          user.fRole === "PM" ||
          user.fRole === "SUPIR" ||
          user.fRole === "MARKETING") && (
          <div
            style={{
              width: "100%",
              backgroundColor: "#E89292",
              marginTop: "25px",
              fontWeight: 400,
              paddingTop: "20px",
              borderRadius: "7px",
              maxWidth: "1024px",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
          >
            <div
              style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}
            >
              <text style={{ fontWeight: "bold" }}>Activity</text>
            </div>
            {activity.length !== 0 ? (
              <div className="LaporanCardContainer">
                {activity.map((d) => {
                  return <ActivityCard data={d} user={user} token={token} />;
                })}
              </div>
            ) : (
              <div
                onClick={() => {
                  if (user.fRole === "SUPIR" || user.fRole === "MARKETING") {
                    handleClickAddActivity();
                  }
                }}
                className="LaporanCardContainer"
              >
                <div
                  style={{
                    width: "100%",
                    height: "400px",
                    backgroundColor: "white",
                    borderRadius: 7,
                    fontSize: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "center",
                      gap: "15px",
                      color: "#A4C3FB",
                    }}
                  >
                    <img style={{ width: "80px" }} src={activitylogo} alt="" />
                    <text>Tidak Ada Activity</text>
                  </div>

                  {(user.fRole === "SUPIR" || user.fRole === "MARKETING") && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "95%",
                        alignItems: "center",
                        backgroundColor: "#D0A360",
                        color: "white",
                        padding: "5px 0",
                        marginTop: "30px",
                        borderRadius: 7,
                      }}
                    >
                      <text>Tambah Activity</text>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div
              style={{
                marginTop: "15px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Link
                to="/allactivity"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#D0A360",
                  fontSize: "14px",
                  fontWeight: "bold",
                  width: "90%",
                  padding: "7px 0",
                  backgroundColor: "white",
                  borderRadius: 100,
                  textDecoration: "none",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                }}
              >
                Lihat Semua
              </Link>
            </div>
            <div style={{ height: "15px" }} />
          </div>
        )}

        {/* MoM/Notulen */}
        {(user.fRole === "BOSS" ||
          user.fRole === "PM" ||
          user.fRole === "SPV") && (
          <div
            style={{
              width: "100%",
              backgroundColor: "#FBA4C3",
              marginTop: "25px",
              fontWeight: 400,
              paddingTop: "20px",
              borderRadius: "7px",
              maxWidth: "1024px",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
          >
            <div
              style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}
            >
              <text style={{ fontWeight: "bold" }}>MoM/Notulen</text>
            </div>
            {notulen.length !== 0 ? (
              <div className="LaporanCardContainer">
                {notulen.slice(0, 3).map((d) => {
                  return <Notulencard data={d} user={user} token={token} />;
                })}
              </div>
            ) : (
              <div
                onClick={() => {
                  if (
                    user.fRole === "SPV" ||
                    user.fRole === "PM" ||
                    user.fRole === "BOSS"
                  ) {
                    handleClickAddNotulen();
                  }
                }}
                className="LaporanCardContainer"
              >
                <div
                  style={{
                    width: "100%",
                    height: "400px",
                    backgroundColor: "white",
                    borderRadius: 7,
                    fontSize: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "center",
                      gap: "15px",
                      color: "#A4C3FB",
                    }}
                  >
                    <img style={{ width: "80px" }} src={notulenlogo} alt="" />
                    <text>Tidak Ada Notulen</text>
                  </div>
                  {(user.fRole === "SPV" ||
                    user.fRole === "PM" ||
                    user.fRole === "BOSS") && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "95%",
                        alignItems: "center",
                        backgroundColor: "#D0A360",
                        color: "white",
                        padding: "5px 0",
                        marginTop: "30px",
                        borderRadius: 7,
                      }}
                    >
                      <text>Tambah Notulen</text>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div
              style={{
                marginTop: "15px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Link
                to="/allnotulen"
                state={{ role: user.fRole }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#D0A360",
                  fontSize: "14px",
                  fontWeight: "bold",
                  width: "90%",
                  padding: "7px 0",
                  backgroundColor: "white",
                  borderRadius: 100,
                  textDecoration: "none",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                }}
              >
                Lihat Semua
              </Link>
            </div>
            <div style={{ height: "15px" }} />
          </div>
        )}

        {/* Pengajuan */}
        {(user.fRole === "BOSS" ||
          user.fRole === "PM" ||
          user.fRole === "SPV") && (
          <div
            style={{
              width: "100%",
              backgroundColor: "#B8F3C1",
              marginTop: "25px",
              fontWeight: 400,
              paddingTop: "20px",
              borderRadius: "7px",
              maxWidth: "1024px",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
          >
            <div
              style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}
            >
              <text style={{ fontWeight: "bold" }}>Pengajuan</text>
            </div>
            {pengajuan.length !== 0 ? (
              <div className="LaporanCardContainer">
                {pengajuan.slice(0, 3).map((d) => {
                  return <PengajuanCard data={d} />;
                })}
              </div>
            ) : (
              <div
                onClick={() => {
                  if (
                    user.fRole === "SPV" ||
                    user.fRole === "PM" ||
                    user.fRole === "BOSS"
                  ) {
                    handleClickAddPengajuan();
                  }
                }}
                className="LaporanCardContainer"
              >
                <div
                  style={{
                    width: "100%",
                    height: "400px",
                    backgroundColor: "white",
                    borderRadius: 7,
                    fontSize: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "center",
                      gap: "15px",
                      color: "#A4C3FB",
                    }}
                  >
                    <img style={{ width: "80px" }} src={pengajuanlogo} alt="" />
                    <text>Tidak Ada Pengajuan</text>
                  </div>
                  {(user.fRole === "SPV" ||
                    user.fRole === "PM" ||
                    user.fRole === "BOSS") && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "95%",
                        alignItems: "center",
                        backgroundColor: "#D0A360",
                        color: "white",
                        padding: "5px 0",
                        marginTop: "30px",
                        borderRadius: 7,
                      }}
                    >
                      <text>Tambah Pengajuan</text>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div
              style={{
                marginTop: "15px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Link
                to="/allpengajuan"
                state={{ role: user.fRole }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#D0A360",
                  fontSize: "14px",
                  fontWeight: "bold",
                  width: "90%",
                  padding: "7px 0",
                  backgroundColor: "white",
                  borderRadius: 100,
                  textDecoration: "none",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                }}
              >
                Lihat Semua
              </Link>
            </div>
            <div style={{ height: "15px" }} />
          </div>
        )}

        {/* Pembelian */}
        {(user.fRole === "BOSS" ||
          user.fRole === "PM" ||
          user.fRole === "FINANCE") && (
          <div
            style={{
              width: "100%",
              backgroundColor: "#8AE2D3",
              marginTop: "25px",
              fontWeight: 400,
              paddingTop: "20px",
              borderRadius: "7px",
              maxWidth: "1024px",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
          >
            <div
              style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}
            >
              <text style={{ fontWeight: "bold" }}>Pembelian</text>
            </div>
            {pembelian.length !== 0 ? (
              <div className="LaporanCardContainer">
                {pembelian.map((d) => {
                  return <PembelianCard data={d} />;
                })}
              </div>
            ) : (
              <div
                onClick={() => {
                  if (user.fRole === "FINANCE" && buttonTask) {
                    handleClickAddPembelian();
                  }
                }}
                className="LaporanCardContainer"
              >
                <div
                  style={{
                    width: "100%",
                    height: "400px",
                    backgroundColor: "white",
                    borderRadius: 7,
                    fontSize: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "center",
                      gap: "15px",
                      color: "#A4C3FB",
                    }}
                  >
                    <img style={{ width: "80px" }} src={pembelianlogo} alt="" />
                    <text>Tidak Ada Pembelian</text>
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                marginTop: "15px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Link
                to="/allpembelian"
                state={{ role: user.fRole }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#D0A360",
                  fontSize: "14px",
                  fontWeight: "bold",
                  width: "90%",
                  padding: "7px 0",
                  backgroundColor: "white",
                  borderRadius: 100,
                  textDecoration: "none",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                }}
              >
                Lihat Semua
              </Link>
            </div>
            <div style={{ height: "15px" }} />
          </div>
        )}

        {/* Pembayaran */}
        {(user.fRole === "BOSS" ||
          user.fRole === "PM" ||
          user.fRole === "FINANCE") && (
          <div
            style={{
              width: "100%",
              backgroundColor: "#89AC76",
              marginTop: "25px",
              fontWeight: 400,
              paddingTop: "20px",
              borderRadius: "7px",
              maxWidth: "1024px",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
          >
            <div
              style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}
            >
              <text style={{ fontWeight: "bold" }}>Pembayaran</text>
            </div>
            {pembayaran.length !== 0 ? (
              <div className="LaporanCardContainer">
                {pembayaran.slice(0, 3).map((d) => {
                  return <PembayaranCard data={d} />;
                })}
              </div>
            ) : (
              <div
                onClick={() => {
                  if (user.fRole === "FINANCE") {
                    handleClickAddPembayaran();
                  }
                }}
                className="LaporanCardContainer"
              >
                <div
                  style={{
                    width: "100%",
                    height: "400px",
                    backgroundColor: "white",
                    borderRadius: 7,
                    fontSize: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "center",
                      gap: "15px",
                      color: "#A4C3FB",
                    }}
                  >
                    <img
                      style={{ width: "80px" }}
                      src={pembayaranlogo}
                      alt=""
                    />
                    <text>Tidak Ada Pembayaran</text>
                  </div>
                  {user.fRole === "FINANCE" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "95%",
                        alignItems: "center",
                        backgroundColor: "#D0A360",
                        color: "white",
                        padding: "5px 0",
                        marginTop: "30px",
                        borderRadius: 7,
                      }}
                    >
                      <text>Tambah Pembayaran</text>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div
              style={{
                marginTop: "15px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Link
                to="/allpembayaran"
                state={{ role: user.fRole }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#D0A360",
                  fontSize: "14px",
                  fontWeight: "bold",
                  width: "90%",
                  padding: "7px 0",
                  backgroundColor: "white",
                  borderRadius: 100,
                  textDecoration: "none",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                }}
              >
                Lihat Semua
              </Link>
            </div>
            <div style={{ height: "15px" }} />
          </div>
        )}

        {/* Task */}
        {user.fRole !== "CLIENT" && (
          <div
            style={{
              width: "100%",
              backgroundColor: "#FBB1A4",
              marginTop: "25px",
              fontWeight: 400,
              paddingTop: "20px",
              borderRadius: "7px",
              maxWidth: "1024px",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
          >
            <div
              style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}
            >
              <text style={{ fontWeight: "bold" }}>Task</text>

              {user.fRole === "PM" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  <text
                    onClick={onClickButtonTask}
                    style={buttonTask ? buttonStyle.on : buttonStyle.off}
                  >
                    Task
                  </text>
                  <text
                    onClick={onClickButtonAssigned}
                    style={buttonTask ? buttonStyle.off : buttonStyle.on}
                  >
                    Assigned
                  </text>
                </div>
              )}
            </div>
            {task.length !== 0 ? (
              <div className="LaporanCardContainer">
                {task.slice(0, 3).map((d) => {
                  return <TaskCard data={d} />;
                })}
              </div>
            ) : (
              <div
                onClick={() => {
                  if (
                    (user.fRole === "BOSS" || user.fRole === "PM") &&
                    buttonTask
                  ) {
                    handleClickAddTask();
                  }
                }}
                className="LaporanCardContainer"
              >
                <div
                  style={{
                    width: "100%",
                    height: "400px",
                    backgroundColor: "white",
                    borderRadius: 7,
                    fontSize: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "center",
                      gap: "15px",
                      color: "#A4C3FB",
                    }}
                  >
                    <img style={{ width: "80px" }} src={tasklogo} alt="" />
                    <text>Tidak Ada Task</text>
                  </div>
                  {buttonTask &&
                    (user.fRole === "BOSS" || user.fRole === "PM") && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "95%",
                          alignItems: "center",
                          backgroundColor: "#D0A360",
                          color: "white",
                          padding: "5px 0",
                          marginTop: "30px",
                          borderRadius: 7,
                        }}
                      >
                        <text>Tambah Task</text>
                      </div>
                    )}
                </div>
              </div>
            )}
            <div
              style={{
                marginTop: "15px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Link
                to="/alltask"
                state={{ role: user.fRole }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#D0A360",
                  fontSize: "14px",
                  fontWeight: "bold",
                  width: "90%",
                  padding: "7px 0",
                  backgroundColor: "white",
                  borderRadius: 100,
                  textDecoration: "none",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                }}
              >
                Lihat Semua
              </Link>
            </div>
            <div style={{ height: "15px" }} />
          </div>
        )}

        {/* Planning */}
        {(user.fRole === "BOSS" ||
          user.fRole === "PM" ||
          user.fRole === "SPV") && (
          <div
            style={{
              width: "100%",
              backgroundColor: "#74F4D5",
              marginTop: "25px",
              fontWeight: 400,
              paddingTop: "20px",
              borderRadius: "7px",
              maxWidth: "1024px",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
          >
            <div
              style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}
            >
              <text style={{ fontWeight: "bold" }}>Planning</text>
            </div>
            {planning.length !== 0 ? (
              <div className="LaporanCardContainer">
                {planning.slice(0, 3).map((d) => {
                  return <PlanningCard data={d} />;
                })}
              </div>
            ) : (
              <div
                onClick={() => {
                  if (user.fRole === "SPV") {
                    handleClickAddPlanning();
                  }
                }}
                className="LaporanCardContainer"
              >
                <div
                  style={{
                    width: "100%",
                    height: "400px",
                    backgroundColor: "white",
                    borderRadius: 7,
                    fontSize: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "center",
                      gap: "15px",
                      color: "#A4C3FB",
                    }}
                  >
                    <img style={{ width: "80px" }} src={planninglogo} alt="" />
                    <text>Tidak Ada Planning</text>
                  </div>
                  {user.fRole === "SPV" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "95%",
                        alignItems: "center",
                        backgroundColor: "#D0A360",
                        color: "white",
                        padding: "5px 0",
                        marginTop: "30px",
                        borderRadius: 7,
                      }}
                    >
                      <text>Tambah Planning</text>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div
              style={{
                marginTop: "15px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Link
                to="/allplanning"
                state={{ role: user.fRole }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#D0A360",
                  fontSize: "14px",
                  fontWeight: "bold",
                  width: "90%",
                  padding: "7px 0",
                  backgroundColor: "white",
                  borderRadius: 100,
                  textDecoration: "none",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                }}
              >
                Lihat Semua
              </Link>
            </div>
            <div style={{ height: "15px" }} />
          </div>
        )}

        {/* Potential Owner */}
        {(user.fRole === "BOSS" ||
          user.fRole === "PM" ||
          user.fRole === "MARKETING") && (
          <div
            style={{
              width: "100%",
              backgroundColor: "#F0DBBB",
              marginTop: "25px",
              fontWeight: 400,
              paddingTop: "20px",
              borderRadius: "7px",
              maxWidth: "1024px",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
          >
            <div
              style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}
            >
              <text style={{ fontWeight: "bold" }}>Potential Owner</text>
            </div>
            {potentialClient.length !== 0 ? (
              <div className="LaporanCardContainer">
                {potentialClient.slice(0, 3).map((d) => {
                  return <PotentialClientCard data={d} user={user} />;
                })}
              </div>
            ) : (
              <div
                onClick={() => {
                  if (user.fRole === "MARKETING") {
                    handleClickAddClient();
                  }
                }}
                className="LaporanCardContainer"
              >
                <div
                  style={{
                    width: "100%",
                    height: "400px",
                    backgroundColor: "white",
                    borderRadius: 7,
                    fontSize: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "center",
                      gap: "15px",
                      color: "#A4C3FB",
                    }}
                  >
                    <img
                      style={{ width: "80px" }}
                      src={calonclientlogo}
                      alt=""
                    />
                    <text>Tidak Ada Calon Owner</text>
                  </div>
                  {user.fRole === "MARKETING" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "95%",
                        alignItems: "center",
                        backgroundColor: "#D0A360",
                        color: "white",
                        padding: "5px 0",
                        marginTop: "30px",
                        borderRadius: 7,
                      }}
                    >
                      <text>Tambah Calon Owner</text>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div
              style={{
                marginTop: "15px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Link
                to="/allclient"
                state={{ role: user.fRole }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#D0A360",
                  fontSize: "14px",
                  fontWeight: "bold",
                  width: "90%",
                  padding: "7px 0",
                  backgroundColor: "white",
                  borderRadius: 100,
                  textDecoration: "none",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                }}
              >
                Lihat Semua
              </Link>
            </div>
            <div style={{ height: "15px" }} />
          </div>
        )}

        {/* Kasbon */}
        {(user.fRole === "BOSS" ||
          user.fRole === "PM" ||
          user.fRole === "SPV") && (
          <div
            style={{
              width: "100%",
              backgroundColor: "#A4E9EA",
              marginTop: "25px",
              fontWeight: 400,
              paddingTop: "20px",
              borderRadius: "7px",
              maxWidth: "1024px",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
          >
            <div
              style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}
            >
              <text style={{ fontWeight: "bold" }}>Kasbon</text>
            </div>
            {kasbon.length !== 0 ? (
              <div className="LaporanCardContainer">
                {kasbon.slice(0, 3).map((d) => {
                  return <KasbonCard data={d} />;
                })}
              </div>
            ) : (
              <div
                onClick={() => {
                  if (user.fRole === "SPV") {
                    handleClickAddKasbon();
                  }
                }}
                className="LaporanCardContainer"
              >
                <div
                  style={{
                    width: "100%",
                    height: "400px",
                    backgroundColor: "white",
                    borderRadius: 7,
                    fontSize: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "center",
                      gap: "15px",
                      color: "#A4C3FB",
                    }}
                  >
                    <img style={{ width: "80px" }} src={kasbonlogo} alt="" />
                    <text>Tidak Ada Kasbon</text>
                  </div>
                  {user.fRole === "SPV" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "95%",
                        alignItems: "center",
                        backgroundColor: "#D0A360",
                        color: "white",
                        padding: "5px 0",
                        marginTop: "30px",
                        borderRadius: 7,
                      }}
                    >
                      <text>Tambah Kasbon</text>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div
              style={{
                marginTop: "15px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Link
                to="/allkasbon"
                state={{ role: user.fRole }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#D0A360",
                  fontSize: "14px",
                  fontWeight: "bold",
                  width: "90%",
                  padding: "7px 0",
                  backgroundColor: "white",
                  borderRadius: 100,
                  textDecoration: "none",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                }}
              >
                Lihat Semua
              </Link>
            </div>
            <div style={{ height: "15px" }} />
          </div>
        )}

        {user.fRole === "CLIENT" && (
          <div
            style={{
              width: "95%",
              marginLeft: "auto",
              marginRight: "auto",
              fontFamily: "Poppins",
              paddingBottom: "25px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              fontSize: "20px",
            }}
          >
            <div
              style={{
                width: "100vw",
                marginTop: "-35px",
                marginBottom: "50px",
                backgroundImage: `url(${banner})`,
                backgroundSize: "100% 100%",
                color: "white",
                paddingTop: "50px",
                paddingBottom: "50px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  maxWidth: "1024px",
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: "25px",
                  paddingRight: "25px",
                }}
              >
                <text style={{ color: "white" }}>Hello, {user.fName}</text>
                <br />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <text
                    style={{
                      fontWeight: "bold",
                      fontSize: "50px",
                      textAlign: "center",
                    }}
                  >
                    ASMITA KARYA KONSTRUKSI
                  </text>
                  <video
                    style={{ width: "90%", marginTop: "10px" }}
                    src="https://asmitakaryakonstruksi.com/wp-content/uploads/2024/05/VIDEO-COMPRO-ASMITA-KARYA-KONSTRUKSI.mp4"
                    controls
                    autoPlay="autoplay"
                  />
                  <text
                    style={{
                      width: "90%",
                      marginTop: "20px",
                      fontSize: "16px",
                      textAlign: "center",
                    }}
                  >
                    We Provide Professional Client-Focused Construction
                    Solutions
                  </text>

                  <div
                    onClick={() => {
                      navigate("/allproject");
                    }}
                    style={{
                      width: "200px",
                      backgroundColor: "#d1a460",
                      padding: "10px 0",
                      border: 5,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "35px",
                    }}
                  >
                    All Projects
                  </div>
                </div>
              </div>
            </div>
            <text
              style={{
                color: "#D0A360",
                fontWeight: "bold",
                fontSize: "30px",
                marginBottom: "10px",
              }}
            >
              Latest Projects
            </text>
            <div
              style={{ justifyContent: "center", marginBottom: "70px" }}
              className="AllProjectContainer"
            >
              {project.map((d) => {
                return <LaporanProject data={d} />;
              })}
            </div>
          </div>
        )}
      </div>

      {openModal && user.fRole !== "CLIENT" && user.fRole !== "BOSS" && (
        <ModalCheckIn
          closeModal={setOpenModal}
          refresh={absen}
          setRefresh={setAbsen}
          user={user}
          token={token}
          project={project}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          selectedProjectData={selectedProjectData}
          setSelectedProjectData={setSelectedProjectData}
          type={"CheckIn"}
        />
      )}

      {openModalCheckOut &&
        user.fRole !== "CLIENT" &&
        user.fRole !== "BOSS" && (
          <ModalCheckIn
            fID={absensi[0].fID}
            closeModal={setOpenModalCheckOut}
            refresh={absen}
            setRefresh={setAbsen}
            user={user}
            token={token}
            project={projectDropdown}
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
            selectedProjectData={selectedProjectData}
            setSelectedProjectData={setSelectedProjectData}
            type={"CheckOut"}
          />
        )}
    </div>
  );
};

export default HomePage;
